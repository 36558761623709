export const updatePurchaseOrder = (newPO, invoiceID) => (
	fetch("backflow/builder/purchase_order", {
		method: "PUT",
		body: JSON.stringify({ newPO: newPO, invoiceID: invoiceID }),
		headers: {
			"Content-Type": "application/json"
		}
	})
)


export const serviceAddress = (invoiceID) => (
	fetch("backflow/builder/address?" + new URLSearchParams({
		invoiceID: invoiceID 
	}), {
		method: "GET", 
		headers: {
            "Content-Type" : "application/json"
        }
	})

)
export const billingDetails = (invoiceID) =>(
	fetch("backflow/builder/billing?" + new URLSearchParams({
		invoiceID: invoiceID
	}), {
		method: "GET", 
		headers: {
            "Content-Type" : "application/json"
        }
	})

)
export const serviceDetails = (invoiceID) =>(
	fetch("backflow/builder/service?" + new URLSearchParams({
		invoiceID: invoiceID

	}), {
		method: "GET", 
		headers: {
            "Content-Type" : "application/json"
        }
	})

)

export const lineItems = (invoiceID) => (
	fetch("backflow/builder/items?" + new URLSearchParams({
		invoiceID: invoiceID
	}), {
		method: "GET", 
		headers: {
            "Content-Type" : "application/json"
        }
	})
)

export const lineItemDetails = (itemID) => (
	fetch("backflow/builder/itemDetails?" + new URLSearchParams({
		itemID: itemID
	}), {
		method: "GET", 
		headers: {
            "Content-Type" : "application/json"
        }
	})
)

export const removeItem = (itemID) => (
	fetch("backflow/builder/itemRemove", {
		method: "PUT",
		body: JSON.stringify( {itemID: itemID}),  
		headers: {
            "Content-Type" : "application/json"
        }
	})
)

export const addItem = (invoiceID) => (
	fetch("backflow/builder/item", {
		method: "POST", 
		body: JSON.stringify( {invoiceID: invoiceID} ),
		headers: {
            "Content-Type" : "application/json"
        }
	})
)


export const updateServiceAddress = (addressID, obj, invoiceID) =>(
	fetch("backflow/builder/address", {
		method: "PUT", 
		body: JSON.stringify( {addressID: addressID, obj: obj, invoiceID : invoiceID} ),
		headers: {
            "Content-Type" : "application/json"
        }
	})

)

export const updateBillingDetails = (billingID, obj, invoiceID) => (
	fetch("backflow/builder/billing", {
		method: "PUT", 
		body: JSON.stringify( {billingID: billingID, obj: obj, invoiceID : invoiceID} ),
		headers: {
            "Content-Type" : "application/json"
        }
	})

)

export const updateDueDate = (date, invoiceID) => (
	fetch("backflow/builder/billing", {
		method: "PUT", 
		body: JSON.stringify( {newDate: date, invoiceID : invoiceID} ),
		headers: {
            "Content-Type" : "application/json"
        }
	})

)

export const updateLineItem = (itemID, obj) =>(
	fetch("backflow/builder/item", {
		method: "PUT", 
		body: JSON.stringify( { itemID: itemID, obj: obj} ),
		headers: {
            "Content-Type" : "application/json"
        }
	})

)


