import {
  SET_SERVICES_REPORT_CURRENT_STOP_ID
} from "../../actions/servicesReport";

const _nullCurrentStopID =  null; 
                
export default (state = _nullCurrentStopID, { type, stopID }) => {
  Object.freeze(state);
  switch (type) {
    case SET_SERVICES_REPORT_CURRENT_STOP_ID:
      return stopID;
    case "": 
      return _nullCurrentStopID; 
    default:
      return state;
  }
};