import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import {useSelector, useDispatch} from "react-redux";
import { useState, useEffect, useRef, useCallback} from "react";
import Tabs from 'react-bootstrap/Tabs';
import Tab from 'react-bootstrap/Tab';

import TestersApp from "./TestersApp"
import TesterSearch from "./TesterSearch"
import GaugesApp from "./GaugesApp"

export default function AccountsApps(){

	const [appToShow, setAppToShow] = useState(<TestersApp />)
	const [currentTab, setCurrentTab] = useState("TESTERS"); 
	const dispatch = useDispatch(); 

	useEffect(()=>{	
		//clear the state for district 
		switch(currentTab.toUpperCase()){
			case "TESTERS": 
				setAppToShow(<TestersApp />)
				break; 
			case "GAUGES": 
				setAppToShow(<GaugesApp />)
				break;
			default:
				setAppToShow(<TestersApp />)
				break;
		}
	}, [ currentTab])
	return(
		<Container>
			<Row>
				<Col>
					<Tabs 
						onSelect = {(eventKey) => setCurrentTab(eventKey)}
					>
					     <Tab eventKey="TESTERS" title="Testers" mountOnEnter unmountOnExit >
					     </Tab>
					     <Tab eventKey="GAUGES" title="Gauges" mountOnEnter unmountOnExit >
					     </Tab>
				    </Tabs>
				</Col>
			</Row>
		
			<Row>
				<Col>
					{appToShow}
				</Col>	
			</Row>
		</Container>
	)
}