import Table from 'react-bootstrap/Table';
import ListGroup from 'react-bootstrap/ListGroup';
import Row from 'react-bootstrap/Row';
import Container from 'react-bootstrap/Container';
import Col from 'react-bootstrap/Col';
import Card from 'react-bootstrap/Card';
import Spinner from 'react-bootstrap/Spinner';
import Button from 'react-bootstrap/Button';
import ButtonGroup from 'react-bootstrap/ButtonGroup';
import Form from 'react-bootstrap/Form';
import Modal from 'react-bootstrap/Modal';
import Accordion from 'react-bootstrap/Accordion';
import Offcanvas from 'react-bootstrap/Offcanvas';
import { useState, useEffect, useRef, useCallback} from "react";
import {useSelector, useDispatch} from "react-redux";

import {format, add, sub} from "date-fns"
import WaterCard from "./WaterCard"
import WaterDistrictCard from "./WaterDistrictCard"
import PoppingList from "./PoppingList"

import { 
getList, 	
getTotal, 
getTotals, 
determineDate, 
getPrintable, 
requestSharedLink, 
sendEmails, 
sendEmailsNoDate, 
getTotalSent, 
getTotalPDFed,
getTotalInTransit, 
requestWaterDistrictList,
setDistrictID
} from "../../../actions/waterDelivery.js"
import { getDistrict} from "../../../actions/waterDistricts.js"

export default function GetEmail(props){
	const [email, setEmail] = useState("")
	const [sEmail, setsEmail] = useState("")
	const [showSecond, setShowSecond] = useState(false)
	const currentDate = useSelector((state) => state.deliveryDate)
	const waterDistrict = useSelector((state) => state.waterDistrict)
	const waterID = useSelector( (state) => state.waterID )
	const [markAsSent, setMarkAsSent] = useState(false); 
	const [loader, setLoader] = useState(false)
	const chosen = useSelector((state) => state.waterID)
	const dispatch = useDispatch() 
	const [ddLoaded, setDDLoaded] = useState(false)
	useEffect(()=>{
		if(chosen!=null){
			setDDLoaded(false)
			dispatch( getDistrict( chosen) ).then( (data, err) => {
				setDDLoaded(true)
				console.log("District: ", data)
			})
		}
		
	}, [chosen])

	useEffect(() =>{
		if(ddLoaded){
			console.log("Water Changed! ", waterDistrict.p_email, "ww")
			setEmail(waterDistrict.p_email)
		}
	}, [waterDistrict, ddLoaded])



	const sendReports = (event) =>{
		setLoader(true)
		sendEmailsNoDate(waterID, email, markAsSent).then((data, err) =>{
			setLoader(false)
			dispatch( requestWaterDistrictList()).then((data, err) =>{
				console.log("Done getting water districts!"); 
				setLoader(false)
			})

			props.closeModal()
		})
		event.preventDefault(); 
	}

	return(
		<>
			<Modal.Header  closeButton onHide = { ()=> props.closeModal() }>
			</Modal.Header>
			<Form onSubmit = { sendReports }>
			<Modal.Body>
			
			<Form.Label> Email Address</Form.Label>
				<Form.Control type = "email" value={email} onChange = { (event) => setEmail(event.target.value) }/>
				<Form.Check label ="Mark These Reports As Sent" checked= {markAsSent} onClick = { 
						(event)=>{
							setMarkAsSent(event.target.checked)
						}
					}/>
				<Form.Check label ="Printer Email" onClick = {
					(event) =>{ 
							if(event.target.checked){
								setEmail("This would be the printer email")
							}else{
								setEmail("")
							}
						}
					}
				/>
			</Modal.Body>
			<Modal.Footer>
				{	
					loader ?
						<Spinner />
					:
						<Button type="submit">
							send
						</Button>
				}
				
			</Modal.Footer>
			</Form>
		</>
	)
}