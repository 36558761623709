import {
  START_DATE
} from "../../actions/home";

import {format} from "date-fns"

const myD = new Date()
const _nullDate = format( myD.setDate(myD.getDate() -15 ), "yyyy-MM-dd" )
                
export default (state = _nullDate, { type, date }) => {
  Object.freeze(state);
  switch (type) {
    case START_DATE:
      return date;
    case "": 
      return _nullDate; 
    default:
      return state;
  }
};