
import * as server from "../util/failed.js"

export const SET_LIST  = "SET_LIST"; 
export const SET_REPORT = "SET_REPORT"; 
export const SET_ASSEMBLY = "SET_ASSEMBLY"
export const SET_CUSTOMER = "SET_CUSTOMER"
export const SET_PARTS = "SET_PARTS"
export const SET_CURRENT_ID = "SET_CURRENT_ID"

const receiveList = (list) => ({
	type: SET_LIST, 
	list
})
const receiveID = (id) => ({
	type: SET_CURRENT_ID, 
	id
})
const receiveReport = (obj) => ({
	type: SET_REPORT, 
	obj
})


export const flagAsScheduled = (reportID) => {
	return new Promise( async(resolve, reject) =>{
		try{	
			const response = await server.flagAsScheduled(reportID); 
			const data = await response.json()
			if(response.ok){
				resolve(); 
			}
		}catch(err){

		}
	})

}


export const requestTestReport = (reportID) => dispatch => {
	return new Promise( async(resolve, reject) =>{
		try{	
			const response = await server.requestTestReport(reportID); 
			const data = await response.json()
			if(response.ok){
				dispatch( receiveReport( JSON.parse(data).obj ) )
				resolve(); 
			}
		}catch(err){

		}
	})

}

export const requestFailedTestReports = () => dispatch =>{
	return new Promise( async(resolve, reject) =>{
		try{	
			const response = await server.requestFailedList(); 
			const data = await response.json()
			if(response.ok){
				dispatch( receiveList( JSON.parse(data).list ) )
				resolve(); 
			}
		}catch(err){

		}
	})
}


export const setID = (id) => dispatch => {
	return new Promise( async(resolve, reject) =>{
		try{	
			dispatch( receiveID(id) ); 
			resolve()
		}catch(err){

		}
	})

}