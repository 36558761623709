import {
  RECEIVE_INVOICE_BILLING_DETAIL,  
} from "../../actions/invoice.js";

const _nullInvoiceBilling = null; 
                
export default (state = _nullInvoiceBilling, { type, billing_detail }) => {
  Object.freeze(state);
  switch (type) {
    case RECEIVE_INVOICE_BILLING_DETAIL:
      return billing_detail;
    case "": 
      return _nullInvoiceBilling; 
    default:
      return state;
  }
};