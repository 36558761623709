import * as server from "../util/gauge_server.js";


export const CURRENT_GAUGE = "CURRENT_GAUGE"
export const CLEAR_GAUGE = "CLEAR_GAUGE"
export const GAUGE_DETAILS = "GAUGE_DETAILS"
export const CLEAR_DETAILS = "CLEAR_DETAILS"


const getGaugeID = (id) => ({
	type: CURRENT_GAUGE, 
	id
})

const clearGaugeID = () =>({
	type: CLEAR_GAUGE
})

const getDetails = (obj) => ({
	type: GAUGE_DETAILS, 
	obj
})

const clearReport = () => ({
	type: CLEAR_DETAILS
})



export const setID = (id) => dispatch => {
	return new Promise ( async (resolve, reject) =>{
		dispatch( getGaugeID(id) )
		resolve(); 
	})
}

export const clearID = () => dispatch => {
	return new Promise ( async (resolve, reject) =>{
		dispatch( clearGaugeID() )
		resolve(); 
	})
}

export const requestGaugeDetails = (gaugeID) => dispatch => {
	return new Promise ( async (resolve, reject) =>{
		const response = await server.requestGauge(gaugeID); 
		const data = await response.json();
		if(response.ok){
			dispatch( getDetails(JSON.parse(data).obj) )
			resolve( JSON.parse(data).obj); 
		}
	})

}

export const updateGaugeDetails = (gaugeID, obj) => dispatch => {
	return new Promise ( async (resolve, reject) =>{
		const response = await server.updateGauge(gaugeID, obj); 
		const data = await response.json();
		if(response.ok){
			dispatch( getDetails(JSON.parse(data).obj) )
			resolve( JSON.parse(data).obj); 
		}
	})

}


export const createGauge = (obj) => dispatch => {
	return new Promise ( async (resolve, reject) =>{
		const response = await server.createGauge(obj); 
		const data = await response.json();
		if(response.ok){
			resolve(); 
		}
	})

}