import {
  SET_SALES_RATE
} from "../../actions/invoice";

const _nullRate =  0
                
export default (state = _nullRate, { type, taxrate }) => {
  Object.freeze(state);
  switch (type) {
    case SET_SALES_RATE:
      return taxrate;
    case "": 
      return _nullRate; 
    default:
      return state;
  }
};