import {
  SET_GAUGE_LIST
} from "../../actions/employee";

const _nullList = []; 

export default (state = _nullList, { type, list }) => {
  Object.freeze(state);
  switch (type) {
    case SET_GAUGE_LIST:
      return list;
    case "": 
      return _nullList; 
    default:
      return state;
  }
};