
export const requestTester = (testerID) =>(
	fetch("backflow/testReport/tester?" +  new URLSearchParams({
		testerID: testerID
	}), {
		method: "GET", 
		headers: {
            "Content-Type" : "application/json"
        }
	}) 
)
export const requestReportDetails = (reportID) =>(
	fetch("backflow/testReport/details?" +  new URLSearchParams({
		reportID: reportID
	}), {
		method: "GET", 
		headers: {
            "Content-Type" : "application/json"
        }
	}) 
)

export const requestReportAssembly = (assemblyID) =>(
	fetch("backflow/testReport/assembly?" +  new URLSearchParams({
		assemblyID: assemblyID
	}), {
		method: "GET", 
		headers: {
            "Content-Type" : "application/json"
        }
	}) 
)

export const requestTesterDetails = (testerID) =>(
	fetch("backflow/testers?" +  new URLSearchParams({
		testerID: testerID
	}), {
		method: "GET", 
		headers: {
            "Content-Type" : "application/json"
        }
	}) 
)

export const requestTestReport = (reportID) =>(
	fetch("backflow/testReport?" +  new URLSearchParams({
		reportID: reportID
	}), {
		method: "GET", 
		headers: {
            "Content-Type" : "application/json"
        }
	}) 
)

export const requestReportList = (districtID) => (
	fetch("backflow/waterDistrict/online?" +  new URLSearchParams({
		districtID: districtID
	}), {
		method: "GET", 
		headers: {
            "Content-Type" : "application/json"
        }
	}) 

)

export const requestReportListEmail = () => (
	fetch("backflow/waterDistrict/email?", {
		method: "GET", 
		headers: {
            "Content-Type" : "application/json"
        }
	}) 
)

export const setAsSent = (reportID) =>(
	fetch("backflow/testReport/setAsSent", {
		method: "POST", 
		body: JSON.stringify({reportID: reportID}), 
		headers: {
            "Content-Type" : "application/json"
        }
	}) 
)

export const setForReview = ( reportID, reason) =>(
	fetch("backflow/testReport/setForReview", {
		method: "POST", 
		body: JSON.stringify({reportID: reportID, reason: reason}), 
		headers: {
            "Content-Type" : "application/json"
        }
	}) 

)

export const requestAddressServices = (testDate, addressID) =>(
	fetch("backflow/address/services?"  +  new URLSearchParams({
		testDate: testDate, 
		addressID: addressID
	}), {
		method: "GET",
		headers: {
            "Content-Type" : "application/json"
        }
	}) 
)

export const requestAddress = (addressID) =>(
	fetch("backflow/address?"  +  new URLSearchParams({
		addressID: addressID
	}), {
		method: "GET",
		headers: {
            "Content-Type" : "application/json"
        }
	}) 
) 