import {
  RECEIVE_ADDRESSES
} from "../../actions/customersnap";

const _nullAddressList = { 
  addressList: []
}
                
export default (state = _nullAddressList, { type, addresslist }) => {
  switch (type) {
    case RECEIVE_ADDRESSES:
      return addresslist;
    case "": 
      return _nullAddressList; 
    default:
      return state;
  }
};