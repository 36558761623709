import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Form from 'react-bootstrap/Form'
import FloatingLabel from 'react-bootstrap/FloatingLabel'
import {useSelector, useDispatch} from "react-redux";
import { useState, useEffect, useRef, useCallback} from "react";
import {setAddress, setContact, setDetails, setServices} from "../../../../actions/newstop"


export default function ContactForm(){
	const [name, setName] = useState(null);
	const [phone, setPhone] = useState(null);
	const [email, setEmail] = useState(null); 
	const dispatch = useDispatch(); 

	const update = () =>{
		let obj = {}; 
		obj.name = name; 
		obj.phone = phone; 
		obj.email = email
		dispatch( setContact(obj) ).then((data, err) =>{
			
		})
	}
	return(
		<Container>
			<Row>
				<Col>
					<h5> Contact </h5>
				</Col>
			</Row>
			<Row>
				<Col>
					<FloatingLabel label="Name">
						<Form.Control 
							value = {name}	
							size="sm"
							onChange = {(event)=>setName(event.target.value)}
							onBlur = {update}
						/>
					</FloatingLabel>
					<FloatingLabel label="Phone">
					<Form.Control 
						value = {phone}	
						onChange = {(event)=>setPhone(event.target.value)}
						onBlur = {update}
					/>
					</FloatingLabel>
					<FloatingLabel label = "Email">
					<Form.Control 
						value = {email}
						onChange = {(event)=>setEmail(event.target.value)}
						onBlur = {update}
					/>
					</FloatingLabel>
				</Col>
			</Row>
		</Container>	
	)
}