import * as server from "../util/server";

export const RECEIVE_TEST_REPORT = "RECEIVE_TEST_REPORT"; 
export const CLEAR_TEST_REPORT = "CLEAR_TEST_REPORT"; 

export const GET_PRINTER_LIST = "GET_PRINTER_LIST";
export const GET_TEST_REPORT_LIST = "GET_TEST_REPORT_LIST"; 

export const GET_PENDING_LIST = "GET_PENDING_LIST"
export const GET_REPORT_LIST = "GET_REPORT_LIST"; 

const getReport = (report) => ({
	type: RECEIVE_TEST_REPORT, 
	report
})

const getTestReportList = (testreportlist) => ({
	type: GET_TEST_REPORT_LIST, 
	testreportlist
})


const receivePrinterList = (printerlist) =>({
	type: GET_PRINTER_LIST, 
	printerlist
})



const receivePendingReportList = (reportlist) => ({
	type: GET_PENDING_LIST, 
	reportlist
})

const getTestReportLL = (list) =>({
	type: GET_REPORT_LIST, 
	list
})


export const setTestsAsSent = (list) => {
	return new Promise( async(resolve, reject)=>{
		console.log("LIST: ", list); 
		const response = await  server.setTestsAsSent(list); 
		const data = await response.json(); 
		if(response.ok)	{
			resolve(); 
		}
	})
}

export const requestPDF = (districtID) => {
	return new Promise ( async (resolve, reject) =>{
		const response = await server.requestWaterPDF(districtID); 
		const data =  await response.json() ; 

		if(response.ok){
			if( !JSON.parse(data).success){
				console.log("rejecting...", JSON.parse(data))
				resolve(JSON.parse(data))
			}else{
				console.log(JSON.parse(data)); 
				resolve(JSON.parse(data))
			}
		}else{
			resolve(response)
		}
	})
}

export const requestPPReports = () => dispatch =>{
	return new Promise( async( resolve, reject) =>{
		
		const response = await server.getTestReportPendingList(); 
		const data = await response.json(); 
		if( response.ok){
			dispatch(getTestReportLL( JSON.parse(data).list) )
			resolve(true);
		}
	})	
}

export const requestReportdd = (waterDistrictID) => dispatch =>{
	return new Promise( async( resolve, reject) =>{
		let obj = { waterID: waterDistrictID }
		const response = await server.getNextReport(obj); 
		const data = await response.json(); 
		if( response.ok){
			let obj = {report:  JSON.parse(data).report, remaining: JSON.parse(data).remaining } 
			dispatch(getReport( obj ) )
		}
	})	
} 

export const getPrinterList = () => dispatch => {

	return new Promise ( async (resolve, reject) => {
		const response = await server.requestPrinterList(); 
		const data = await response.json();

		if(response.ok){
			let myobj = { list: data.printers }
			dispatch( receivePrinterList( myobj) )
			resolve(true)
		}
	})
}


export const getTestReport = (testReportID) => async dispatch =>{
	return new Promise( async( resolve, reject)=>{
		let obj = { testReportID: testReportID }
		const response = await server.requestTestReport(obj); 
		const data = await response.json()
		if(response.ok){
			if(JSON.parse(data).success){
				let obj = { testReport: JSON.parse(data).testReport, 
					customer: JSON.parse(data).customer,
					 waterDistrict: JSON.parse(data).waterDistrict,
					  contact: JSON.parse(data).contact,
					   address: JSON.parse(data).address,
					   tester: JSON.parse(data).tester, 
					   gauge: JSON.parse(data).gauge
					}
				dispatch( getReport( obj ) ); 
				resolve(true); 
			}else{
				resolve(false)
			}
		}else{
			resolve(false)
		}
		
	})
}

export const getPendingTestReports = () => dispatch =>{
	return new Promise( async(resolve, reject)=>{
		const response = await server.getPendingTestReport(); 
		const data = await response.json(); 
		if(response.ok){
			let obj = { list: JSON.parse(data).testReportList}
			dispatch( getTestReportList( obj) )
			resolve(true)
		}
	})
}

export const requestPendingReports = (waterDistrictID) => dispatch => {
	return new Promise( async(resolve, reject) =>{
		let obj = { waterID : waterDistrictID}
		const response = await server.requestPendingReports( obj ); 
		const data = await response.json(); 

		if(response.ok){
			let obj = { list: JSON.parse(data).list, total: JSON.parse(data).total}
			dispatch(receivePendingReportList( obj ) )
			let obj2 = { report: JSON.parse(data).report }
			dispatch( getReport( obj2 ) ); 
			resolve(true)
		}
	})
}

export const setAsSent = ( reportID ) => dispatch =>{
	return new Promise( async(resolve, reject) =>{
		let obj = { reportID: reportID }
		const response = await server.setReportAsSent(obj); 
		const data = await response.json(); 
		if(response.ok) {
			resolve(true); 
		}
	})
}
export const setWaterDistrictSend = (state, reportID) => async dispatch =>  {
	return new Promise ( async (resolve, reject)=>{
		let obj = { state: state, testReportID: reportID}; 
		const response = await server.setWDSend(obj); 
		const data = await response.json(); 
		if(response.ok){
			if(JSON.parse(data).success){
				let obj = { testReport: JSON.parse(data).testReport, 
					customer: JSON.parse(data).customer,
					 waterDistrict: JSON.parse(data).waterDistrict,
					  contact: JSON.parse(data).contact,
					   address: JSON.parse(data).address,
					   tester: JSON.parse(data).tester, 
					   gauge: JSON.parse(data).gauge
					}
				dispatch( getReport( obj )); 
				resolve(true)
			}
		}else{
			resolve(false)
		}
	}) 
} 







