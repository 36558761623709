import Container from 'react-bootstrap/Container'
import CardGroup from 'react-bootstrap/CardGroup'
import Card from 'react-bootstrap/Card'
import Button from 'react-bootstrap/Button'
import Row from 'react-bootstrap/Row'


export default function UserAgreement(){
	return(
		<>
	
		<Container bsPrefix="diss">

			<Row>
				<h4>Last Updated: <strong> October 26th, 2023</strong></h4>
			</Row>
			
			<Row>
				<h3>1. DEFINITIONS</h3>

					<ul>
						<li>a. "App" means the American Backflow App, including all its features and functionalities.</li>

						<li>b. "Licensee" refers to the individual or entity who has downloaded, installed, or otherwise acquired the App.</li>

						<li>c. "Licensor" refers to the entity that holds the intellectual property rights to the App.</li>
					</ul>

				<h3>2. LICENSE GRANT</h3>

				Subject to the terms and conditions of this License Agreement, Licensor grants Licensee a limited, non-exclusive, non-transferable, and revocable license to use the App solely for processing payments and displaying invoice information as intended by the App's design and functionality.

				<h3>3. LICENSE RESTRICTIONS</h3>

				Licensee shall not:

				a. Modify, adapt, translate, or create derivative works based on the App.

				b. Reverse engineer, decompile, disassemble, or attempt to derive the source code of the App.

				c. Remove, alter, or obscure any copyright, trademark, or other proprietary notices from the App.

				d. Use the App for any unlawful, harmful, or malicious purposes, including but not limited to any activities that infringe on the rights of others or violate applicable laws.

				e. Transfer, sublicense, or assign the license to the App to any third party without the prior written consent of Licensor.

				<h3>4. INTELLECTUAL PROPERTY RIGHTS</h3>

				Licensor retains all rights, title, and interest in and to the App, including all intellectual property rights. Licensee acknowledges that no ownership rights are granted under this License Agreement, and Licensee shall not claim any such rights.

				<h3>5. PAYMENTS AND INVOICES</h3>

				a. The App processes payments and displays invoice information. Licensee acknowledges that Licensor is not responsible for the accuracy, security, or legality of the payment processing, invoicing, or any financial transactions conducted through the App. Licensee uses the App for these purposes at their own risk.

				<h3>6. SUPPORT AND UPDATES</h3>

				Licensor may, at its discretion, provide updates, upgrades, or support services for the App. These updates and support services may be subject to additional terms and conditions. Licensor is not obligated to provide any support or updates to the App under this License Agreement.

				<h3>7. TERM AND TERMINATION</h3>

				This License Agreement is effective upon Licensee's acceptance of these terms and will continue until terminated. Licensee may terminate the license at any time by ceasing to use the App. Licensor may terminate this License Agreement if Licensee breaches any of the terms and conditions. Upon termination, Licensee shall immediately cease all use of the App and destroy all copies of the App in their possession.

				<h3>8. DISCLAIMER OF WARRANTY</h3>

				THE APP IS PROVIDED "AS IS" WITHOUT ANY WARRANTIES, EXPRESS OR IMPLIED, INCLUDING BUT NOT LIMITED TO MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, OR NON-INFRINGEMENT. LICENSOR DOES NOT WARRANT THAT THE APP WILL BE ERROR-FREE OR UNINTERRUPTED.

				<h3>9. LIMITATION OF LIABILITY</h3>

				IN NO EVENT SHALL LICENSOR BE LIABLE FOR ANY DIRECT, INDIRECT, INCIDENTAL, SPECIAL, EXEMPLARY, OR CONSEQUENTIAL DAMAGES, INCLUDING BUT NOT LIMITED TO LOSS OF PROFITS, DATA, OR BUSINESS INTERRUPTION, ARISING OUT OF THE USE OR INABILITY TO USE THE APP.

				<h3>10. GOVERNING LAW</h3>

				This License Agreement shall be governed by and construed in accordance with the laws of the state of Oregon, United States.

				<h3>11. ENTIRE AGREEMENT</h3>

				This License Agreement constitutes the entire agreement between Licensee and Licensor regarding the use of the App and supersedes all prior or contemporaneous understandings or agreements.

				<h3>12. CONTACT INFORMATION</h3>

				If you have any questions or concerns regarding this License Agreement, please contact:

				gemadhc@gmail.com

				By using the American Backflow App, you acknowledge that you have read, understood, and agreed to be bound by the terms and conditions of this License Agreement. If you do not agree to these terms, please do not use the App.

				[End of License Agreement]
			</Row>
		</Container>
		</>
	)
}