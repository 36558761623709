import {
  SET_SERVICES_REPORT_LIST
} from "../../actions/servicesReport";

const _nullList =  []; 
                
export default (state = _nullList, { type, list }) => {
  Object.freeze(state);
  switch (type) {
    case SET_SERVICES_REPORT_LIST:
      return list;
    case "": 
      return _nullList; 
    default:
      return state;
  }
};