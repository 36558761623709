import * as server from "../util/accountServer.js"
import { GET_CUSTOMER_DETAILS, SET_ID, GET_BILLING_DETAILS} from "./customerApp.js" 

export const RECEIVE_ACCOUNT = "RECEIVE_ACCOUNT"

const receiveCustomerDetails = (customer) => ({
	type: GET_CUSTOMER_DETAILS, 
	customer
})

const receiveAccount = (account) =>({
	type: RECEIVE_ACCOUNT, 
	account
})
const updateCustomerID = (ID) =>({
	type: SET_ID, 
	ID
})
const receiveBillingDetails = (billing) => ({
	type: GET_BILLING_DETAILS, 
	billing
})


export const clearAccount = () => dispatch => {
	return new Promise(async (resolve, reject) => {
		resolve( dispatch( receiveCustomerDetails(null) ))
	})
}

export const clearAccountBilling = () => dispatch => {
	return new Promise(async (resolve, reject) => {
		resolve( dispatch( receiveBillingDetails(null) ) )
	})
}

export const requestBillingDetails = (accountID) => dispatch =>{
	return new Promise ( async (resolve, reject) =>{
		try{
			const response = await server.requestBillingDetails(accountID); 
			const data = await response.json(); 
			if(response.ok){
				dispatch( receiveBillingDetails(JSON.parse(data).detail) )
				resolve(JSON.parse(data).detail)
			}
		}catch(e){

		}
	})
}

export const createAccount = (obj) => dispatch => {
	return new Promise ( async (resolve, reject) =>{
		try{
			const response = await server.createAccount(obj); 
			const data = await response.json(); 
			if(response.ok){
				dispatch( updateCustomerID(JSON.parse(data).accountID) )
				resolve(JSON.parse(data).accountID)
			}
		}catch(e){
			console.log(e)
		}
	})
}


export const updateBillingName = (newname, billingID) => dispatch =>{
	return new Promise ( async (resolve, reject) =>{
		try{
			const response = await server.updateBillingName(newname, billingID); 
			const data = await response.json(); 
			if(response.ok){
				dispatch( receiveBillingDetails(JSON.parse(data).detail) )
				resolve(JSON.parse(data).detail)
			}
		}catch(e){

		}
	})
} 
export const updateBillingContact = (obj, billingID) => dispatch =>{
	return new Promise ( async (resolve, reject) =>{
		try{
			const response = await server.updateBillingContact(obj, billingID); 
			const data = await response.json(); 
			if(response.ok){
				dispatch( receiveBillingDetails(JSON.parse(data).detail) )
				resolve(JSON.parse(data).detail)
			}
		}catch(e){

		}
	})
}
export const updateBillingAddress = (obj, billingID) => dispatch =>{
	return new Promise ( async (resolve, reject) =>{
		try{
			const response = await server.updateBillingAddress(obj, billingID); 
			const data = await response.json(); 
			if(response.ok){
				dispatch( receiveBillingDetails(JSON.parse(data).detail) )
				resolve(JSON.parse(data).detail)
			}
		}catch(e){

		}
	})
}
export const updateBillingDetails = (obj, billingID) => dispatch =>{
	return new Promise ( async (resolve, reject) =>{
		try{
			const response = await server.updateBillingDetails(obj, billingID); 
			const data = await response.json(); 
			if(response.ok){
				dispatch( receiveBillingDetails(JSON.parse(data).detail) )
				resolve(JSON.parse(data).detail)
			}
		}catch(e){

		}
	})
}

export const updateAccountName = (newname, accountID) => dispatch=> {
	return new Promise ( async (resolve, reject) =>{
		try{
			const response = await server.updateAccountName(newname, accountID); 
			const data = await response.json(); 
			if(response.ok){
				dispatch( receiveCustomerDetails(JSON.parse(data).customer) )
				resolve(JSON.parse(data).customerID)
			}
		}catch(e){

		}
	})
}

export const updateContactName = (obj, accountID) => dispatch =>{
	return new Promise ( async (resolve, reject) =>{
		try{
			const response = await server.updateContactName(obj, accountID); 
			const data = await response.json(); 
			if(response.ok){
				dispatch( receiveCustomerDetails(JSON.parse(data).customer) )
				resolve(JSON.parse(data).customerID)
			}
		}catch(e){

		}
	})
}
export const updatePhoneEmail = (obj, accountID) => dispatch => {
	return new Promise ( async (resolve, reject) =>{
		try{
			const response = await server.updateContactPhoneEmail(obj, accountID); 
			const data = await response.json(); 
			if(response.ok){
				dispatch( receiveCustomerDetails(JSON.parse(data).customer) )
				resolve(JSON.parse(data).customerID)
			}
		}catch(e){

		}
	})
}
export const updateStatus = (obj, accountID) => dispatch => {
	return new Promise ( async (resolve, reject) =>{
		try{
			const response = await server.updateStatus(obj, accountID); 
			const data = await response.json(); 
			if(response.ok){
				dispatch( receiveCustomerDetails(JSON.parse(data).customer) )
				resolve(JSON.parse(data).customerID)
			}
		}catch(e){

		}
	})
}