import {
  RECEIVE_CUSTOMER
} from "../../actions/customersnap";

const _nullCustomer = { 
  customerDetails: []
}
                
export default (state = _nullCustomer, { type, customerlist }) => {
  switch (type) {
    case RECEIVE_CUSTOMER:
      return customerlist;
    case "": 
      return _nullCustomer; 
    default:
      return state;
  }
};