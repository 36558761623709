import {
  SET_PAYMENT_EMAIL, 
  CLEAR_PAYMENT_EMAIL
} from "../../actions/scheduling";

const _nullEmail =  null; 
                
export default (state = _nullEmail, { type, email }) => {
  Object.freeze(state);
  switch (type) {
    case SET_PAYMENT_EMAIL:
      return email;
    case CLEAR_PAYMENT_EMAIL: 
      return _nullEmail
    case "": 
      return _nullEmail; 
    default:
      return state;
  }
};