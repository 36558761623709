import {
  SET_DATE_LIST, 
  CLEAR_DATE_LIST
} from "../../actions/scheduling";

const _nullList =  []; 
                
export default (state = _nullList, { type, datelist }) => {
  Object.freeze(state);
  switch (type) {
    case SET_DATE_LIST:
      return datelist;
    case CLEAR_DATE_LIST: 
      return _nullList
    case "": 
      return _nullList; 
    default:
      return state;
  }
};