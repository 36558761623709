import {
    SET_OVERALL_TOTAL
} from "../../actions/scheduling";

const _nullTotal = 0;

export default (state = _nullTotal, { type, total }) => {
    Object.freeze(state);
    switch (type) {
        case SET_OVERALL_TOTAL:
            return total;
        case "":
            return _nullTotal;
        default:
            return state;
    }
};