import {
  GET_WATER_DISTRICT, 

  CLEAR_WATER_DISTRICT
} from "../../actions/waterDistricts.js";

const _nullWaterDistrict = null
                
export default (state = _nullWaterDistrict, { type, waterdistrict }) => {
  Object.freeze(state);
  switch (type) {
    case GET_WATER_DISTRICT:
      return waterdistrict;
    case CLEAR_WATER_DISTRICT: 
      return _nullWaterDistrict;
    case "": 
      return _nullWaterDistrict; 
    default:
      return state;
  }
};