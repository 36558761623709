
import Table from 'react-bootstrap/Table';
import Toast from 'react-bootstrap/Toast';
import Badge from 'react-bootstrap/Badge';
import Row from 'react-bootstrap/Row';

import Col from 'react-bootstrap/Col';
import Card from 'react-bootstrap/Card';
import Form from 'react-bootstrap/Form';
import CardGroup from 'react-bootstrap/CardGroup';
import Container from 'react-bootstrap/Container';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import Spinner from 'react-bootstrap/Spinner';
import ButtonGroup from 'react-bootstrap/ButtonGroup';
import { useState, useEffect, useRef, useCallback} from "react";
import {useSelector, useDispatch} from "react-redux";
import { CheckMarkAnimation } from "../animations.js"
import {setAsSent} from "../../../actions/testReports.js"
import { requestNextPop, 
	requestNextPopNoDate, 
	requestReport, 
	requestTotalNotYetSent,
	 requestTestReport} from "../../../actions/waterDelivery.js"
import InitialResults from "./InitialResults"
import Assembly from "./Assembly"
import TesterGauge from "./TesterGauge"
import TestDetails from "./TestDetails"
import Approved from "./Approved"
import Repairs from "./Repairs"
import {requestDetails} from "../../../actions/report.js"


export default function TestReport(props){
	const report = useSelector((state) => state.testReport)
	const reportID = useSelector((state) => state.reportID)
	const dispatch = useDispatch(); 
	const [showReport, setShowReport] = useState(false)


	useEffect(()=>{
		if(reportID != null){
			setShowReport(false)
			dispatch( requestTestReport(reportID) ).then((data, err) =>{
				console.log("got the test report")
				setShowReport(true)
			})
		}
	}, [reportID])

	useEffect(()=>{
		//request assembly
		if(reportID != null){
			setShowReport(false)
			dispatch( requestDetails(reportID) ).then((data, err)=>{
				console.log("Requested test report details!")
				setShowReport(true)
			})
		}
			
	}, [reportID])

	function yesno(boolval){
		if(boolval){
			return "Yes"
		}else{
			return "No"
		}
	}

	function onoff(boolval) {
		if (boolval) {
			return "On"
		} else {
			return "Off"
		}
	}

	return( 
			<Container className = "ttReport">
				
				{
					showReport  && report ?
						<>
								
									
									<Row>
										<Col>
											<TestDetails />
										</Col>
									
										<Col >
											<Assembly />
										</Col>
									</Row>
									<hr/>
									<Row>
										<Col>
											<TesterGauge />
										</Col>
										<Col>
											<Approved />
										</Col>
										<Col>
												<Repairs />
										</Col>
									</Row>
									<hr/>

									<Row>
										<Col >
											<InitialResults />
										</Col>	
										
									</Row>
								
									
								
						</>
							
					:
						<>
							<Spinner />
						</>

				}
				
						
			</Container>
	)
}