
import * as server from "../util/customerServer.js";
import * as accountServer from "../util/accountServer"


//reducer variables
export const GET_CUSTOMER_DETAILS = "GET_CUSTOMER_DETAILS"; 
export const GET_ADDRESS_LIST = "GET_ADDRESS_LIST"; 
export const GET_ASSEMBLY_LIST = "GET_ASSEMBLY_LIST"; 
export const CLEAR_ASSEMBLY_LIST = "CLEAR_ASSEMBLY_LIST"; 
export const GET_BILLING_DETAILS = "GET_BILLING_DETAILS"; 
export const GET_INVOICE_LIST = "GET_INVOICE_LIST"; 
export const GET_LOG_LIST = "GET_LOG_LIST"; 
export const SET_ID = "SET_ID"; 
export const CLEAR_ID = "CLEAR_ID"; 
export const GET_SERVICES = "GET_SERVICES"
export const GET_PAYMENT_LIST = "GET_PAYMENT_LIST"; 
export const GET_REPORTS = "GET_REPORTS"
export const SET_ADDRESS_ID = "SET_ADDRESS_ID"
export const CLEAR_ADDRESS_ID = "CLEAR_ADDRESS_ID"


//update reducer slices 

	
const receiveAddressID = (ID) =>({
	type: SET_ADDRESS_ID, 
	ID
})

const clearAddressID = () =>({
	type: CLEAR_ADDRESS_ID
})

const receiveScheduledServices = (list) =>({
	type: GET_SERVICES, 
	list
})

const receiveReports = (list) => ({
	type: GET_REPORTS, 
	list
})

const receiveCustomerDetails = (customer) => ({
	type: GET_CUSTOMER_DETAILS, 
	customer
})
const receiveAddressList = (list) => ({
	type: GET_ADDRESS_LIST, 
	list
})
const receiveAssemblyList = (list) => ({
	type: GET_ASSEMBLY_LIST, 
	list
})
const receiveBillingDetails = (billing) => ({
	type: GET_BILLING_DETAILS, 
	billing
})
const receiveInvoiceList = (list) => ({
	type: GET_INVOICE_LIST, 
	list
})
const receiveLogList = (list) => ({
	type: GET_LOG_LIST, 
	list
})

const updateCustomerID = (ID) =>({
	type: SET_ID, 
	ID
})

const nullOutID = () => ({
	type: CLEAR_ID
})

const clearAssemblies = () =>({
	type: CLEAR_ASSEMBLY_LIST
})

const receivePaymentList = (list) =>({
	type: GET_PAYMENT_LIST, 
	list
})


export const setAsWaterSent = (reportID, actionType)  => {
	return new Promise( async (resolve, reject) => {
		try {
			const response = await server.setAsWaterSent(reportID, actionType);
			const data = await response.json();
			if (response.ok) {
				resolve();
			} else {
				//catch the error...
				resolve(false)
			}
		} catch (e) {
			console.log(e)
		}

	})
}

export const setAsClientSent = (reportID, actionType)  => {
	return new Promise( async (resolve, reject) => {
		try {
			const response = await server.setAsClientSent(reportID, actionType);
			const data = await response.json();
			if (response.ok) {
				resolve();
			} else {
				//catch the error...
				resolve(false)
			}
		} catch (e) {
			console.log(e)
		}

	})
}

export const getReportLog = (reportID) => {
	return new Promise( async (resolve, reject) => {
		try {
			const response = await server.requestReportLog(reportID);
			const data = await response.json();
			if (response.ok) {
				resolve(JSON.parse(data).list);
			} else {
				//catch the error...
				resolve(false)
			}
		} catch (e) {
			console.log(e)
		}

	})
}
export const getAccountLog = (accountID) => dispatch => {
	return new Promise((resolve, reject) => {
	})
}

export const clearCustomerReports = () => dispatch => {
	return new Promise((resolve, reject) => {
		dispatch( receiveReports([]) )
		resolve(); 
	})
}
export const clearServicesList = () => dispatch => {
	return new Promise((resolve, reject) => {
		dispatch(receiveScheduledServices([]))
		resolve();
	})
}
export const clearCustomerServicesAddresses = () => dispatch => {
	return new Promise((resolve, reject) => {
		dispatch(receiveAddressList([]))
		resolve();
	})
}

export const requestReports = (addressID) => dispatch => {
	return new Promise(async (resolve, reject) => {
		try{
			const response = await server.requestReports(addressID); 
			const data = await response.json(); 

			if(response.ok){
				console.log("RECEIVED: ", JSON.parse(data).list)
				resolve( dispatch( receiveReports( JSON.parse(data).list ) ) ); 
			}else{
				//catch the error...
				resolve(false)
			}
		}catch(e){
			console.log(e)
		}
	})
}

export const requestServices = (customerID) => dispatch => {
	return new Promise(async (resolve, reject) => {
		try{
			const response = await server.requestServices(customerID); 
			const data = await response.json(); 

			if(response.ok){
				console.log("RECEIVED: ", JSON.parse(data).list)
				resolve( dispatch( receiveScheduledServices( JSON.parse(data).list ) ) ); 
			}else{
				//catch the error...
				resolve(false)
			}
		}catch(e){
			console.log(e)
		}
	})
}

export const clearAssem = () => dispatch =>{
	return new Promise( async (resolve, reject) =>{
		dispatch(clearAssemblies());
		resolve() 
	})
}


export const updateAccount = (obj, customerID) => dispatch => {
	return new Promise( async (resolve, reject) =>{
		const response = await server.updateCustomerDetails(obj, customerID); 
		const data = await response.json(); 
		if(response.ok){
			dispatch( receiveCustomerDetails(JSON.parse(data).customer )); 
			resolve(); 
		}else{
			//catch the error...
		}
	})
}

export const  removeBillingDetail  = ( id, customerID) => dispatch => {
	return new Promise( async(resolve, reject) =>{
		const response = await server.removeBillingDetail(id, customerID); 
		const data = await response.json();  
		if(response.ok){
			dispatch( receiveBillingDetails(JSON.parse(data).list));
			resolve()
		}
	})
}
export const createBillingDetail = (obj, customerID) => dispatch => {
	return new Promise( async(resolve, reject) =>{
		const response = await server.createBillingDetail(obj, customerID); 
		const data = await response.json();  
		if(response.ok){
			dispatch( receiveBillingDetails(JSON.parse(data).list));
			resolve()
		}
	})
}

export const clearID = () => dispatch =>{
	return new Promise( async( resolve, reject) => {
		dispatch( nullOutID() ); 
		resolve()
	}) 

}
export const newCustomerID = (customerID) => dispatch => {
	return new Promise( async( resolve, reject) => {
		dispatch( updateCustomerID( customerID) ); 
		resolve()
	}) 
}
//action functions 
export const requestCustomerDetails = (customerID) => dispatch =>{
	return new Promise( async (resolve, reject) =>{
		const response = await server.requestCustomerDetails(customerID); 
		const data = await response.json(); 
		if(response.ok){
			dispatch( receiveCustomerDetails(JSON.parse(data).customer )); 
			resolve(); 
		}else{
			//catch the error...
		}
	})
}

export const createCustomer = (customerObj) => dispatch =>{
	return new Promise( async (resolve, reject) =>{
		const response = await server.createCustomer(customerObj); 
		const data = await response.json(); 
		if(response.ok){
			dispatch( updateCustomerID(JSON.parse(data).customerID )); 
			resolve(JSON.parse(data).customerID); 
		}else{
			//catch the error...
		}
	})
}

export const associateAddressToCustomer = (addressID, customerID) => dispatch => {
	return new Promise( async (resolve, reject) =>{
		console.log("Associating: ", addressID, customerID); 
		const response = await server.associateAddressToCustomer(addressID, customerID); 
		const data = await response.json(); 
		if(response.ok){
			dispatch( receiveAddressList(JSON.parse(data).list )); 
			resolve(true); 
		}else{
			//catch the error...
		}
	})
}

export const serviceAddresses = (customerID) => dispatch =>{
	return new Promise( async (resolve, reject) =>{
		const response = await server.serviceAddresses(customerID); 
		const data = await response.json(); 
		if(response.ok){
			dispatch( receiveAddressList(JSON.parse(data).list )); 
			resolve(); 
		}else{
			//catch the error...
		}
	})
}
export const assemblyList = ( addressID) => dispatch =>{
	return new Promise( async (resolve, reject) =>{
		const response = await server.assemblyList(addressID); 
		const data = await response.json(); 
		if(response.ok){
			dispatch( receiveAssemblyList(JSON.parse(data).list )); 
			resolve(); 
		}else{
			//catch the error...
		}
	})
}
export const newAddress = (addrObj, customerID) => dispatch =>{
	return new Promise( async (resolve, reject) =>{
		const response = await server.newAddress(addrObj, customerID); 
		const data = await response.json(); 
		if(response.ok){
			dispatch( receiveAddressList(JSON.parse(data).list)); 
			resolve(JSON.parse(data).addedID); 
		}else{
			//catch the error...
		}
	})
}
export const newAssembly = (assObj, customerID) => dispatch =>{
	return new Promise( async (resolve, reject) =>{
		const response = await server.newAssembly(assObj, customerID); 
		const data = await response.json(); 
		if(response.ok){
			dispatch( receiveAssemblyList(JSON.parse(data).list)); 
			resolve(); 
		}else{
			//catch the error...
		}
	})
}
export const removeAssembly = (assemblyID, addressID) => dispatch => {
	return new Promise( async (resolve, reject) =>{
		const response = await server.removeAssembly(assemblyID, addressID); 
		const data = await response.json(); 
		if(response.ok){
			dispatch( receiveAssemblyList(JSON.parse(data).list)); 
			resolve(); 
		}else{
			//catch the error...
		}
	})
}
export const removeAddress = (addressID, customerID) => dispatch =>{
	return new Promise( async (resolve, reject) =>{
		const response = await server.removeAddress(addressID, customerID); 
		const data = await response.json(); 
		if(response.ok){
			dispatch( receiveAddressList(JSON.parse(data).list)); 
			resolve(); 
		}else{
			//catch the error...
		}

	})
}
export const requestBillingDetails = (customerID) => dispatch =>{
	return new Promise( async (resolve, reject) =>{
		const response = await accountServer.requestBillingDetails(customerID); 
		const data = await response.json(); 
		if(response.ok){
			dispatch( receiveBillingDetails(JSON.parse(data).list)); 
			resolve(); 
		}else{
			//catch the error...
		}
	})
}
export const invoiceList = (customerID) => dispatch =>{
	return new Promise( async (resolve, reject) =>{
		const response = await server.invoiceList(customerID); 
		const data = await response.json(); 
		if(response.ok){
			dispatch( receiveInvoiceList(JSON.parse(data).list )); 
			resolve(); 
		}else{
			//catch the error...
		}
	})
}

export const paymentList = (customerID) => dispatch =>{
	return new Promise( async (resolve, reject) =>{
		const response = await server.paymentList(customerID); 
		const data = await response.json(); 
		if(response.ok){
			dispatch( receivePaymentList(JSON.parse(data).list )); 
			resolve(); 
		}else{
			//catch the error...
		}
	})
}
export const newContactLog = (logObj, customerID) => dispatch =>{
	return new Promise( async (resolve, reject) =>{
		const response = await server.newContactLog(logObj, customerID); 
		const data = await response.json(); 
		if(response.ok){
			dispatch( receiveLogList(JSON.parse(data).list )); 
			resolve(); 
		}else{
			//catch the error...
		}
	})
}
export const contactLogList = (customerID) => dispatch =>{
	return new Promise( async (resolve, reject) =>{
		const response = await server.contactLogList(customerID); 
		const data = await response.json(); 
		if(response.ok){
			dispatch( receiveLogList(JSON.parse(data).list )); 
			resolve(); 
		}else{
			//catch the error...
		}	
	})
}








