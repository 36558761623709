import {
 RECEIVE_TOTAL
} from "../../actions/waterDelivery";

const _nullTotal = null
                
export default (state = _nullTotal, { type, total}) => {
  Object.freeze(state);
  switch (type) {
    case RECEIVE_TOTAL:
      return total;
    case "": 
      return _nullTotal; 
    default:
      return state;
  }
};