import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import Table from 'react-bootstrap/Table';
import FloatingLabel from 'react-bootstrap/FloatingLabel';
import GaugeRow from "./GaugeRow"
import {useSelector, useDispatch} from "react-redux";
import { useState, useEffect, useRef, useCallback} from "react";
import {
	requestTesterDetails, 
	requestTesterList,
	setTesterID, 
	requestGaugesList
} from "../../../actions/employee.js"
import {setID, clearID, requestGaugeDetails}  from "../../../actions/gauges"
import GaugeForm from "./GaugeForm"
import NewGauge from "./NewGauge"


export default function GaugesApp(){
	const gauges = useSelector( (state) => state.employee_management_gauges)
	const [showModal, setShowModal] = useState(false)
	const dispatch = useDispatch(); 
	const[gaugeLoader, setGaugeLoader] = useState(false)
	const [currentGauge, setCurrentGauge] = useState(-1)

	useEffect(()=>{
		if(currentGauge != -1){
			dispatch( setID( currentGauge) ).then((data, err) =>{

			})
		}else{
			dispatch( clearID() ).then((data, err) =>{

			}) 
		}
	}, [currentGauge])


	useEffect(()=>{
		setGaugeLoader(false)
		dispatch( requestGaugesList () ).then((data, err) =>{
			setGaugeLoader(true)

		})
	}, [])
	

	const gaugesOptions = gauges.map((gg, index) =>{
		return(
			<option value = {gg.id}>{gg.serialN}</option>
		)
	})

	return(
		<Container className="main-container">
			<Modal show = { showModal} size = 'sm' centered>
				<Modal.Header closeButton onHide = { () => setShowModal(false) } >
					New Gauge
				</Modal.Header>
				<Modal.Body>
					<NewGauge closeModal = { ()=> {
						setShowModal(false)
							setGaugeLoader(false)
							dispatch( requestGaugesList () ).then((data, err) =>{
								setGaugeLoader(true)

							})

					}} />
				</Modal.Body>
			</Modal>
			<Row>
				<Col>
					<Form.Label> Gauge</Form.Label>
					<Form.Select value = {currentGauge} onChange = { (event) => setCurrentGauge(event.target.value) }>
						<option value = {-1}>Select a gauge </option>
						{
							gaugeLoader ? 
								<> {gaugesOptions}</>
							: 
								<> </>
						}

						
					</Form.Select>
				</Col>
				<Col>
				</Col>
				<Col>
					<Button onClick = { () => setShowModal(true)} > New Gauge </Button>
				</Col>
			</Row>
			<hr/>
			<Row>
				<Col>
					<GaugeForm gauge = {currentGauge} />
				</Col>
			</Row>

		</Container>
	)
}