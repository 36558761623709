import * as server from "../util/server";
import { GET_TEST_REPORT_LIST } from "./testReports";

export const GET_CURRENT_PRINT= "GET_CURRENT_PRINT"
export const GET_WATER_DISTRICT = "GET_WATER_DISTRICT"
export const CLEAR_WATER_DISTRICT = "CLEAR_WATER_DISTRICT"
export const GET_WATER_DISTRICT_LIST = "GET_WATER_DISTRICT_LIST"

export const GET_DISTRICTS  = "GET_DISTRICTS"
export const CLEAR_LIST = "CLEAR_LIST"

const receiveDistricts = (list) =>({
	type: GET_DISTRICTS, 
	list
})	

const updateCurrent = (printcurrent) =>({
	type: GET_CURRENT_PRINT, 
	printcurrent
})

const getTestReportList = (testreportlist) => ({
	type: GET_TEST_REPORT_LIST, 
	testreportlist
})

const receiveWaterDetails = (waterdistrict) =>({
	type: GET_WATER_DISTRICT, 
	waterdistrict
})

const clearClear = () =>({
	type: CLEAR_WATER_DISTRICT
})

const clearclearList = ()=> ({
	type: CLEAR_LIST
})

const receiveWaterDistrictList = (waterdistrictlist) =>({
	type: GET_WATER_DISTRICT_LIST, 
	waterdistrictlist
})

export const requestInvoicePDF = (invoiceID, token) => dispatch => {
	
}




export const getPrintable = (waterID) => dispatch =>{
	return new Promise ( async( resolve, reject) =>{
		let obj = { waterID: waterID }  
		const response = await server.requestWaterPDF(obj); 
		const data =  await response.json() ; 
		if(response.ok){
			if( !JSON.parse(data).success){
				reject( JSON.parse(data) )
			}else{
				console.log("getting PDF from this string: ", data);
				let blob = new Blob([data], { type: "application/pdf"});
				//resolve(blob)
				console.log("This is the link: ", JSON.parse(data).link)
				resolve(JSON.parse(data).link)
			}
			
		}else{
			reject(false)
		}
	})

}
export const requestWaterDistrictsOnline = () => dispatch =>{
	return new Promise ( async (resolve, reject) =>{
		const response = await server.requestOnlineWaterDistrictList(); 
		const data = await response.json(); 
		if(response.ok){
			dispatch( receiveDistricts ( JSON.parse(data).list ) ) ; 
			resolve(true); 
		}
	})
}

export const requestWaterDistrictsMail = () => dispatch =>{
	return new Promise( async(resolve, reject) =>{
		const response = await server.requestMailWaterDistrictList(); 
		const data = await response.json(); 
		if(response.ok){
			dispatch( receiveDistricts( JSON.parse(data).list ) ); 
			resolve(true)
		}
	})
}

export const requestWaterDistrictsEmail = () => dispatch => {
	return new Promise( async(resolve, reject) =>{
		const response = await server.requestEmailWaterDistrictList(); 
		const data = await response.json(); 
		if(response.ok){
			dispatch( receiveDistricts( JSON.parse(data).list ) ); 
			resolve(true)
		}
	})
}

export const requestWaterDistrictDetails = (waterID) => dispatch =>{
	return new Promise( async(resolve, reject) =>{
		let obj = { waterID : waterID }
		const response = await server.requestWaterDistrictDetails(obj); 
		const data = await response.json(); 

		if(response.ok){
			dispatch( receiveWaterDetails(JSON.parse(data).waterDistrict)); 
			resolve(true)
		}
	})
}

export const sendPrintJobs = (printJobList, printer) => dispatch => {
	return new Promise( async (resolve, reject) =>{
		for( let i = 0;  i < printJobList.length;  i++){
			let currObj = { current: printJobList[i], remaining: printJobList.length - i, total: printJobList.length}
			dispatch( updateCurrent(currObj) )

			let obj = { testReport: printJobList[i], printer: printer  }
			const response = await server.printWaterReport(obj);  
			const data = await response.json()
			
		}
		
		const response2 = await server.getPendingTestReport(); 
		const data2 = await response2.json(); 
		if(response2.ok){
			let obj = { list: JSON.parse(data2).testReportList}
			dispatch( getTestReportList( obj) )
		}
		
		resolve(true)
	})
}

export const checkOffReports  = (reportlist) => dispatch=> {
	return new Promise( async (resolve, reject) =>{
		let obj = { list: reportlist }
		const response = await server.checkOffReports(obj); 
		const data = await response.json(); 

		if(response.ok){

			const response2 = await server.getPendingTestReport(); 
			const data2 = await response2.json(); 
			if(response2.ok){
				let obj = { list: JSON.parse(data2).testReportList}
				dispatch( getTestReportList( obj) )
				resolve(true)
			}
		}
	})

}

export const getDistrict = (districtID) => dispatch => {
	return new Promise (async (resolve, reject) =>{
		//console.log("GETTING THIS WATER DISTRICT: ", districtID)
		let obj = { waterID : districtID }
		const response = await server.requestWaterDistrictDetails(obj); 
		const data = await response.json(); 

		if(response.ok){
			dispatch( receiveWaterDetails(JSON.parse(data).waterDistrict)); 
			console.log("Got this district", JSON.parse(data).waterDistrict)
			resolve(JSON.parse(data).waterDistrict)
		}else{
			reject()
		}
	})
}