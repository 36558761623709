import {
  RECEIVE_LINE_ITEMS,
  NO_LIST_ITEMS
} from "../../actions/invoice";

const _nullItemList = { 
    list : []
}
                
export default (state = _nullItemList, { type, itemlist }) => {
  Object.freeze(state);
  switch (type) {
    case RECEIVE_LINE_ITEMS:
      return itemlist;
  case NO_LIST_ITEMS:
    return _nullItemList; 

  default:
      return state;
  }
};