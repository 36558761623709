import {
  SET_SERVICES_REPORT_CONTACT
} from "../../actions/servicesReport";

const _nullContact =  null; 
                
export default (state = _nullContact, { type, contact }) => {
  Object.freeze(state);
  switch (type) {
    case SET_SERVICES_REPORT_CONTACT:
      return contact;
    case "": 
      return _nullContact; 
    default:
      return state;
  }
};