export const createContact = (obj) => (
	fetch("backflow/wdManager/contact", {
		method: "POST",
		body: JSON.stringify(obj),
		headers: {
			"Content-Type": "application/json"
		}
	})
)
export const createDistrict = (obj) => (
	fetch("backflow/wdManager/district", {
		method: "POST",
		body: JSON.stringify(obj),
		headers: {
			"Content-Type": "application/json"
		}
	})
)

export const requestWaterList = (query) => (
	fetch("backflow/wdManager/search?" + new URLSearchParams({
		query: query
	}), {
		method: "GET", 
		headers: {
            "Content-Type" : "application/json"
        }
	}) 
)
export const requestWaterDetails = (id) => (
	fetch("backflow/wdManager?" + new URLSearchParams({
		id: id
	}), {
		method: "GET", 
		headers: {
            "Content-Type" : "application/json"
        }
	}) 
)

export const getDistrictContacts = (id) => (
	fetch("backflow/wdManager/contacts?" + new URLSearchParams({
		id: id
	}), {
		method: "GET",
		headers: {
			"Content-Type": "application/json"
		}
	})
)

export const getContactDetails = (id) => (
	fetch("backflow/wdManager/contactDetails?" + new URLSearchParams({
		id: id
	}), {
		method: "GET",
		headers: {
			"Content-Type": "application/json"
		}
	})
)

export const updateDistrictDetails = (obj) => (
	fetch("backflow/wdManager/district", {
		method: "PUT",
		body: JSON.stringify(obj), 
		headers: {
			"Content-Type": "application/json"
		}
	})
)

export const updateContactDetails = (obj) => (
	fetch("backflow/wdManager/contact", {
		method: "PUT",
		body: JSON.stringify(obj),
		headers: {
			"Content-Type": "application/json"
		}
	})
)



