import Alert from 'react-bootstrap/Alert';
import Accordion from 'react-bootstrap/Accordion';
import Card from 'react-bootstrap/Card';
import Button from 'react-bootstrap/Button';
import ButtonGroup from 'react-bootstrap/ButtonGroup';
import Table from 'react-bootstrap/Table';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Modal from 'react-bootstrap/Modal';
import ListGroup from 'react-bootstrap/ListGroup';
import Badge from 'react-bootstrap/Badge';
import Spinner from 'react-bootstrap/Spinner';
import Offcanvas from 'react-bootstrap/Offcanvas';
import CardGroup from 'react-bootstrap/CardGroup';
import Container from 'react-bootstrap/Container';
import Breadcrumb from 'react-bootstrap/Breadcrumb';
import Pagination from 'react-bootstrap/Pagination';
import {useSelector, useDispatch} from "react-redux";
import { useState, useEffect, useRef, useCallback} from "react";
import { getTotalsList  } from "../../../actions/home.js"
import { getTestersStops , getStops} from "../../../actions/scheduling.js"
import {format } from "date-fns"
import {
	setServicesReportDate, 
	requestServicesReportList, 
	reduceStopID,
	clearStop, 
	cancelStop, 
	requestStopAssemblies, 
requestInvoiceStatus
} from "../../../actions/servicesReport.js"
import {setAddress, setContact, setDetails, setServices, createStop} from "../../../actions/newstop"
import { newCustomerID, createCustomer, associateAddressToCustomer} from "../../../actions/customerApp.js" 
import StopOptions from "./StopOptions"
import NewStop from "./newstop/NewStop"
import TesterStops from "./TesterStops"
import { requestTesterNames } from "../../../actions/dispatch"


export default function ServicesReport(props){
	const [testerToShow, setTesterToShow] = useState('') 
	const official = useSelector((state) => state.schedule_calendarlist)
	const [showTesters, setShowTesters] = useState(false)
	const dispatch = useDispatch(); 
	const [calendarLoaded, setCalendarLoaded] = useState(false)
	const testerList = useSelector( (state) => state.testers);
	const [showNotTested, setShowNotTested] = useState(false)
	const [date, setDate] = useState( format( new Date(), "yyyy-MM-dd")  )
	const customerID = useSelector((state) => state.customerID)
	const [newStop, setNewStop] = useState(false)
	const newstopDetails = useSelector((state) => state.newstop_details)
	const newstopContact = useSelector((state) => state.newstop_contact)
	const newstopAddress = useSelector((state) => state.newstop_address)
	const newstopServices = useSelector((state) => state.newstop_services)
	const [total, setTotal] = useState(0)
	const [offset, setOffset] = useState(0)
	const mytotal = useSelector((state) => state.schedule_total)
	const [toShow, setToShow] = useState(8);
	const [active, setActive] = useState(1)
	const [totalPages, setTotalPages] = useState(1)
	const [paginated, setPaginated] = useState([])

	useEffect(() => {
		let newlist = []
		for (let number = 1; number <= totalPages; number++) {
			newlist.push(
				<Pagination.Item key={number} active={number === active} onClick={() => setActive(number) }>
					{number}
				</Pagination.Item>
			)
		}
		setPaginated(newlist)
		setCalendarLoaded(false)
		dispatch(getTestersStops(date, testerToShow, active)).then((data, err) => {
			setCalendarLoaded(true)
		})
	}, [totalPages, active])
	
	useEffect(() => {
		if (mytotal > 0) {
			setTotalPages(Math.ceil(mytotal / toShow ) )			
		}
	}, [mytotal])



	useEffect( () =>{
		setShowTesters(false)
		dispatch( requestTesterNames() ).then((data, err) =>{
			setShowTesters(true)
			
		})
	}, [])

	useEffect(() => {
		setCalendarLoaded(false)
		dispatch(getTestersStops(date, testerToShow, active )).then((data, err) => {
			setCalendarLoaded(true)
		})
	}, [customerID])

	useEffect(()=>{
		if(showNotTested){
			let tempTotal = 0
			for(let i = 0; i < official.length; i++){
				if( official[i].status.toUpperCase() != "COMPLETED" ){
					tempTotal++
				}
			}
			setTotal(mytotal)
		}else{
			setTotal(mytotal)
		}
	}, [showNotTested, official])

	const renderCalendar = official.map( (record, index) =>{
		if( showNotTested){
			if( record.status.toUpperCase() != "COMPLETED" ){
				return(
					<Col>
						<TesterStops record = {record} nth = {index}/>
					</Col>
				)
			}
		}else{
			return(
				<Col>
					<TesterStops record = {record} nth = {index}/>
				</Col>
			)
		}	
	})
			  
	useEffect(()=>{
		console.log("This is the date selected: ", date)
		setCalendarLoaded(false)
		setActive(1)
		dispatch( getTestersStops( date, testerToShow, active) ) .then((data, err) =>{
			setCalendarLoaded(true)
		})
	}, [date, testerToShow])

	const testerOptions = testerList.map( (tt) =>{
		if(tt.tester){
			return(
				<option value = {tt.id}>
					{tt.tester_name}
				</option>
			)
		}else{
			return(
				<> </>
			)
		}	
	})

	return(
		<>
			<Modal show = {newStop} fullscreen>
				<Modal.Header closeButton onHide = { 
					async ()=>{
						let p1 = await dispatch( setServices([]) ); 
						let p2 = await dispatch( setDetails({
							tester: null, 
							date: new Date(), 
							startTime :  "08:00", 
							endTime: "16:00", 
							comment: null
						}))
						Promise.all([p1, p2]).then( (data, err) => {
							setNewStop(false)
						})
				}}>
					<Modal.Title>New Stop </Modal.Title>
				</Modal.Header>
				<Modal.Body>
					<NewStop />
				 </Modal.Body>
				 <Modal.Footer>
				 	<Button
				 		onClick = { 
				 			()=>{
				 				let obj = {}; 
				 				obj.details = newstopDetails; 
				 				obj.contact = newstopContact; 
				 				obj.service = newstopServices; 
				 				obj.address = newstopAddress; 
				 				dispatch( createStop(obj) ).then((data, err) =>{
				 					setCalendarLoaded(false)
									let actualDate = new Date(date); 
									actualDate.setDate( actualDate.getDate() + 1)
									dispatch( getTestersStops( format(new Date(actualDate), "yyyy-MM-dd" )), testerToShow, active ) .then((data, err) =>{
										setCalendarLoaded(true)
										setNewStop(false)
									})
				 				})
				 			}
				 		}

				 	>
				 		Add Stop
				 	</Button>
				 </Modal.Footer>
			</Modal>
			<Container className= "servicesContainer" >
				<br/>
				<Row>
					<Col sm md lg = {2}>
						<Container>
							<Form.Label> Select a date </Form.Label>
							<Form.Control 
								type="date" 
								value={date} 
								onChange = {( event ) => setDate(event.target.value)} 
							/>
						</Container>
					</Col>
					<Col >
						<Container>

							<Row sm md lg = {10}> 
								<Col>
									<Form.Label> Select tester to show </Form.Label>
									<Form.Select 
										onChange = {(event)=> {
											setTesterToShow(event.target.value)
										}} 
									>
										<option value = '' > Show all </option>
										{	
											showTesters ? 
												<> {testerOptions} </>
											: 
												<></>
										}

									</Form.Select>
								</Col>
								
							</Row> 

						</Container>

					</Col>
					<Col>	
						<Container>
							<h5> {total} stops </h5>
							<Form.Check 
								label = "Show Stops Not Completed"
								checked = {showNotTested}
								onChange = { (event)=>{ setShowNotTested(event.target.checked)}}
							/>
						</Container>
					</Col>
					
				</Row>
				<hr/>
		


						{
							calendarLoaded ?
								<>
									{
										official.length ?
										<>
										
										<Row sm md lg={4} className="calend">
												{renderCalendar}
											</Row>
											<Row>
											<Pagination>
												{paginated}
											</Pagination>
												</Row>
											
										</>
										:
											<> No Stops To Show</>
									}
									
								</>

							: 
								<>
									<Spinner />
								</>
						}
				
						
			</Container>
		</>
	)
}