import {
  RECEIVE_INVOICE,  
  CLEAR_INVOICE
} from "../../actions/invoice.js";

const _nullInvoice = null; 
                
export default (state = _nullInvoice, { type, invoice }) => {
  Object.freeze(state);
  switch (type) {
    case RECEIVE_INVOICE:
      return invoice;
    case CLEAR_INVOICE: 
      return _nullInvoice; 
    default:
      return state;
  }
};