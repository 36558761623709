import {
 SET_WATER_ID, SET_CLEAR_WATER_ID
} from "../../actions/waterDelivery";

const _nullID = null
                
export default (state = _nullID, { type, id}) => {
  Object.freeze(state);
  switch (type) {
    case SET_WATER_ID:
      return id;
    case SET_CLEAR_WATER_ID: 
      return _nullID; 
    case "": 
      return _nullID; 
    default:
      return state;
  }
};