

import * as server from "../util/dump.js"

export const GET_WATER_DISTRICT_FILTER = "GET_WATER_DISTRICT_FILTER"; 
export const GET_DUMP_REPORTS = "GET_DUMP_REPORTS"


const receiveReportList = (list) =>({
	type: GET_DUMP_REPORTS, 
	list
})
const receiveWaterFilter = (district) =>({
	type: GET_WATER_DISTRICT_FILTER, 
	district
})

export const requestReportList = () => dispatch =>{
	return new Promise(async ( resolve, reject)=>{
		const response = await server.requestReportList(); 
		const data = await response.json(); 
		if(response.ok){
			dispatch( receiveReportList( JSON.parse(data).list )  )
			resolve(); 
		}
	})
}
export const requestDate = (reportID) => {
	return new Promise(async ( resolve, reject)=>{
		const response = await server.requestDate(reportID); 
		const data = await response.json(); 
		if(response.ok){
			resolve( JSON.parse(data).obj )
		}
	})
}
export const requestResults = (reportID) =>{
	return new Promise(async ( resolve, reject)=>{
		const response = await server.requestResults(reportID); 
		const data = await response.json(); 
		if(response.ok){
			resolve( JSON.parse(data).obj )
		}
	})
}
export const requestWaterDistrict = (reportID) =>{
	return new Promise(async ( resolve, reject)=>{
		const response = await server.requestWaterDistrict(reportID); 
		const data = await response.json(); 
		if(response.ok){
			resolve( JSON.parse(data).obj )
		}
	})
}