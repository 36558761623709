import Table from 'react-bootstrap/Table';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import Badge from 'react-bootstrap/Badge';
import Spinner from 'react-bootstrap/Spinner';
import ButtonGroup from 'react-bootstrap/ButtonGroup';
import Form from 'react-bootstrap/Form';
import { useState, useEffect, useRef, useCallback} from "react";
import {useSelector, useDispatch} from "react-redux";
import { 
	getList, 	
getTotal, 
getTotals, 
determineDate, 
getPrintable, 
requestSharedLink, 
sendEmails, 
getTotalSent, 
getTotalPDFed,
getTotalInTransit
} from "../../../actions/waterDelivery.js"

import { getDistrict } from  "../../../actions/waterDistricts"
import { format } from "date-fns"
import {CheckMarkAnimationSmall} from "../animations.js"
import OnlineInput from "./OnlineInput"; 


/*SEND EMAILS MODULE*/
function GetEmail(props){
	const [email, setEmail] = useState("")
	const [sEmail, setsEmail] = useState("")
	const [showSecond, setShowSecond] = useState(false)
	const currentDate = useSelector((state) => state.deliveryDate)
	const [markAsSent, setMarkAsSent] = useState(false); 
	const [loader, setLoader] = useState(false)

	useEffect(() =>{
		setEmail(props.district.email1)
		if( props.district.email2 == ""){
			setShowSecond(false)
		}else{
			setShowSecond(true)
		}
	}, [])

	const sendReports = (event) =>{
		setLoader(true)
		sendEmails( currentDate, props.district.waterDistrictID, email, markAsSent).then((date, err) =>{
			setLoader(false)
			props.refreshList(); 
			props.closeModal()
		})
		event.preventDefault(); 
	}

	return(
		<>
			<Modal.Header  closeButton onHide = { ()=> props.closeModal() }>
			</Modal.Header>
			<Form onSubmit = { sendReports }>
			<Modal.Body>
			
			<Form.Label> Email Address</Form.Label>
				<Form.Control type = "email" value={email} onChange = { (event) => setEmail(event.target.value) }/>
				{
					showSecond ?
						<Form.Check  label ={sEmail} />
					:
						<> </>
				}
			
				<Form.Check label ="Mark These Reports As Sent" checked= {markAsSent} onClick = { 
						(event)=>{
							setMarkAsSent(event.target.checked)
						}
					}/>
				<Form.Check label ="Printer Email" onClick = {
					(event) =>{ 
							if(event.target.checked){
								setEmail("This would be the printer email")
							}else{
								setEmail(props.district.email1)
							}
						}
					}
				/>
			</Modal.Body>
			<Modal.Footer>
				{	
					loader ?
						<Spinner />
					:
						<Button type="submit">
							send
						</Button>
				}
				
			</Modal.Footer>
			</Form>
		</>
	)

}

export default function RowRowRow(props){
	const [x, setX] = useState(null)
	const [y, setY] = useState(null)
	const [z, setZ] =  useState(null)
	const [sent, setSent] = useState(null)
	const [pdfed, setPDFed] = useState(null)
	const [mylink, setMyLink] = useState(null); 
	const [showRow, setShowRow] = useState(false)
	const [disableLink, setDisableLink] = useState(false)
	const dateSelected = useSelector((state) => state.deliveryDate)
	const [sendButtonClicked, setSendButtonClicked] = useState(false); 
	const [sameXY, setSameXY] = useState(false)
	const dispatch = useDispatch(); 
	const [showSmall, setShowSmall] = useState(false) 
	useEffect(()=>{
		if(x == y){
			setSameXY(true)
		}else{
			setSameXY(false)
		}

	}, [x,y])

	useEffect(()=>{
		if(y == 0){
			setShowRow(false)
		}else{
			setShowRow(true)
		}
	}, [y])

	useEffect(()=>{

		requestSharedLink( props.ww.waterDistrictID, dateSelected).then( (data, err) =>{
			setMyLink(data)
		})
		getTotalSent( dateSelected, props.ww.waterDistrictID).then( (data, err) =>{
			setSent(data)
		})
		getTotalPDFed( dateSelected, props.ww.waterDistrictID).then( (data, err) =>{
			setPDFed(data)
		})

		getTotalInTransit( props.ww.waterDistrictID, dateSelected ).then((data, err) =>{
			setZ(data)
		})

	}, [])


	const [complete, setComplete] = useState(false)
	const currentDate = useSelector((state) => state.deliveryDate)
	const [show, setShow] = useState(false)
	
	const [modalBody, setModalBody] = useState(<> </>)
	const openLink = () =>{
		if(mylink != ""){
			/*open the link in a different tab*/
			console.log("Opening: ", mylink)
			window.open(mylink, "_blank", "noreferrer");
			
		}
	}

	const sendEmailToWater = () => {
		setSendButtonClicked(true)
		sendEmails( currentDate, props.ww.waterDistrictID, true ).then((date, err) =>{
			console.log("call to automated resolved!")
		})
	}

	

	const [loader, setLoader] = useState(false); 

	useEffect(()=>{
		if(mylink == "" || mylink == null){
			setDisableLink(true)
		}else{
			setDisableLink(false)
		}
	}, [mylink])


	useEffect(()=>{
		getTotals(  currentDate, props.ww.waterDistrictID).then((data, err) =>{
			console.log("Got this back: ", data)
			setX(data.sent)
			setY(data.total)
			
		})
	}, [])

	useEffect(()=>{
		if(sent == x){
			setComplete(false)
		}else{
			setComplete(true)
		}
	}, [sent, x])


	function Analytics(){
		if(props.ww.deliveryMethod.toUpperCase() == "MAIL" || props.ww.deliveryMethod.toUpperCase() == "E-MAIL"){
			return(
				<>	
					{
								complete ?
									<Badge bg="success">Complete</Badge>
								: 
									<>
										
										Documents available: {pdfed}<br/>
										Reports in transit: {z} <br/>
										Total Reports: {y}
									</>
							}
				</>
			)
		}else{
			return(
				<>{	
					sameXY  ? 
						<>
								<Badge bg="success">Complete</Badge>
						</>
					: 
						<>
						{y - x} pending
					</>

				}	
				</>
			)
		}
	}

	function Actions(){
		if(props.ww.deliveryMethod.toUpperCase() == "MAIL"){
			return(
					<ButtonGroup vertical>
						{
							loader ?
								<Spinner />
							:
								<Button 
									size="sm" 
									disabled ={sendButtonClicked || complete}
									onClick ={ ()=>{
										setModalBody(<GetEmail closeModal = {()=>setShowSmall(false)} district={props.ww} refreshList= { ()=> props.refreshList()}/>)
										setShowSmall(true)
									}}
								> 
									Send
								</Button>
						}
						<Button size="sm" 
						onClick={ openLink }
						disabled ={ disableLink}>  
							Review Reports
						</Button>
					</ButtonGroup>
			)
		}else if( props.ww.deliveryMethod.toUpperCase() == "E-MAIL"){
			return(
					<ButtonGroup vertical>
						<Button 
							size="sm" 
							disabled ={sendButtonClicked || complete}
							onClick ={ ()=>{
										setModalBody(<GetEmail closeModal = {()=>setShowSmall(false)} district={props.ww} refreshList= { ()=> props.refreshList()}/>)
										setShowSmall(true)
									}}
						> 
							Send
						</Button>

						<Button onClick={ openLink } size="sm" disabled = {disableLink}>
							Review Reports
						</Button>
					</ButtonGroup>
			)
		}else{
			return(

					<ButtonGroup>
						<Button 
							size="sm" 
							
							onClick={ () =>{
								
								dispatch( getDistrict(props.ww.waterDistrictID) ) .then((data, err) =>{
									setModalBody(
										<OnlineInput 
										closeModal = { () => setShow(false)}  
										waterID = { props.ww.waterDistrictID } 
										refreshList= { ()=> props.refreshList()}
										hasDate = {true}
										/>
									)
									setShow(true); 
								})
								
							}}
							> 
								Show Reports 
						</Button>
					</ButtonGroup>
			)
		}
	}
	return(
		<>
			<Modal show ={show}  fullscreen>
				{modalBody}
			</Modal>
			<Modal show= {showSmall} size="sm" centered>
				{modalBody}
			</Modal>
			{
				showRow ?
					<tr>
						<td>
							{props.ww.districtName}
						</td>
						<td>
							{props.ww.deliveryMethod}
						</td>
						<td>
							<Analytics />
							
						</td>
						<td>
							<Actions />

						</td>
					</tr>
				:
					<></>
			}
			
		</>
	)
}