import { useState, useEffect, useRef, useCallback} from "react";
import {useSelector, useDispatch} from "react-redux";
import Card from 'react-bootstrap/Card';
import Form from 'react-bootstrap/Form';
import Table from 'react-bootstrap/Table';
import { updateScheduleServiceList} from "../../../../actions/addressApp"


export default function NewStopRow(props){
	const [selected, setSelected] = useState(true)
	const [service, setService]  = useState("TEST")
	const [allow, setAllow] = useState(false)
	const currentList = useSelector( (state) => state.schedule_services_list); 
	const dispatch = useDispatch(); 
	const assemblyList = useSelector((state) => state.address_assemblies)

	useEffect(()=>{
		if(!selected){
			setService("NONE")
		}else{
			setService("TEST")
		}
	}, [selected])

	useEffect(()=>{
		if(props.assembly.state.toUpperCase() == "REMOVED"){
			setAllow(false)
		}else{
			setAllow(true)
		}
	}, [])

	useEffect(()=>{
	
	

		let newList = []; 
		for(let i = 0; i < assemblyList.length;  i++){
			if(  i == props.index){
				let obj = {};
				obj.assemblyID = assemblyList[i].id;
				obj.serviceType = service; 
				obj.selected = selected; 
				newList.push(obj)
			}else{
				let obj = {}
				obj.assemblyID = assemblyList[i].id; 
				obj.serviceType = currentList[i].serviceType; 
				obj.selected = currentList[i].selected; 
				newList.push(obj)
			}
		}
		dispatch( updateScheduleServiceList(newList) ).then((resolve, reject) =>{
		})
		
	}, [selected, service])



	return(
		<>	
			{
				allow ?
					<tr>
						<td>
							<Form.Check checked ={selected} onClick = {(event) => setSelected(event.target.checked)} /> 
						</td>
						<td>
							{props.assembly.serial_number}
						</td>
						<td>
							{props.assembly.location}
						</td>
						<td>
							<Form.Select size="sm" value ={service} onChange= {(event) => setService(event.target.value) }>
								<option value="NONE">No Service</option>
								<option value="TEST">Test</option>
								<option value="REPAIR">Repair</option>
								<option value="REPLACEMENT">Replacement</option>
								<option value ="REMOVE">Remove</option>
								<option value="INSTALL">New install</option>
							</Form.Select>
						</td>
					</tr>
				: 
					<>	
					</>
			}
					
		</>
	)
}