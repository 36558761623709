import Accordion from 'react-bootstrap/Accordion';
import Row from 'react-bootstrap/Row';	
import Table from 'react-bootstrap/Table';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import Spinner from 'react-bootstrap/Spinner';
import Container from 'react-bootstrap/Container';

import { useState, useEffect, useRef, useCallback} from "react";
import {useSelector, useDispatch} from "react-redux";

import { invoiceList, paymentList } from "../../../../actions/customerApp.js"
import {format} from "date-fns"

export default function InvoiceList(){
	const customerID = useSelector( (state)=> state.customerID)
	const [loader, setLoader] = useState(true)
	const invoicelist = useSelector((state) =>state.customerInvoices)
	const paymentlist = useSelector( (state) => state.customer_payments)
	const [combinedList, setCombinedList] = useState([])
	const dispatch = useDispatch(); 
	useEffect(()=>{
		console.log("requesting list of invoices for this customer")
		dispatch( invoiceList( customerID) ).then((data,err) =>{
			console.log("Done getting the invoice list...")

		}) 
	}, [customerID])

	useEffect( ()=>{
		dispatch( paymentList( customerID) ).then((data,err) =>{
			console.log("Done getting the invoice list...")

		}) 
	}, [customerID])
	const renderPayments = paymentlist.map( (payment) => {
		return(
			<tr>
				<td>
					{format( new Date( payment.date), "MM/dd/yyyy" )}
				</td>
				<td>
					PAYMENT
				</td>
				<td>
					{ payment.qb_id}
				</td>
				<td>
					-${payment.amount}					
				</td>
				
			</tr>
		)

	})

	const renderInvoices = invoicelist.map( (invoice) =>{
		return(
			<tr>
				<td>
					{format( new Date( invoice.service_date), "MM/dd/yyyy" )}
				</td>
				<td>
					INVOICE
				</td>
				<td>
					{ invoice.qb_docNum}
				</td>
				<td>
					${invoice.total}					
				</td>
				
			</tr>
		)
	}) 
	return(
		<>
			<Table stripped bordered size="sm">
				<thead>
					<tr>
						<th>	
							Date
						</th>
						<th>
							Type
						</th>
						<th>
							Number
						</th>
					
						<th>
							Amount
						</th>
					
					</tr>
				</thead>
				<tbody>
					{renderInvoices}
					{renderPayments}
				</tbody>
			</Table>
		</>
	)
}