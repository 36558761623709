import Form from 'react-bootstrap/Form';
import Nav from 'react-bootstrap/Nav';
import Table from 'react-bootstrap/Table';
import Modal from 'react-bootstrap/Modal';
import Spinner from 'react-bootstrap/Spinner';
import Alert from 'react-bootstrap/Alert';
import Card from 'react-bootstrap/Card';
import Button from 'react-bootstrap/Button';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import CardGroup from 'react-bootstrap/CardGroup';
import Container from 'react-bootstrap/Container';
import Breadcrumb from 'react-bootstrap/Breadcrumb';
import {useSelector, useDispatch} from "react-redux";
import { useState, useEffect, useRef, useCallback} from "react";
import ItemLine from "./ItemLine"
import {requestLineItemDetails,
 requestServiceAddress, 
 requestLineItems, removeItem, addItem } from "../../../actions/invoiceBuilder.js"

export default function InvoiceItems(){


	const balance = useSelector( (state) => state.invoice_balance) 
	const [strBalance, setStrBalance] = useState('')
	const dispatch = useDispatch(); 
	const tax_rate = useSelector((state) => state.invoice_tax_rate)
	const [taxRateLoader, setTaxRateLoader] = useState(true)
	const itemlist = useSelector( (state) => state.builder_items)
	const [loader, setLoader] = useState(false)
	const invoiceID = useSelector((state) => state.builder_id)


	useEffect(()=>{
		setLoader(true)	
		dispatch( requestLineItems(invoiceID) ).then((data, err) =>{
			setLoader(false)

		})
	}, [])



	const renderItems = itemlist.map( (item) =>{
		return(
			<ItemLine  id = {item.id}/>
		)
	} )

	

	useEffect(()=>{
		setStrBalance(balance.toFixed(2))
	}, [balance])

	return(
		<Container>
			{
				loader ?
					<>
						<Spinner />
					</>
				: 
					<>
						<Row>
							<Col>
								<Table size="sm" responsive bordered striped>
									<thead>
										<tr>
											<th>
												Item Name
											</th>
											<th>
												Description
											</th>
											<th>
												Quantity
											</th>
											<th>
												Unit Price
											</th>
											<th>
												Taxable
											</th>
											<th>
												Amount
											</th>
											<th>
												Actions
											</th>
										</tr>
									</thead>
									<tbody>
								
										{renderItems}
										<tr> 
											<td colSpan={7}>
												<Button size="sm"
													onClick = {
														() =>{

															addItem(invoiceID).then((data, err) =>{
																
																dispatch( requestLineItems(invoiceID) ).then((data, err) =>{

																})

															})
														}
													}
													
												> + 
												</Button> 
											</td>
										
										</tr>
										<tr>

										</tr>
										{
											taxRateLoader ?
												<> </>
											: 
											<>
												<tr>
													<td colSpan ={4}>Tax Rate</td>
													<td colSpan = {3}>{Number( tax_rate.rate) * 100}%</td>
												</tr>
												<tr>
													<td colSpan = {4}> Total With Tax </td>
													<td colSpan = {3}> ${strBalance}</td>
												</tr>
											</>
										}
												
									</tbody>
								</Table>
							</Col>
						</Row>

					</>
			}
						
		</Container>
	)
}