import {
  SET_LIST
} from "../../actions/failed";

const _nullList = []
                
export default (state = _nullList, { type, list}) => {
  switch (type) {
    case SET_LIST:
      return list;
    case "": 
      return _nullList; 
    default:
      return state;
  }
};