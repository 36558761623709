import {
  SET_ADDRESS_ID
} from "../../actions/scheduling";

const _nullAddressID = 60605; 
                
export default (state = _nullAddressID, { type, id }) => {
  Object.freeze(state);
  switch (type) {
    case SET_ADDRESS_ID:
      return id;
    case "": 
      return _nullAddressID; 
    default:
      return state;
  }
};