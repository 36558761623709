import Button from 'react-bootstrap/Button'
import ButtonGroup from 'react-bootstrap/ButtonGroup'
import Card from 'react-bootstrap/Card'
import Table from 'react-bootstrap/Table'
import Form from 'react-bootstrap/Form'
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Modal from 'react-bootstrap/Modal'
import Accordion from 'react-bootstrap/Accordion'

import AnnouncementCard from "../AnnouncementCard"
import CompanyChat from "../CompanyChat"
import StaffStatus from "../StaffStatus"



export default function HomeApp(){
	return(
		<>
			<Row>
				<Col>
					<AnnouncementCard />
					{/*<StaffStatus />*/}
				</Col>
				
				{/*<Col>
					<CompanyChat />
				</Col>*/}
			</Row>
		</>
	)
}