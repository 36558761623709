import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Dropdown from 'react-bootstrap/Dropdown';
import {useSelector, useDispatch} from "react-redux";
import { useState, useEffect, useRef, useCallback} from "react";
import DumpTable from "./DumpTable"

export default function DumpFilters(){
	return(
		<Container>
			<Row>
				<Col>
					<Dropdown>
				      <Dropdown.Toggle variant="light" id="dropdown-basic">
				      	 Water District
				      </Dropdown.Toggle>
				      <Dropdown.Menu>
				        <Dropdown.Item href="#/action-1">Action</Dropdown.Item>
				        <Dropdown.Item href="#/action-2">Another action</Dropdown.Item>
				        <Dropdown.Item href="#/action-3">Something else</Dropdown.Item>
				      </Dropdown.Menu>
				    </Dropdown>
				</Col>
			</Row>
		</Container>
	)
}