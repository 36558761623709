import {
  RECEIVE_SESSION,  
  CLEAR_SESSION
} from "../../actions/session";

import {format }from 'date-fns'

const _nullSession = null
                
export default (state = _nullSession, { type, session }) => {
  Object.freeze(state);
  switch (type) {
    case RECEIVE_SESSION:
      return session;
    case CLEAR_SESSION: 
      return _nullSession; 
    default:
      return state;
  }
};