const controller = new AbortController();
const signal = controller.signal;

export const updateStopDetails = (stopID, obj) => (
	fetch("backflow/stops/details",{
		method: "PUT",
		body: JSON.stringify( {stopID: stopID, obj: obj} ) , 
		headers: {
            "Content-Type" : "application/json"
        }
	}) 
)

export const requestStopAssemblies = (stopID) => (
	fetch("backflow/stops/assemblies?" + new URLSearchParams({
		stopID: stopID

	}),{
		method: "GET", 
		headers: {
            "Content-Type" : "application/json"
        }
	}) 
)
export const requestInvoiceStatus = (stopID)=>(
	fetch("backflow/stops/invoice?" + new URLSearchParams({
		stopID: stopID

	}), {
		method: "GET", 
		headers: {
            "Content-Type" : "application/json"
        }
	}) 

)
export const cancelStop = (stopID, reason) => (
	fetch("backflow/stops/cancel", {
		method: "POST", 
		body: JSON.stringify( {stopID: stopID, reason: reason} ), 
		headers: {
            "Content-Type" : "application/json"
        }
	}) 
)


export const getTestReport = (reportID) =>(
	fetch("backflow/stops/report?" + new URLSearchParams({
		reportID: reportID
	}), {
		method: "GET", 
		headers: {
            "Content-Type" : "application/json"
        }
	})
)
export const updateDate = (stopID, newdate) =>(
	fetch("backflow/stops/date", {
		method: "PUT", 
		body: JSON.stringify( {stopID: stopID, newdate: newdate} ), 
		headers: {
            "Content-Type" : "application/json"
        }
	}) 
) 
export const updateAssembly = (assemblyID, obj ) =>(
	fetch("backflow/assembly", {
		method: "PUT", 	
		body: JSON.stringify({assemblyID: assemblyID, obj: obj}), 
		headers: {
            "Content-Type" : "application/json"
        }
	})

)

export const createAssembly = (addressID, stopID) => (
	fetch("backflow/assembly", {
		method: "POST", 	
		body: JSON.stringify({addressID: addressID, stopID: stopID}), 
		headers: {
            "Content-Type" : "application/json"
        }
	})
); 

export const updateTimeFrame = (stopID, startTime, endTime) => (
	fetch("backflow/stops/timeFrame", {
		method: "PUT", 
		body: JSON.stringify( {stopID: stopID, startTime: startTime, endTime: endTime} ), 
		headers: {
            "Content-Type" : "application/json"
        }
	}) 

)


export  const updateAddress = (addressID, obj,  stopID) =>(
	fetch("backflow/stops/address", {
		method: "PUT", 
		body: JSON.stringify( {addressID : addressID, obj: obj, stopID: stopID} ), 
		headers: {
            "Content-Type" : "application/json"
        }
	}) 
)
export  const updateContact = (contactID, obj,  stopID) =>(
	fetch("backflow/stops/contact", {
		method: "PUT", 
		body: JSON.stringify( {contactID : contactID, obj: obj, stopID: stopID} ), 
		headers: {
            "Content-Type" : "application/json"
        }
	}) 
)


export const updateServiceType = (serviceID, serviceType) => (
	fetch("backflow/stops/serviceType", {
		method: "PUT", 
		body: JSON.stringify( {serviceID : serviceID, serviceType: serviceType } ), 
		headers: {
            "Content-Type" : "application/json"
        }
	}) 

)

export const requestWaterDistricts = () =>(
	fetch("backflow/stops/waterList", {
		method: "GET", 
		headers: {
            "Content-Type" : "application/json"
        }
	}) 
)


export const requestAddress = (stopID) => (
	fetch("backflow/stops/address?" + new URLSearchParams({
		stopID: stopID
	}), {
		method: "GET", 
		headers: {
            "Content-Type" : "application/json"
        }
	}) 
)
export const requestContact = (stopID) => (
	fetch("backflow/stops/contact?" + new URLSearchParams({
		stopID: stopID
	}), {
		method: "GET", 
		headers: {
            "Content-Type" : "application/json"
        }
	}) 
)

export const requestServicesList= (date) =>(
	fetch("backflow/stops?" + new URLSearchParams({
		date: date
	}), {
		method: "GET", 
		headers: {
            "Content-Type" : "application/json"
        }
	}) 
)


export const requestAssemblies= (stopID) => (
	fetch("backflow/stops/services?" + new URLSearchParams({
		stopID: stopID
	}), {
		method: "GET", 
		headers: {
            "Content-Type" : "application/json"
        }
	}) 
)


export const updateTester = (stopID, testerID) => (
	fetch("backflow/stops/tester", {
		method: "PUT", 
		body: JSON.stringify( {stopID: stopID, testerID: testerID} ), 
		headers: {
            "Content-Type" : "application/json"
        }
	}) 

)


export const requestStop = (stopID) =>(
	fetch("backflow/stops/details?" + new URLSearchParams({
		stopID: stopID
	}), {
		method: "GET", 
		headers: {
            "Content-Type" : "application/json"
        }
	}) 
)
