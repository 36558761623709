import {
  SET_EMPLOYEE_SEARCH
} from "../../actions/employee";

const _nullResults =[]
                
export default (state = _nullResults, { type, results }) => {
  switch (type) {
    case SET_EMPLOYEE_SEARCH:
      return results;
    case "": 
      return _nullResults; 
    default:
      return state;
  }
};