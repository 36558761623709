import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import FloatingLabel from 'react-bootstrap/FloatingLabel';
import TesterSearch from "./TesterSearch"
import TesterList from "./TesterList"
import TesterDetails from "./TesterDetails"
import {useSelector, useDispatch} from "react-redux";
import { useState, useEffect, useRef, useCallback } from "react";
import {
	requestTesterDetails,
	requestGaugesList, updateTester, clearDetails
} from "../../../actions/employee.js"

export default function TestersApp() {
	const testerID = useSelector((state) => state.employee_management_testerID);
	const details = (useSelector((state) => state.employee_management_details))
	const gauges = useSelector((state) => state.employee_management_gauges)
	const [showDetails, setShowDetails] = useState(false)
	const dispatch = useDispatch(); 

	useEffect(() => {
		if (testerID != null && testerID != -1) {
			setShowDetails(false)
			dispatch(requestTesterDetails(testerID)).then((data, err) => {
				setShowDetails(true)
			})
		} else {
			dispatch(clearDetails()).then((data, err) => {

			})
			setShowDetails(false)
		}
	}, [testerID])

	return(
		<Container className="main-container">
			<Row >
				<Col sm md lg ={4}>
					<TesterList />
				</Col>
			</Row>
				<hr/>
			<Row>
				<Col>
					{
						showDetails ?
							<TesterDetails />
							:
							<>Nothing to show</>
					}
					
				</Col>
			</Row>

		</Container>
	)
}