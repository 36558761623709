
import * as server from "../util/waterDeliveryServer.js";
import { RECEIVE_TEST_REPORT } from "./report.js"

export const RECEIVE_LIST  = "RECEIVE_LIST"; 
export const SET_DATE = "SET_DATE"
export const RECEIVE_TOTAL = "RECEIVE_TOTAL"; 
export const RECEIVE_WATER_DISTRICT_LIST = "RECEIVE_WATER_DISTRICT_LIST"
export const SET_WATER_ID = "SET_WATER_ID" 
export const SET_CLEAR_WATER_ID = "SET_CLEAR_WATER_ID"
export const RECEIVE_TEST_REPORT_ID = "RECEIVE_TEST_REPORT_ID"

const receiveList = (list) => ({
	type: RECEIVE_LIST, 
	list
})

const setDate = (date) => ({
	type: SET_DATE, 
	date
})

const receiveTotal = (total) => ({
	type: RECEIVE_TOTAL, 
	total
})

const receiveTestReport = (report) => ({
	type: RECEIVE_TEST_REPORT, 
	report
})

const receiveTestReportID = (id) => ({
	type: RECEIVE_TEST_REPORT_ID, 
	id
})

const receiveWaterDistrictList = (list) => ({
	type: RECEIVE_WATER_DISTRICT_LIST, 
	list
})

const setID = (id) => ({
	type: SET_WATER_ID, 
	id
})
const clearID = () => ({
	type: SET_CLEAR_WATER_ID
})


export const sendReportToSelf = (reportID) => {
	return new Promise ( async(resolve, reject) =>{
		const response = await server.sendReportToSelf(reportID); 
		const data = await response.json(); 
		if(response.ok){
			resolve(); 
		}

	})
}
export const determineReportID = (reportID) => dispatch => {
	return new Promise ( async( resolve, reject) =>{
		dispatch( receiveTestReportID(reportID) ); 
		resolve(); 
	})
}

export const clearclear = () => dispatch =>{
	return new Promise ( async( resolve, reject) =>{
		dispatch( clearID() ); 
		resolve(); 
	})
}


export const cleanDistrictID = () => dispatch =>{
	return new Promise((resolve, reject) =>{
		resolve(dispatch(clearID() )) 
	})
}

export const setDistrictID = (id) => dispatch => {
	return new Promise((resolve, reject) =>{
		resolve(dispatch(setID(id)))
	})
}


export const  sendReportToReviewList = (reportID) => {
	return new Promise( async(resolve, reject) =>{
		const response = await server.sendToReview(reportID)
		const data =  await response.json() ; 
		if(response.ok){
			resolve(true)
		}else{
			resolve(0)
		}
	})
}
export const requestTestReport = (reportID) => dispatch => {
	return new Promise( async(resolve, reject) =>{
		const response = await server.requestTestReport(reportID)
		const data =  await response.json() ; 
		if (response.ok) {
			if ('report' in JSON.parse(data)) {
				resolve(dispatch(receiveTestReport(JSON.parse(data).report)))
			} else {
				resolve(0)
			}
		}else{
			resolve(0)
		}
	})
} 


export const requestWaterDistrictList = () => dispatch =>{
	return new Promise( async(resolve, reject) =>{
		const response = await server.requestWaterDistrictList()
		const data =  await response.json() ; 
		if(response.ok){
			resolve(dispatch( receiveWaterDistrictList(JSON.parse(data).list)) )
		}else{
			resolve(0)
		}
	})
}

export const requestTotalNotYetSentNoDate = (waterID) =>{
	return new Promise( async(resolve, reject) =>{
		const response = await server.requestTotalNotYetSentNoDate(waterID)
		const data =  await response.json() ; 
		if(response.ok){
			resolve(JSON.parse(data).total)
		}else{
			resolve(0)
		}
	})
}

export const  requestNextPopNoDate = (waterID, offset) => dispatch =>{ 
	return new Promise( async(resolve, reject) =>{
		const response = await server.requestPopReportNoDate( waterID, offset); 
		const data = await response.json(); 
		if(response.ok){
			dispatch( receiveTestReportID( JSON.parse(data).report) ); 
			resolve(true); 
		}else{
			resolve(false)
		}
	})
}


export const getTotalInTransit = (waterID, dateSelected) => {
	return new Promise( async(resolve, reject) =>{
		const response = await server.requestTotalInTransit(dateSelected, waterID)
		const data =  await response.json() ; 
		if(response.ok){
			resolve(JSON.parse(data).total)
		}else{
			resolve(0)
		}
	})
}
export const requestTotalNotYetSent = (waterID, dateselected) => {
	return new Promise( async(resolve, reject) =>{
		const response = await server.requestTotalNotYetSent(dateselected, waterID)
		const data =  await response.json() ; 
		if(response.ok){
			resolve(JSON.parse(data).total)
		}else{
			resolve(0)
		}
	})
}

export const requestNextPop = (waterID, dateSelected, offset) => dispatch => {
	return new Promise( async(resolve, reject) =>{
		const response = await server.requestPopReport( waterID, dateSelected, offset); 
		const data = await response.json(); 
		if(response.ok){
			dispatch( receiveTestReport( JSON.parse(data).report) ); 
			resolve(true); 
		}else{
			resolve(false)
		}
	})
}



export const determineDate = (dateSelected) => dispatch => {
	return new Promise( async (resolve, reject) =>{
		dispatch( setDate(dateSelected) ); 
		resolve(); 
	})
}


export const sendEmails = (dateselected, waterID , email, markAsSent) => {
	return new Promise ( async( resolve, reject) =>{
		const response = await server.automatedEmail(dateselected, waterID, email, markAsSent)
		const data =  await response.json() ; 
		if(response.ok){
			resolve()
		}else{
			reject(false)
		}
	})
}
export const sendEmailsNoDate = (waterID , email, markAsSent) => {
	return new Promise ( async( resolve, reject) =>{
		const response = await server.automatedEmailNoDate(waterID, email, markAsSent)
		const data =  await response.json() ; 
		if(response.ok){
			resolve()
		}else{
			reject(false)
		}
	})
}


export const requestSharedLink = (waterID, forDate)  => {
	return new Promise ( async( resolve, reject) =>{
		console.log("Requesting this share link:",  waterID, forDate)
		const response = await server.requestSharedLink(waterID, forDate)
		const data =  await response.json() ; 
		if(response.ok){
			console.log(JSON.parse(data).link )
			resolve(JSON.parse(data).link)
		}else{
			reject(false)
		}
	})

}


export const getPrintable = (waterID, forDate) => dispatch =>{
	return new Promise ( async( resolve, reject) =>{
		console.log("Requesting these reports:",  waterID, forDate)
		const response = await server.requestWaterPDF(waterID, forDate); 
		const data =  await response.json() ; 
		if(response.ok){
			console.log(JSON.parse(data) )
			resolve()
		}else{
			reject(false)
		}
	})

}


export const getList = (dateSelected) => dispatch => {
	return new Promise( async (resolve, reject) =>{
		const response = await server.getWaterList(dateSelected); 
		const data = await response.json(); 
		if(response.ok){
			dispatch( receiveList(JSON.parse(data).list)); 
			resolve(); 
		}else{
			//catch the error...
		}
	})
}

export const getTotalPDFed = (dateSelected, waterID) => {
	return new Promise( async (resolve, reject) =>{
		const response = await server.getTotalPDFed(dateSelected, waterID); 
		const data = await response.json(); 
		if(response.ok){
			 
			resolve(JSON.parse(data).total); 
		}else{
			//catch the error...
		}
	})
}
export const getTotalSent = (dateSelected, waterID) => {
	return new Promise( async (resolve, reject) =>{
		const response = await server.getTotalSent(dateSelected, waterID); 
		const data = await response.json(); 
		if(response.ok){
			
			resolve(JSON.parse(data).total); 
		}else{
			//catch the error...
		}
	})
}

export const getTotal = (dateSelected) => dispatch => {
	return new Promise( async (resolve, reject) =>{
		const response = await server.getTotal(dateSelected); 
		const data = await response.json(); 
		if(response.ok){
			dispatch( receiveTotal(JSON.parse(data).total)); 
			resolve(); 
		}else{
			//catch the error...
		}
	})
}

export const getTotals = (dateSelected, waterID) => {
	return new Promise( async (resolve, reject) =>{
		const response = await server.getTotals(dateSelected, waterID); 
		const data = await response.json(); 
		if(response.ok){
			resolve(JSON.parse(data)); 
		}else{
			//catch the error...
		}
	})

}