import * as server from "../util/server";

export const RECEIVE_MESSAGES = "RECEIVE_MESSAGES"

const getMess = (messagelist) =>({
	type: RECEIVE_MESSAGES, 
	messagelist
})

export const getMessages = ( ) => dispatch => {
	return new Promise( async(resolve, reject) => {
	
		const response = await server.requestMessages( )
		const data = await response.json()

		if(response.ok){
			let myobj = {messageList: JSON.parse(data).list }
			resolve( dispatch( getMess ( myobj) ))
		}

	})

}
