import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Accordion from 'react-bootstrap/Accordion';
import Card from 'react-bootstrap/Card';
import Spinner from 'react-bootstrap/Spinner';
import Form from 'react-bootstrap/Form';
import Table from 'react-bootstrap/Table';
import ListGroup from 'react-bootstrap/ListGroup';
import AssemblyCard from "./AssemblyCard"
import { useState, useEffect, useRef, useCallback} from "react";
import {useSelector, useDispatch} from "react-redux";
import StopCard from "./StopCard"
import { determineID, requestStops } from "../../../../actions/addressApp"

export default function DisplayStops() {
	const stoplist = useSelector((state) => state.address_stoplist )
	const addressID = useSelector((state) => state.addressID)
	const dispatch = useDispatch(); 
	const [loader, setLoader] = useState(false)
	const [hasItems, setHasItems] = useState(false)
	const [noStops, setNoStops] = useState(true)

	useEffect(()=>{
		if(addressID != null){	
			setLoader(true)	
			dispatch( requestStops(addressID) ).then((data, err) =>{	
				setLoader(false)
			})
		}
	}, [addressID])

	useEffect(()=>{
		if(stoplist.length > 0 ){
			setNoStops(false)
		}else{
			setNoStops(true)
		}
	}, [stoplist])

	useEffect(()=>{
		if(addressID != null){	
			setLoader(true)	
			dispatch( requestStops(addressID) ).then((data, err) =>{	
				setLoader(false)
			})
		}
	}, [])

	const renderStops = stoplist.map( (stop, index) =>{
		return (
			<StopCard stop={stop} index={index} />
		)

	})

	return(
		
		<>
			{
				loader ?
					<>
						<Spinner />
					</>
				: 
					<>
						{
							noStops ?
								<> No stops scheduled </>
							: 
								
								<Accordion>
									{renderStops}
								</Accordion>

						}
					</>
							

			}
		</>
		
	)
}