import {
  RECEIVE_INVOICE_QB,  
  CLEAR_INVOICE_QB
} from "../../actions/invoice.js";

const _nullQBInvoice = {
  invoice: null
}; 
                
export default (state = _nullQBInvoice, { type, qbinvoice }) => {
  Object.freeze(state);
  switch (type) {
    case RECEIVE_INVOICE_QB:
      return qbinvoice;
    case CLEAR_INVOICE_QB: 
      return _nullQBInvoice; 
    default:
      return state;
  }
};