

export const requestQueryTotal = (dueDate, month, count, offset) => (
  fetch("/backflow/rounds/queryTotal?" + new URLSearchParams({
    dueDate: dueDate, 
    month: month, 
    count: count, 
    offset: offset
  }), {
      method: "GET",
      headers: {
        "Content-Type": "application/json", 
        "Cache-Control": "no-store, no-cache"
      }
  })
)

export const requestDateTotal = (dueDate) => (
  fetch("/backflow/rounds/dateTotal?" + new URLSearchParams({
    dueDate: dueDate
  }), {
      method: "GET",
      headers: {
        "Content-Type": "application/json", 
        "Cache-Control": "no-store, no-cache"
      }
  })
)


export const requestList = (dueDate, month, count, offset) => (
  fetch("/backflow/rounds?" + new URLSearchParams({
    dueDate: dueDate, 
    month: month, 
    count: count, 
    offset: offset
  }), {
      method: "GET",
      headers: {
        "Content-Type": "application/json", 
        "Cache-Control": "no-store, no-cache"
      }
  })
)

export const requestFollowUpDetails = (followID) => (
  fetch("/backflow/rounds/details?" + new URLSearchParams({
      followID :  followID
    }), {
        method: "GET",
        headers: {
          "Content-Type": "application/json", 
          "Cache-Control": "no-store, no-cache"
        }
    })
)

export const requestAddressDetails = (addressID) => (
  fetch("/backflow/rounds/address?" + new URLSearchParams({
      addressID: addressID
    }), {
        method: "GET",
        headers: {
          "Content-Type": "application/json", 
          "Cache-Control": "no-store, no-cache"
        }
    })
)

export const requestContactDetails = (addressID) => (
  fetch("/backflow/rounds/contact?" + new URLSearchParams({
      addressID: addressID
    }), {
        method: "GET",
        headers: {
          "Content-Type": "application/json", 
          "Cache-Control": "no-store, no-cache"
        }
    })
)
export const requestBillingDetails = (addressID) => (
  fetch("/backflow/rounds/billing?" + new URLSearchParams({
      addressID: addressID
    }), {
        method: "GET",
        headers: {
          "Content-Type": "application/json", 
          "Cache-Control": "no-store, no-cache"
        }
    })
)

export const requestNoteList = (roundID) => (
  fetch("/backflow/rounds/round_list?" + new URLSearchParams({
      roundID: roundID
    }), {
        method: "GET",
        headers: {
          "Content-Type": "application/json", 
          "Cache-Control": "no-store, no-cache"
        }
    })
)

export const addRound = (obj) => (
  fetch("/backflow/rounds/follow" , {
        method: "POST",
        body: JSON.stringify(obj), 
        headers: {
          "Content-Type": "application/json", 
          "Cache-Control": "no-store, no-cache"
        }
    })

)





