import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form';
import { useSelector, useDispatch } from "react-redux";
import { useState, useEffect, useRef, useCallback } from "react";
import { checkSession } from "../../../actions/session.js"
import { login, cleanErrors } from "../../../actions/session.js";
import Alert from 'react-bootstrap/Alert';
import Button from 'react-bootstrap/Button';
import Spinner from 'react-bootstrap/Spinner';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';

export default function LoginAlert() {
    const session = useSelector((state) => state.session)
    const [show, setShow] = useState(false)
    const today = new Date();
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [validated, setValidated] = useState(false);
    const [credentialsStateShow, setCredentialsStateShow] = useState(false);
    const dispatch = useDispatch();
    const loginSuccess = useSelector((state) => state.errors.success);
    const [spinnerShow, setSpinnerShow] = useState(false)
    const errorMessage = useSelector((state) => state.errors.message)

    useEffect(() => {
        if (loginSuccess == null) {
            setCredentialsStateShow(false);
        } else if (loginSuccess == false) {
            setCredentialsStateShow(true);
        } else {
            setCredentialsStateShow(false)
        }

    }, [loginSuccess])

    const handleSubmit = (event) => {
        event.preventDefault();
        setSpinnerShow(true);
        let obj = { username: email, password: password }
        dispatch(login(obj)).then((data, err) => {
            if (data) {
                dispatch(cleanErrors());
                setSpinnerShow(false)
            } else {
                setSpinnerShow(false)
            }


        })

    }


    const check = () => {

        dispatch(checkSession()).then((data, err) => {

        })
    }


    useEffect(() => {
        if (session != null) {
            setShow(false)
        } else {
            setShow(true)
        }
    }, [])



    return (
        <>
          
        < Modal show = { show } centered >
            <Modal.Header>
               Log in 
            </Modal.Header>
                <Modal.Body>
                    <Container>
                    <Form noValidate validated={validated} onSubmit={handleSubmit} className="login-form">

                        <Row>
                            <Alert variant="danger" show={credentialsStateShow} >
                                <p> {errorMessage} </p>
                            </Alert>
                        </Row>
                        <Row>
                            <Form.Group>
                                <Form.Label>Email:</Form.Label>
                                <Form.Control type="email" name="email" value={email} onChange={(e) => setEmail(e.target.value)} required />
                            </Form.Group>
                            <Form.Group>
                                <Form.Label> Password: </Form.Label>
                                <Form.Control type="password" name="password" value={password} onChange={(e) => setPassword(e.target.value)} maxlength="250" required />
                            </Form.Group>
                        </Row>
                        <Row>
                        </Row>
                        <Row>
                            <Col>
                                <Button variant="dark" className="cardFooter" type="submit" > Submit </Button>
                            </Col>
                        </Row>
                        </Form>
                        </Container>
            </Modal.Body>
            <Modal.Footer>
            </Modal.Footer>
            </Modal >
            </>
      )
}