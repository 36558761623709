
export const checkLoggedIn = async preloadedState => {
  const response = await fetch('/backflow/session', {method: 'GET'});
  const { user } = await response.json();
  console.log("updated user: ", user); 
  if (user) {
    preloadedState = {
      session: user
    };
  }else{
    //check if a session is open in localstorage  
    /*console.log("this is the local storage: ", localStorage);
    if(localStorage.length > 0){
      console.log("There's an user logged in!");
      preloadedState = {
        session: JSON.parse(localStorage.session)
      }
      console.log("New preloaded state: ",preloadedState ); 
    }*/
  }
  return preloadedState;
};
