import {
  SET_CALENDAR_LIST, 
  CLEAR_CALENDAR_LIST
} from "../../actions/scheduling";

const _nullList =  []; 
                
export default (state = _nullList, { type, list }) => {
  Object.freeze(state);
  switch (type) {
    case SET_CALENDAR_LIST:
      return list;
    case CLEAR_CALENDAR_LIST: 
      return _nullList
    case "": 
      return _nullList; 
    default:
      return state;
  }
};