import {
  GET_REPORT_LIST
} from "../../actions/testReports.js";

const _nullReportList = [];
                
export default (state = _nullReportList, { type, list }) => {
  Object.freeze(state);
  switch (type) {
    case GET_REPORT_LIST:
      return list;
    case "": 
      return _nullReportList; 
    default:
      return state;
  }
};