import {
    SET_TESTER_DETAILS,
    CLEAR_TESTER_DETAILS
} from "../../actions/employee";

const _nullDetails =  null; 
                
export default (state = _nullDetails, { type, details }) => {
  Object.freeze(state);
  switch (type) {
    case SET_TESTER_DETAILS:
          return details;
      case CLEAR_TESTER_DETAILS:
          return _nullDetails
    case "": 
      return _nullDetails; 
    default:
      return state;
  }
};