import * as server from "../util/alternativeServer";

export const  GET_REPORT_LIST = "GET_REPORT_LIST"; 
export const RECEIVE_TEST_REPORT = "RECEIVE_TEST_REPORT"; 
export const CLEAR_TEST_REPORT = "CLEAR_TEST_REPORT"
export const  RECEIVE_TESTER = "RECEIVE_TESTER"
export const RECEIVE_ASSEMBLY = "RECEIVE_ASSEMBLY"
export const RECEIVE_TEST_DETAILS = "RECEIVE_TEST_DETAILS"

const receiveTestReport = (report) => ({
	type: RECEIVE_TEST_REPORT, 
	report
})

const receiveList = (list) =>({
	type: GET_REPORT_LIST, 
	list
})


const receiveAssembly = (assembly) =>({
	type: RECEIVE_ASSEMBLY, 
	assembly
})
const receiveDetails = (details) =>({
	type: RECEIVE_TEST_DETAILS, 
	details
})
const receiveTester = (tester) =>({
	type: RECEIVE_TESTER, 
	tester
})


export const requestTester = (reportID) => dispatch =>{
	return new Promise ( async (resolve, reject) =>{
		const response = await server.requestTester( reportID); 
		const data = await response.json(); 
		if(response.ok){
			dispatch( receiveTester( JSON.parse(data).tester) ); 
			resolve(); 
		}
		
	})
} 
export const requestDetails = (reportID) => dispatch =>{
	return new Promise ( async (resolve, reject) =>{
		const response = await server.requestReportDetails( reportID); 
		const data = await response.json(); 
		if(response.ok){
			dispatch( receiveDetails( JSON.parse(data).details) ); 
			resolve(); 
		}
		
	})
} 
export const requestAssembly = (assemblyID) => dispatch =>{
	return new Promise ( async (resolve, reject) =>{
		const response = await server.requestReportAssembly( assemblyID ); 
		const data = await response.json(); 
		if(response.ok){
			dispatch( receiveAssembly( JSON.parse(data).assembly) ); 
			resolve(); 
		}
		
	})
} 

export const requestTestReport = (reportID) => async dispatch => {
	return new Promise ( async (resolve, reject) =>{
		const response = await server.requestTestReport( reportID ); 
		const data = await response.json(); 
		if(response.ok){
			dispatch( receiveTestReport( JSON.parse(data).report) ); 
			resolve(); 
		}
		
	})
}

export const requestReportListEmail = () => async dispatch => {
	return new Promise( async (resolve, reject) =>{
		const response = await server.requestReportListEmail(); 
		const data = await response.json(); 
		if(response.ok){
			dispatch( receiveList( JSON.parse(data).list )); 
			resolve(); 
		}
	})
}

export const requestReportList = (districtID) => async dispatch => {
	return new Promise( async (resolve, reject) =>{
		const response = await server.requestReportList( districtID ); 
		const data = await response.json(); 
		if(response.ok){
			dispatch( receiveList( JSON.parse(data).list )); 
			resolve(); 
		}
	})
}

export const setAsSent = (reportID) => async dispatch => {
	return new Promise( async (resolve, reject) =>{
		const response = await server.setAsSent( reportID ); 
		const data = await response.json(); 
		if(response.ok){
			resolve(true); 
		}
	})
}

export const setForReview = (reportID, reason) => {
	return new Promise( async (resolve, reject) =>{
		const response = await server.setForReview( reportID, reason ); 
		const data = await response.json(); 
		if(response.ok){
			resolve(true); 
		}
	})
}
