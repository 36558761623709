import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import Spinner from 'react-bootstrap/Spinner';
import Container from 'react-bootstrap/Container';
import {useSelector, useDispatch} from "react-redux";
import { useState, useEffect, useRef, useCallback} from "react";
import {createAccount} from "../../../actions/account.js"


export default function CreateAccount(props){
	const [accountName, setAccountName] = useState(null); 
	const [fname, setFname] = useState(null); 
	const [lname, setLname] = useState(null); 
	const [phone, setPhone] = useState(null); 
	const [email, setEmail] = useState(null); 
	const [createLoader, setCreateLoader] = useState(false)
	const dispatch = useDispatch(); 

	const create  = (event)=>{
		event.preventDefault(); 
		//draw all new values 
		let obj = {}; 
		obj.accountName = accountName; 
		obj.fname = fname; 
		obj.lname = lname; 
		obj.phone = phone; 
		obj.email = email; 
		console.log("Creating an account for: ", obj)
		//tap on the server
		setCreateLoader(true)
		dispatch( createAccount(obj) ).then((data, err) =>{
			console.log("Received customer", data)
			setCreateLoader(false)
			props.closeModal(); 
		})
	
	}
	return(
		<>
			<Form onSubmit= {create}>
			<Modal.Body>
			
				<Form.Label> Account Name </Form.Label>
				<Form.Control 
					value = {accountName}
					onChange={(event) => setAccountName(event.target.value)}
						maxLength="99"
					required
				/>
				
				{/*<hr/>
				<h6> Contact </h6>
				<Form.Label> First Name </Form.Label>
				<Form.Control
					value = {fname}
					onChange = { (event) => setFname(event.target.value)}
					required
				 />
				<Form.Label> Last Name </Form.Label>
				<Form.Control
					value = {lname}
					onChange = { (event) => setLname(event.target.value)}
					required

				 />
				<Form.Label> Phone Number </Form.Label>
				<Form.Control 
					value = {phone}
					onChange = { (event) => setPhone(event.target.value)}
					required
				/>
				<Form.Label> Email </Form.Label>
				<Form.Control
					value = {email}
					onChange = { (event) => setEmail(event.target.value)}
					required
				 />*/}
				
			
			</Modal.Body>
			<Modal.Footer>

				<Button type="submit" disabled={createLoader}> {
					createLoader ?
						<Spinner />
					: 
						<> Create Account </>
				}
				</Button>
			</Modal.Footer>
			</Form>
		</>
	)
}