import {
  GET_PENDING_LIST
} from "../../actions/testReports";

const _nullTestReportList = []
                
export default (state = _nullTestReportList, { type, reportlist }) => {
  Object.freeze(state);
  switch (type) {
    case GET_PENDING_LIST:
      return reportlist;
    case "": 
      return _nullTestReportList; 
    default:
      return state;
  }
};