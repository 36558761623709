import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col'; 
import Form from 'react-bootstrap/Form'; 
import ListGroup from 'react-bootstrap/ListGroup';
import {useSelector, useDispatch} from "react-redux";
import { useState, useEffect, useRef, useCallback} from "react";
import {
	requestTesterDetails, 
	requestTesterList,
	setTesterID, clearDetails
} from "../../../actions/employee.js"
import TesterItem from "./TesterItem"

export default function TesterList(){
	const dispatch = useDispatch(); 
	const [loader, setLoader] = useState(false)	
	const list = useSelector((state) => state.employee_management_testers)
	const [selectedItem, setSelectedItem] = useState(null); 
	const [tester, setTester] = useState(-1); 
	

	useEffect(() => {
		   if (tester != null) {
			   dispatch(setTesterID(tester)).then((data, err) => {

			   })
		   }
 	 }, [tester])

	useEffect( ()=>{
		setLoader(false)
		dispatch( requestTesterList() ).then((data, err) =>{
			console.log("Testers loaded")
			setLoader(true)
		})
	}, [])

	const renderTesters = list.map((tt)=>{
		return(
			<option value= {tt.ID}> {tt.tester_name}</option>
		)
	})

	return(
		<Container>
			<Row>
				
				<Col>
					<Form.Label>Tester Name</Form.Label>
					<Form.Select value={selectedItem} onChange = {(event)=> setTester(event.target.value) } >
						<option value={-1}>select one</option>
						{renderTesters}
					</Form.Select>
				</Col>
			</Row>
		</Container>
	)

}