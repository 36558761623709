import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Card from 'react-bootstrap/Card';
import Form from 'react-bootstrap/Form';
import Accordion from 'react-bootstrap/Accordion';
import Button from 'react-bootstrap/Button';
import InputGroup from 'react-bootstrap/InputGroup';
import Container from 'react-bootstrap/Container';
import FloatingLabel from 'react-bootstrap/FloatingLabel';
import { useState, useEffect, useRef, useCallback} from "react";
import {useSelector, useDispatch} from "react-redux";
import BillingCard from "./BillingCard"
import {requestBillingDetails} from "../../../../actions/account"
import { newAddress } from "../../../../actions/customerApp.js"
import { determineID, requestStops } from "../../../../actions/addressApp.js"
	

export default function BillingTab(){
	const customer = useSelector( (state) => state.customerdetails ); 
	const [allowEdit, setAllowEdit] = useState(true)
	const dispatch = useDispatch(); 
	const accountID = useSelector((state) => state.customerID )
	const details = useSelector( (state) => state.billingDetails)
	const customerID = useSelector( (state) => state.customerID)

	const [show, setShow] = useState(false)

	function addressPossible(){
		return(
				(details.street != null && details.street != '') &&
				(details.city != null && details.city != '') &&
				(details.state != null && details.state != '') &&
				(details.zipcode != null && details.zipcode != '')
			)
	}

	const createAddress = ()=>{
		return new Promise((resolve, reject) =>{
			if( addressPossible() ){	
				let obj = {}; 
				obj.locationName = details.name; 
				obj.street = details.street;
				obj.city = details.city;
				obj.state = details.state;
				obj.zipcode = details.zipcode; 
				obj.location_type = 'RESIDENTIAL'
				dispatch( newAddress(obj, customerID) ).then((data, err)=>{
					dispatch(determineID(data) ).then((data, err)=>{

					})
				})	
			}else{
				console.log("No, cannot")
			}
		})
	}

	useEffect(() => {
		if (accountID != null) {
			dispatch(requestBillingDetails(accountID)).then((data, err) => {
				console.log("Getting...")
				setShow(true)
			})
		} else {
			setShow(false)
		}
	}, [accountID])

	return (
		<>
			<Accordion.Item eventKey="0">
					{
						show ?
							
								<>
									<Accordion.Header>
										 Billing Details 
									</Accordion.Header>
									<Accordion.Body>
										<BillingCard />
										<Button 
											size= "sm" 
											variant ="secondary"
											onClick = { createAddress } 
										> Create Address From Billing</Button>
									</Accordion.Body>
								</>
							
							:
							<>
							</>
					}
					</Accordion.Item>
		</>			
			
		)
}