import { useState, useEffect, useRef, useCallback} from "react";
import {useSelector, useDispatch} from "react-redux";
import Form from 'react-bootstrap/Form';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Badge from 'react-bootstrap/Badge';
import Col from 'react-bootstrap/Col';
import FloatingLabel from 'react-bootstrap/FloatingLabel';
import {
	updateBillingName, 
	updateBillingContact, 
	updateBillingAddress, 
	updateBillingDetails
} from "../../../actions/account"



export default function BillingInformation(){
	const dispatch = useDispatch()
	const billing= useSelector( (state) =>state.invoice_billing_detail)
	const [name, setName] = useState("")
	const [email, setEmail] = useState(null)
	const [street, setStreet] = useState("")
	const [city, setCity] = useState("")
	const [state, setState] = useState("")
	const [zipcode, setZipcode] = useState("")
	const [salesTax, setSalesTax] = useState("")
	const [po, setPO] = useState("")
	const [isResidential, setIsResidential] = useState(false)
	const [qb_id, setQBID] = useState(null)
	const [hasQB, setHasQB] = useState(false)

	useEffect(()=>{
		setStreet(billing.street)
		setCity(billing.city)
		setState(billing.state)
		setZipcode(billing.zipcode)
		setName(billing.name)
		setSalesTax(billing.sales_tax)
		setPO(billing.PO)
		setEmail(billing.ap_email)
		if(billing.quickbooksID == null){
			setHasQB(false)
		}else{
			setHasQB(true)
		}
		setQBID(billing.quickbooksID)

	}, [])

	function residentialFormat(name){
		console.log("name: ", name)
		const arr = String(name).split(" "); 
		let retStr = arr[arr.length -1] + ", "; 
		for(let i = 0 ; i < arr.length - 1; i++){
			retStr = retStr + `${arr[i]} `; 
		} 
		return retStr.slice(0, -1)
	}

	useEffect(()=>{
		if(isResidential){
			setName(residentialFormat(name) ); 
		}else{
			setName(billing.name)
		}
	}, [isResidential])

	return(
		<Container>
			<Row>
				<Col>
					<h4>
						{
							hasQB ?
								<Badge bg= "success">In  QuickBooks</Badge>
							: 
								<Badge bg = "danger"> No in QuickBooks </Badge>
						}
					<br/>
					<strong> Billing To:  </strong>
					</h4>

				<Container>
				<Row>
					
					<Col sm md lg={8}>
					<FloatingLabel label = "Billing Name">
					<Form.Control 
						value={name}
						size = "sm"
						placeholder = "Name"
						onChange = {(event)=> setName(event.target.value)}
						onBlur = {() =>{
							if( name != billing.name){
								dispatch ( updateBillingName(name, billing.billingID) ).then((data, err) =>{
									console.log("updated billing name")
								})
							}
						}}
									/>
					</FloatingLabel>
					</Col>
					<Col>
						<Container>
						
						<Form.Check 
							id="isResidential"
							type ="checkbox" 
							label="Residential"
							checked = {isResidential}
							onChange = {(event) => setIsResidential(event.target.checked)}
									/>
						</Container>
					</Col>
					</Row>
				</Container>
				</Col>
			</Row>
			<Row>
				<FloatingLabel label ="Street">
				<Form.Control 
					size="sm"
					value= {street}
					onChange = {(event)=> setStreet(event.target.value)}
					onBlur = {() =>{
						if( street != billing.street){
							dispatch ( updateBillingAddress({ street: street, city: city, state: state, zipcode: zipcode}, billing.billingID ) ).then((data, err) =>{
								console.log("updated billing name")
							})
						}
					}}
					/>
				</FloatingLabel>
				<FloatingLabel label="City" >
				<Form.Control 
					value={city}
					placeholder="City"
					size = "sm"
					onChange = {(event)=> setCity(event.target.value)}
					onBlur = {() =>{
						if( city != billing.city){
							dispatch ( updateBillingAddress({ street: street, city: city, state: state, zipcode: zipcode}, billing.billingID ) ).then((data, err) =>{
								console.log("updated billing name")
							})
						}
					}}
					/>
				</FloatingLabel>
				<FloatingLabel label="state" >
				<Form.Control 
					value={state}
					placeholder="State"
					size = "sm"
					onChange = {(event)=> setState(event.target.value)}
					onBlur = {() =>{
						if( city != billing.state){
							dispatch ( updateBillingAddress({ street: street, city: city, state: state, zipcode: zipcode}, billing.billingID ) ).then((data, err) =>{
								console.log("updated billing name")
							})
						}
					}}
					/>
				</FloatingLabel>
				<FloatingLabel label="Zipcode">
				<Form.Control 
					value={zipcode}
					placeholder="Zipcode"
					size = "sm"
					onChange = {(event)=> setZipcode(event.target.value)}
					onBlur = {() =>{
						if( city != billing.zipcode){
							dispatch ( updateBillingAddress({ street: street, city: city, state: state, zipcode: zipcode}, billing.billingID ) ).then((data, err) =>{
								console.log("updated billing name")
							})
						}
					}}
					/>
				</FloatingLabel>
			</Row>
			<Row>
				<hr/>
				<FloatingLabel
					label = "Email"
				>
					<Form.Control 
						id="billingEmail"
						size="sm"
						value= {email}
						onChange = {(event)=> setEmail(event.target.value)}
						onBlur = {() =>{
							if( email != billing.email){
								dispatch ( updateBillingAddress({ email: email, street: street, city: city, state: state, zipcode: zipcode}, billing.billingID ) ).then((data, err) =>{
									console.log("updated billing name")
								})
							}
						}}
					/>
					</FloatingLabel>
			</Row>
			<Row>
				<hr />
				<FloatingLabel label="Purchase Order Number" >
				<Form.Control
					size="sm"
					placeholder="P.O Number"
					value = {po}
					onChange = {(event)=> setPO(event.target.value)}
					onBlur = {() =>{
						if( po != billing.PO){
							dispatch ( updateBillingDetails({ po: po, careof: billing.care_of, sales_tax: salesTax } , billing.billingID ) ).then((data, err) =>{
								console.log("updated billing name")
							})
						}
					}}
					/>
				</FloatingLabel>
	
			</Row>
		</Container>
	)
}