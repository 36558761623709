import Table from 'react-bootstrap/Table';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import ButtonGroup  from 'react-bootstrap/ButtonGroup';
import {useSelector, useDispatch} from "react-redux";
import { useState, useEffect, useRef, useCallback} from "react";

import {requestFailedTestReports, setID } from "../../../../actions/failed" 
export default function FailedList(){
	const mylist = useSelector( (state) => state.failed_list)
	const [total, setTotal] = useState(null)
	const [current, setCurrent] = useState(null)
	const dispatch = useDispatch(); 
	useEffect( () => {
		dispatch( requestFailedTestReports() ).then((data, err) =>{

		})
	}, [])

	useEffect(()=>{
		console.log(mylist.length)
		if(mylist.length > 0 ){
			setTotal(mylist.length)
			setCurrent(0)
		}
		
	}, [mylist])

	useEffect(()=>{
		if(current  < 0){
			setCurrent(mylist.length -1)
		}
		if(current == mylist.length){
			setCurrent(0)
		}

	}, [current])

	useEffect(()=>{
		if(current != null ){
			console.log("Current: " , current)
			if(current >= 0 && current < mylist.length){
				dispatch( setID(mylist[current].id ) ).then((data, err) =>{

				})
			}
		}
	}, [current])

	return(
		<Container>
			<Row>
				<Col sm mfd lg = {5}>
				</Col>
				<Col>
					showing {current + 1} of {total}
				</Col>
				<Col>
					<ButtonGroup>
						<Button size= "sm" onClick = {() => setCurrent(current -1 )}> Prev </Button>
						<Button size= "sm" onClick = {() => setCurrent(current +1 )}> Next </Button>
					</ButtonGroup>
				</Col>
			</Row>
		</Container>
	)
}