import Form from 'react-bootstrap/Form';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Modal from 'react-bootstrap/Modal';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import Table from 'react-bootstrap/Table';
import Card from 'react-bootstrap/Card';
import Spinner from 'react-bootstrap/Spinner';
import { useState, useEffect, useRef, useCallback} from "react";
import {useSelector, useDispatch} from "react-redux";
import BasicInformation from "./BasicInformation"; 
import { Overlay, FormControl } from 'react-bootstrap';
import AccountDetails from "./AccountDetails"
import SearchBar from "./SearchBar"
import { clearID} from "../../../actions/customerApp.js" 
import CreateAccount from "./CreateAccount"
import CustomerModal from "./CustomerModal"

import {  clearCustomerReports, clearServicesList, clearCustomerServicesAddresses } from "../../../actions/customerApp.js"
import { clearAddressID, clearAddressObj, clearStopsList, clearAssemblyList } from "../../../actions/addressApp"

import {
	clearAccount, clearAccountBilling
} from "../../../actions/account"
export default function CustomerApplication() {
	const customerID = useSelector( (state) => state.customerID); 
	const [showCustomer, setShowCustomer] = useState(false);
	const [createShow, setCreateShow] = useState(false); 
	const dispatch = useDispatch();
	const clearme = () => {
		return new Promise(async (resolve, reject) => {
			let p1 = await dispatch(clearAccount());
			let p2 = await dispatch(clearAccountBilling());
			Promise.all([p1, p2]).then((data, err) => {
				console.log("Cleared slices..")
				resolve()
			})
		})
	}

	

	useEffect(() => {
		if (customerID == null) {
			
		}
	}, [customerID])
	

	return(
		<Container>
			
			<Modal show={createShow}>
				<Modal.Header closeButton onHide ={ () => setCreateShow(false)}> 
					
				</Modal.Header>
				<CreateAccount  
					size="sm"
					variant = "dark"
					closeModal ={ 
							()=>{
								setCreateShow(false)
						}
					}
				/> 
				
			</Modal>
			<Row>
	
				<CustomerModal />
						
				

			</Row>
			<Row>
				<Col>
					<SearchBar />
				</Col>
				<Col>
					<Container>
						
						<Button size="sm" variant = "light" onClick = {()=> setCreateShow(true)}> Create Account </Button>
					</Container>
				</Col>
			</Row>	
		</Container>
	)
}