import * as server from "../util/alternativeServer";





export const requestServices = (testDate, addressID) =>{
	return new Promise( async ( resolve, reject) =>{
		try{
			const response = await server.requestAddressServices( testDate, addressID );
			const data = await response.json()
			if(response.ok){
				resolve( JSON.parse(data).list); 
			}else{
				console.log("Something went wrong...")
			}
		}catch(e){
		}	
	})

}

export const requestAddressDetails = (addressID) =>{
	return new Promise( async ( resolve, reject) =>{
		try{
			console.log("requesting the address: ", addressID)
			const response = await server.requestAddress( addressID );
			const data = await response.json()
			if(response.ok){
				resolve( JSON.parse(data).address); 
			}else{
				console.log("Something went wrong...")
			}
		}catch(e){
		}	
	})
}

export const requestTester = (testerID) =>{
	return new Promise( async ( resolve, reject) =>{
		try{
			
			const response = await server.requestTesterDetails( testerID);
			const data = await response.json()
			if(response.ok){
				resolve( JSON.parse(data).tester); 
			}else{
				console.log("Something went wrong...")
			}
		}catch(e){
		}	
	})
}