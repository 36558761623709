

 import {
  SET_SERVICES_REPORT_ADDRESS
} from "../../actions/servicesReport";

const _nullAddress =  null; 
                
export default (state = _nullAddress, { type, address }) => {
  Object.freeze(state);
  switch (type) {
    case   SET_SERVICES_REPORT_ADDRESS:
      return address;
    case "": 
      return _nullAddress; 
    default:
      return state;
  }
};