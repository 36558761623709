import {
  SET_SERVICES
} from "../../actions/newstop";

const _nullServices = []
                
export default (state = _nullServices, { type, list}) => {
  switch (type) {
    case SET_SERVICES:
      return list;
    case "": 
      return _nullServices; 
    default:
      return state;
  }
};