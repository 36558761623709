import {
 SET_BUILDER_BILLING_DETAILS , 
 CLEAR_BUILDER_BILLING_DETAILS
} from "../../actions/invoiceBuilder";

const _nullObj =  null
                
export default (state = _nullObj, { type, details }) => {
  Object.freeze(state);
  switch (type) {
    case  SET_BUILDER_BILLING_DETAILS :
      return details;
    case CLEAR_BUILDER_BILLING_DETAILS: 
      return _nullObj
    case "": 
      return _nullObj; 
    default:
      return state;
  }
};