import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Spinner from 'react-bootstrap/Spinner';
import Form from 'react-bootstrap/Form';
import Container from 'react-bootstrap/Container'
import { useState, useEffect, useRef, useCallback} from "react";
import {useSelector, useDispatch} from "react-redux";
import StopCard from "./StopCard"
import { format } from "date-fns"
import {
	setServicesReportDate, 
	requestServicesReportList
} from "../../../actions/servicesReport.js"


export default function Dispatch(){
	const [date, setDate] = useState( format( new Date(), "yyyy-MM-dd")  )
	const myDate = useSelector( (state) => state.services_report_date )
	const [stopsLoader, setStopsLoader] = useState(false); 
	const list = useSelector((state) => state.services_report_list)
	const [myTesters, setMyTesters] = useState([])
	const dispatch = useDispatch(); 

	useEffect(()=>{
		dispatch( setServicesReportDate( date) ).then((data, err) =>{
			console.log("Set the date!")
		})
	}, [date])

	useEffect(() =>{
		setStopsLoader(false)
		dispatch( requestServicesReportList(myDate) ).then((data, err) =>{
			setStopsLoader(true)
		})

	}, [myDate])

	useEffect(()=>{
		let testers = []; 
		for(let i = 0; i < list.length; i++){
			if(!testers.includes( list[i].testerID ) ){
				console.log("This the tester: ", list[i].testerID)
				testers.push( list[i].testerID )
			}
		}		
		setMyTesters(myTesters)
	}, [list])


	const renderStops = list.map((stop) =>{
		return(
			<Col>
				<StopCard stop = {stop}/>
			</Col>
		)
	})

	return(
		<Container>
			<Row>
				<Col>
					<h5> Services Report</h5>
					<h6> Total: {list.length} </h6>
				</Col>
			</Row>
			<Row>
				<Col sm md lg = {6}>

					<Form.Control 
						type="date" 
						value={date} 
						onChange = {( event ) => setDate(event.target.value)} 
					/>
				</Col>
			</Row>
			<Row sm dm lg={3}>
				{
					stopsLoader ? 
						<>
							{renderStops}
						</>
						
					: 
						<Spinner />
				}
				
				
			</Row>
		</Container>
	)

}