import Pagination from 'react-bootstrap/Pagination'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Alert from 'react-bootstrap/Alert'
import Modal from 'react-bootstrap/Modal'
import Card from 'react-bootstrap/Card'
import Spinner from 'react-bootstrap/Spinner'
import Dropdown from 'react-bootstrap/Dropdown'
import Form from 'react-bootstrap/Form'
import Container from 'react-bootstrap/Container'
import Button from 'react-bootstrap/Button'
import ButtonGroup from 'react-bootstrap/ButtonGroup'
import { useState, useEffect } from 'react'; 
import {useSelector, useDispatch} from "react-redux";
import InvoiceActions from "./InvoiceActions"
import { getInvoiceDetails, 
	requestInvoices, 
	updateInvoices, 
	sendToQuickbooks, 
	requestPaymentLink, 
	clearInvoiceSlice,
	prepaid, 
	requestInvoiceTotal,
	setInvoiceID, 
	popInvoice, 
	setInvoiceAsBroken
	}
	  from "../../../actions/invoice.js";
import { format } from "date-fns"
import NewItem from "./NewItem"
import QuickbooksOptions from "./QuickbooksOptions" 
import EnteredInvoice from "./EnteredInvoice"
import InvoiceDisplay from "./InvoiceDisplay"
import { ErrorBoundary } from "react-error-boundary";


export default function InvoiceList(props){
	const dispatch = useDispatch() ; 
	const invoiceTotal = useSelector( (state) => state.invoiceTotal)
	const [showInvoice, setShowInvoice] = useState(false)
	const [offset, setOffset] = useState(0); 
	const [offsetLoader, setOffsetLoader] = useState(false)

	useEffect(()=>{
		dispatch( requestInvoiceTotal() ).then((data, err)=>{
		
		})

	}, [])

	useEffect(() =>{
		console.log("Invoice Total changed... ")
		setOffsetLoader(true)
		dispatch( requestInvoiceTotal() ).then((data, err)=>{
			if(offset >=0 && offset <= invoiceTotal -1){
				if(invoiceTotal  > 0){
					setShowInvoice(true)
					dispatch( popInvoice(offset) ).then( (data, err) =>{
						setShowInvoice(true); 	
					}) 
				}else{
					setShowInvoice(true)

				}
			}
			setOffsetLoader(false)
		})	
	}, [invoiceTotal, offset])

	useEffect(()=>{
		if(offset < 0 ){
			setOffset(invoiceTotal-1)

		}else if(offset == invoiceTotal){
			setOffset(0)
		}
	}, [offset])
	

	return(
		<Container >
				
				<Row >
					<Col sm md= {5} lg = {10}>
						<InvoiceActions resetCount = {() =>setOffset(0) }/>
					</Col>
					<Col>
						<small>{offset + 1 }  of { invoiceTotal} </small>	
					</Col>							
					<Col sm md lg ={1} >
						{
							offsetLoader ?
								<Spinner />
							: 
							<ButtonGroup>
								<Button size="sm" variant="light" onClick = { () => setOffset(offset - 1) }> {"<"} </Button>
								<Button size="sm" variant="light" onClick = { () => setOffset( offset + 1)}>{">"} </Button>
							</ButtonGroup>
						}
						
							
					</Col>
				</Row>	

				<Row>
					
				{
					invoiceTotal ? 
						<Col >
							{
								showInvoice ?
								
										<InvoiceDisplay />
									
								: 
									
										
									<Spinner/>
										
							}	
						</Col>
					: 
						<Col>
							<Card>
								<Card.Body>
									 No Invoices Pending 
								</Card.Body>
							</Card>
						</Col>

				}
				
			</Row>
		</Container>
	)
}