import {
  TOTALSLIST
} from "../../actions/home";

const _nullList = []
                
export default (state = _nullList, { type, list }) => {
  Object.freeze(state);
  switch (type) {
    case TOTALSLIST:
      return list;
    case "": 
      return _nullList; 
    default:
      return state;
  }
};