import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import AddressForm from "./AddressForm"
import ContactForm from "./ContactForm"
import StopForm from "./StopForm"
import ServicesForm from "./ServicesForm"

export default function NewStop(){
	
	return(
		<>
			<Container>	
				<Row>
					<Col>
						<ServicesForm />
					</Col>
				</Row>
				<Row>
					<hr/>
					<Col >
						<AddressForm />
					</Col>
					<Col>		
						<ContactForm />
					</Col>
				</Row>
				<Row>
					<Col>
						<hr/>
						<StopForm />
					</Col>
				</Row>
			</Container>
		</>
	)
}