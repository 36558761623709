import * as server from "../util/districtManagementServer.js";

export const SET_QUERY = "SET_QUERY"; 
export const CLEAR_QUERY = "CLEAR_QUERY"; 
export const SET_DISTRICT_RESULT_LIST = "SET_DISTRICT_RESULT_LIST"; 
export const CLEAR_DISTRICT_RESULT_LIST = "CLEAR_DISTRICT_RESULT_LIST"; 
export const SET_CURRENT_DISTRICT_ID = "SET_CURRENT_DISTRICT_ID"; 
export const CLEAR_CURRENT_DISTRICT_ID = "CLEAR_CURRENT_DISTRICT_ID"
export const SET_CURRENT_DISTRICT = "SET_CURRENT_DISTRICT"
export const CLEAR_CURRENT_DISTRICT = "CLEAR_CURRENT_DISTRICT"
export const SET_DISTRICT_CONTACT_LIST = "SET_DISTRICT_CONTACT_LIST"
export const CLEAR_DISTRICT_CONTACT_LIST = "CLEAR_DISTRICT_COTACT_LIST"

const receiveDistrictContactList = (list) => ({
	type: SET_DISTRICT_CONTACT_LIST,
	list
})

const clearCurrent = () => ({
	type: CLEAR_CURRENT_DISTRICT
})
const receiveCurrent = (obj) => ({
	type: SET_CURRENT_DISTRICT,
	obj
})

const receiveQuery = (query) =>({
	type: SET_QUERY, 
	query
})

const cleanQuery = () =>({
	type: CLEAR_QUERY
})

const receiveDistrictList = (list) => ({
	type: SET_DISTRICT_RESULT_LIST, 
	list
})

const clearDistrictList = () =>({
	type: CLEAR_DISTRICT_RESULT_LIST
})

const receiveCurrentDistrictID = (id) =>({
	type: SET_CURRENT_DISTRICT_ID, 
	id
})

const clearCurrentDistrictID = () =>({
	type: CLEAR_CURRENT_DISTRICT_ID
})


export const clearResultList = () => dispatch => {
	return new Promise((resolve, reject) => {
		resolve(dispatch(clearDistrictList() ))
	})
}
export const setQuery = (query) => dispatch => {
	return new Promise( (resolve, reject) =>{
		resolve(dispatch(receiveQuery(query) ))
	})
}

export const nullifyQuery = () => dispatch =>{
	return new Promise( (resolve, reject) =>{
		resolve(dispatch(cleanQuery))
	}) 
}
export const setCurrentDistrictID = (id) => dispatch =>{
	return new Promise( (resolve, reject)=>{
		resolve(dispatch(receiveCurrentDistrictID(id) ) )
	})
}
export const nullifyDistrictID = () => dispatch =>{
	return new Promise( (resolve, reject)=>{
		resolve( dispatch(clearCurrentDistrictID() ) )
	})
}



export const lookUpQuery = (query) => dispatch =>{
	return new Promise( async (resolve, reject) =>{	
		const response = await server.requestWaterList(query); 
		const data = await response.json(); 
		if(response.ok){
			dispatch(receiveDistrictList(JSON.parse(data).list) ); 
			resolve(); 
		}
	})
}


export const getDistrictDetails = (districtID) => dispatch => {
	return new Promise(async (resolve, reject) => {
		const response = await server.requestWaterDetails(districtID);
		const data = await response.json();
		if (response.ok) {
			dispatch( receiveCurrent(JSON.parse(data).obj));
			resolve(JSON.parse(data).obj);
		}
	})
}


export const getDistrictContacts = (districtID) => dispatch => {
	return new Promise(async (resolve, reject) => {
		const response = await server.getDistrictContacts(districtID);
		const data = await response.json();
		if (response.ok) {
			dispatch(receiveDistrictContactList(JSON.parse(data).list));
			resolve();
		}
	})
}

export const getContactDetails = (contactID) => {
	return new Promise(async (resolve, reject) => {
		const response = await server.getContactDetails(contactID);
		const data = await response.json();
		if (response.ok) {
			resolve(JSON.parse(data).obj);
		}
	})
}

export const updateDistrictDetails = (obj) => dispatch => {
	return new Promise(async (resolve, reject) => {
		const response = await server.updateDistrictDetails(obj);
		const data = await response.json();
		if (response.ok) {
			dispatch(receiveCurrent(JSON.parse(data).obj));
			resolve(JSON.parse(data).obj);
		}
	})
}

export const updateContactDetails = (obj) =>  {
	return new Promise(async (resolve, reject) => {
		const response = await server.updateContactDetails(obj);
		const data = await response.json();
		if (response.ok) {
			resolve(JSON.parse(data).obj);
		}
	})
}

export const createContact = (obj) => {
	return new Promise(async (resolve, reject) => {
		const response = await server.createContact(obj);
		const data = await response.json();
		if (response.ok) {
			resolve(JSON.parse(data).obj);
		}
	})
}

export const createDistrict = (obj) => dispatch =>  {
	return new Promise(async (resolve, reject) => {
		const response = await server.createDistrict (obj);
		const data = await response.json();
		if (response.ok) {
			dispatch(receiveCurrentDistrictID(JSON.parse(data).insertID) )
			resolve();
		}
	})
}



