export const sendReportToSelf = (reportID) => (
	fetch("backflow/waterDelivery/emailToSelf", {
		method: "PUT", 
		body: JSON.stringify( {reportID: reportID }), 
		headers: {
            "Content-Type" : "application/json"
        }
	}) 
)

export const sendToReview = (reportID) => (
	fetch("backflow/waterDelivery/report/review?" + new URLSearchParams({
			reportID : reportID
		}), {
		method: "GET", 
		headers: {
            "Content-Type" : "application/json"
        }
    })

)

export const requestTestReport = (reportID) => (
	fetch("backflow/waterDelivery/report?" + new URLSearchParams({
			reportID : reportID
		}), {
		method: "GET", 
		headers: {
            "Content-Type" : "application/json"
        }
    })

)

export const requestWaterDistrictList = () => (
	fetch("backflow/waterDelivery/waterDistrictList" , {
		method: "GET", 
		headers: {
            "Content-Type" : "application/json"
        }
    })

)

export const requestPopReport = ( waterID, dateselected, offset) => (
	fetch("backflow/waterDelivery/popNext?" + new URLSearchParams({
			dateSelected : dateselected, 
			waterID: waterID, 
			offset: offset
	
		}), {
		method: "GET", 
		headers: {
            "Content-Type" : "application/json"
        }
    })

)

export const requestPopReportNoDate = ( waterID,offset) => (
	fetch("backflow/waterDelivery/popNextNoDate?" + new URLSearchParams({
			waterID: waterID, 
			offset: offset
		}), {
		method: "GET", 
		headers: {
            "Content-Type" : "application/json"
        }
    })

)
export const requestTotalNotYetSent = (dateSelected, waterID) =>(
	fetch("backflow/waterDelivery/totalNotSent?" + new URLSearchParams({
			dateSelected : dateSelected, 
			waterID: waterID, 
	
		}), {
		method: "GET", 
		headers: {
            "Content-Type" : "application/json"
        }
    })
)
export const requestTotalNotYetSentNoDate = ( waterID) =>(
	fetch("backflow/waterDelivery/totalNotSentNoDate?" + new URLSearchParams({
			waterID: waterID, 
		}), {
		method: "GET", 
		headers: {
            "Content-Type" : "application/json"
        }
    })
)


export const requestTotalInTransit = (dateSelected, waterID) =>(
	fetch("backflow/waterDelivery/inTransit?" + new URLSearchParams({
			dateSelected : dateSelected, 
			waterID: waterID, 
	
		}), {
		method: "GET", 
		headers: {
            "Content-Type" : "application/json"
        }
    })
)
export const getTotalPDFed = (dateSelected, waterID)=>(
	fetch("backflow/waterDelivery/getTotalPDFed", {
		method: "POST", 
		body: JSON.stringify({
			dateSelected : dateSelected, 
			waterID: waterID, 
	
		}), 
		headers: {
            "Content-Type" : "application/json"
        }
    })
)

export const getTotalSent = (dateSelected, waterID) =>(
	fetch("backflow/waterDelivery/getTotalSent", {
		method: "POST", 
		body: JSON.stringify({
			dateSelected : dateSelected, 
			waterID: waterID, 
	
		}), 
		headers: {
            "Content-Type" : "application/json"
        }
    })

)

export const automatedEmail = (dateSelected, waterID , email, markAsSent) =>(
	fetch("backflow/waterDelivery/sendAutomatedEmail", {
		method: "POST", 
		body: JSON.stringify({
			dateSelected : dateSelected, 
			email: email, 
			markAsSent: markAsSent, 
			waterID: waterID
		}), 
		headers: {
            "Content-Type" : "application/json"
        }
    })
)

export const automatedEmailNoDate = (waterID , email, markAsSent) =>(
	fetch("backflow/waterDelivery/sendAutomatedEmailNoDate", {
		method: "POST", 
		body: JSON.stringify({
			email: email, 
			markAsSent: markAsSent, 
			waterID: waterID
		}), 
		headers: {
            "Content-Type" : "application/json"
        }
    })
)




export const requestWaterPDF = (waterID, forDate) =>(
	fetch("backflow/waterDelivery/pdf?" + new URLSearchParams({
		waterID: waterID, 
		dateSelected: forDate
	}) , {
		method: "GET", 
		headers: {
            "Content-Type" : "application/json",
            "Accept" : "application/octet-stream" 
        }
    })
)
export const requestSharedLink = (waterID, forDate) =>(
	fetch("backflow/waterDelivery/sharepointLink?" + new URLSearchParams({
		waterID: waterID, 
		dateSelected: forDate
	}) , {
		method: "GET", 
		headers: {
            "Content-Type" : "application/json"
        }
    })
)




export const getWaterList = (dateSelected) =>(
	fetch("backflow/waterDelivery?" + new URLSearchParams({
		dateSelected: dateSelected
	}) , {
		method: "GET", 
		headers: {
            "Content-Type" : "application/json"
        }
    })
)
export const getTotal = (dateSelected) =>(
	fetch("backflow/waterDelivery/getTotal?" + new URLSearchParams({
		dateSelected: dateSelected
	}) , {
		method: "GET", 
		headers: {
            "Content-Type" : "application/json"
        }
    })
)


export const getTotals= (dateSelected, waterID) =>(
	fetch("backflow/waterDelivery/getTotals?" + new URLSearchParams({
		dateSelected: dateSelected, 
		waterID: waterID
	}) , {
		method: "GET", 
		headers: {
            "Content-Type" : "application/json"
        }
    })
)