import Button from 'react-bootstrap/Button';
import InputGroup from 'react-bootstrap/InputGroup';
import ButtonGroup from 'react-bootstrap/ButtonGroup';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form';
import Accordion from 'react-bootstrap/Accordion';
import Card from 'react-bootstrap/Card';
import Container from 'react-bootstrap/Container';
import Spinner from 'react-bootstrap/Spinner';
import { useState, useEffect, useRef, useCallback} from "react";
import {useSelector, useDispatch} from "react-redux";
import ServicesTable from "./ServicesTable"
import { requestServices } from "../../../../actions/customerApp.js"
import SchedulingApp from "../addressesTab/schedule/SchedulingApp"
import AccordionItem from "./AccordionItem"
import AccordionBody from "./AccordionBody"
import { serviceAddresses} from "../../../../actions/customerApp.js"
import AddressForm from "./AddressForm"
import AssemblyDisplay from "./AssemblyDisplay"
import AssemblyTable from "./AssemblyTable"
import AddressContact from "./AddressContact"
import Logger from "./Logger"
import { determineID, requestStops } from "../../../../actions/addressApp.js"
import NewAddressForm from "./NewAddressForm"


export default function ServicesTab(){
	const [loader, setLoader] = useState(true)
	const [assembliesLoader, setAssembliesLoader] = useState(false)
	const dispatch = useDispatch();
	const customerID = useSelector((state) => state.customerID); 
	const servicelist = useSelector( (state) => state.schedule_serviceslist);
	const addresslist = useSelector( (state) => state.customerServiceAddresses)
	const addressID = useSelector( (state) => state.addressID) 
	const currentAddress = useSelector((state) => state.address_details)
	const [scheduling, setScheduling] = useState(false) 
	const [addressSelected, setAddressSelected] = useState('')
	const [showModal, setShowModal] = useState(false)

	useEffect(()=>{
		dispatch(serviceAddresses(customerID)).then((data, err) =>{
			
		})
	}, [addressID])

	useEffect(()=>{
		if(addressID == null){
				if(addresslist.length > 0  ){
					dispatch(determineID(addresslist[0].id) )
				}
			}
	} ,[addresslist])

	const accordionItems =  addresslist.map( (address, index) =>{
		return(
			<option value = {address.id}>
				{address.location_name} at {address.street}, {address.city}, {address.state}, {address.zipcode}
			</option>
		)
	})


	return(
		<Container className = "mainBodySection">

			{
					assembliesLoader ?
						<>
							<hr/>
						
						</>
					:
			<Row>	
				<hr/>
				<Col>

					<AssemblyDisplay />
				</Col>
				
			</Row>
		}
		
			
		</Container>
	)
}