import {
  SET_ROUNDS_NOTES_LIST, CLEAR_ROUNDS_NOTES_LIST
} from "../../actions/rounds.js";

const _nullList = [1,2,3,4,5];
                
export default (state = _nullList, { type, list }) => {
  Object.freeze(state);
  switch (type) {
    case SET_ROUNDS_NOTES_LIST:
      return list;
    case CLEAR_ROUNDS_NOTES_LIST: 
      return _nullList; 
    case "": 
      return _nullList; 
    default:
      return state;
  }
};