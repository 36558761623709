import { useState, useEffect } from 'react'; 
import {useSelector, useDispatch} from "react-redux";
import {getInvoiceDetails, removeItem, requestPaymentLink, requestInvoicePDF } from "../../../actions/invoice.js"
import Button from 'react-bootstrap/Button'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Spinner from 'react-bootstrap/Spinner'
import Modal from 'react-bootstrap/Modal'
import ButtonGroup from 'react-bootstrap/ButtonGroup'
import Form from 'react-bootstrap/Form';
import Badge from 'react-bootstrap/Badge';


export default function EnteredInvoice(){
	const invoice = useSelector((state) => state.invoice)
	const quickbooks_invoice = useSelector((state) =>state.qbInvoice)
	const dispatch = useDispatch(); 
	const [loader, setLoader] = useState(false)
	const [hasLink, setHasLink] = useState(false); 
	const [paymentLink, setPaymentLink] = useState("")
	const [linkLoader, setLinkLoader] = useState(true)
	const [hasPaymentLink, setHasPaymentLink] = useState(false)
	const [isCopied, setIsCopied] = useState(false); 
	const [hasInvoice, setHasInvoice] = useState(false); 

	function requestInvoiceDownload(){
		setLoader(true)
		requestInvoicePDF(invoice.qb_id).then( (data,  err)=>{
			console.log("Done getting the pdf", data); 
			const a = document.createElement('a');
			a.href = window.URL.createObjectURL(data);
			
			a.style.position = 'fixed';
			a.target = '_blank';
			document.body.appendChild(a);
			a.click();
			document.body.removeChild(a);
			setLoader(false)
		} )
	}

	useEffect(()=>{
		if('InvoiceLink' in quickbooks_invoice){
				setPaymentLink(quickbooks_invoice.InvoiceLink)
				setHasPaymentLink(true)
			}else{
				setPaymentLink("")
				setHasPaymentLink(false)
			}
	}, [quickbooks_invoice])

	useEffect(()=>{

		if(quickbooks_invoice != null){
			dispatch( requestPaymentLink(invoice.qb_id)).then((data, err)  =>{
				
				setLinkLoader(false)
			})
		}else{
			setLinkLoader(false)
		}
	}, [])

	useEffect(()=>{
		if(invoice == null){
			setHasInvoice(false)
		}else{
			setHasInvoice(true)
		}
	}, [invoice])

	return(
		<>
			
					<>
						<Row>
							<Col>
								<p><small><strong>Invoice #  </strong><u>{ invoice.qb_docNum}</u> </small></p>
							</Col>
					
							<Col>
								<ButtonGroup>
									<Button size ="sm"onClick = { ()=> requestInvoiceDownload() }>  {loader ? 'Loading…' : 'Download Invoice'} </Button>
									
								</ButtonGroup>
							</Col>
						</Row>
					</>
					
		</>
	)
}