import Breadcrumb from 'react-bootstrap/Breadcrumb';
import ListGroup from 'react-bootstrap/ListGroup';
import Form from 'react-bootstrap/Form';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Spinner from 'react-bootstrap/Spinner';
import Modal from 'react-bootstrap/Modal';
import { useSelector, useDispatch } from "react-redux";
import { useState, useEffect, useRef, useCallback } from "react";
import ContactModule from "./ContactModule"
import {
    createContact,
    getDistrictContacts
} from "../../../actions/districtManagement.js"


export default function NewContact(props) {
    const [tag, setTag] = useState(null);
    const [name, setName] = useState(null);
    const [email, setEmail] = useState(null);
    const [phone, setPhone] = useState(null);
    const [loader, setLoader] = useState(false)
    const currentID = useSelector((state) => state.district_id)
    const dispatch = useDispatch()

    const update = () => {
        let obj = {};
        obj.type = tag;
        obj.name = name;
        obj.email = email;
        obj.phone = phone;
        obj.districtID = currentID;
        setLoader(true)
        createContact(obj).then((data, err) => {
            dispatch(getDistrictContacts(currentID) ).then((data, err) => {
                props.closeModal()
                setLoader(false)
            })
            
        })
       
    }

    return (
        <>
        <Modal.Body>
            <Col>
                <Form>
                    <Form.Label> Tag </Form.Label>
                    <Form.Control
                        value={tag}
                        onChange={
                            (event) => setTag(event.target.value)
                        }
                    />
                    <Form.Label> Name</Form.Label>
                    <Form.Control
                        value={name}
                        onChange={
                            (event) => setName(event.target.value)
                        }
                    />
                    <Form.Label> Email </Form.Label>
                    <Form.Control
                        value={email}
                        onChange={
                            (event) => setEmail(event.target.value)
                        }
                    />
                    <Form.Label> Phone </Form.Label>
                    <Form.Control
                        value={phone}
                        onChange={
                            (event) => setPhone(event.target.value)
                        }

                    />
                </Form>
            </Col>
        </Modal.Body>
        <Modal.Footer>
                <Button onClick={update} disabled={loader } >
                    {
                        loader ?
                            <Spinner />
                            :
                            <> Create </>

                    }</Button>
        </Modal.Footer>
        </>
    )
}