import { useState, useEffect, useRef, useCallback} from "react";
import {useSelector, useDispatch} from "react-redux";
import Form from 'react-bootstrap/Form';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import FloatingLabel from 'react-bootstrap/FloatingLabel';
import Container from 'react-bootstrap/Container';
import Spinner from 'react-bootstrap/Spinner';
import { format, add} from "date-fns"
import {
	updateServiceAddress, 
	queryTerms, 
	getTermID
} from "../../../actions/invoice"

import {requestLineItemDetails, requestServiceAddress, requestServiceDetails, updateDueDate, updateInvoicePurchaseOrder } from "../../../actions/invoiceBuilder.js"


export default function ServiceDetails(){
	const invoiceID = useSelector((state) => state.builder_id)
	const details = useSelector( (state) => state.builder_service_details)

	const address= useSelector( (state) =>state.invoice_service_address)
	const termList = useSelector((state) => state.invoice_term_list)
	const [termID, setTermID] = useState(null)
	const [termIndex, setTermIndex] = useState(-1)
	const [dueDate, setDueDate] = useState(format( new Date(), "MM/dd/yyyy") ) 
	const [PO, setPO] = useState(null)
	const [termsLoaded, setTermsLoaded] = useState(false)
	const [dueDateDays, setDueDateDays] = useState(15)
	const dispatch = useDispatch();
	const [loader, setLoader] = useState(false)

	

	useEffect(()=>{
		if(invoiceID != null){
			//request billing details and change loader status
			setLoader(true)
			dispatch( requestServiceDetails(invoiceID) ).then((data, err) =>{
				setLoader(false)
			})
		}
	}, [invoiceID])


	useEffect(()=>{
		if(details != null){
			let arr = details.date.split("T");
			let realDate = `${arr[0]}T00:00:00-08:00`
			setDueDate(format(new Date(realDate), "MM-dd-yy"))
			setPO( details.purchase_order)
		}
	} , [details])


	useEffect(()=>{
		setTermsLoaded(false)
		/*dispatch( queryTerms() ).then((data, err) =>{
			console.log("Got the terms!")
			
			setTermsLoaded(true)
			setTermIndex(0)
		})*/
	}, [])



	useEffect( ()=>{
		let myAddedDate = add( new Date(dueDate), {
			year: 0, 
			months:0, 
			weeks: 0, 
			days: dueDateDays, 
			hours: 0, 
			minutes: 0, 
			seconds: 0 
		})
		setDueDate(format(myAddedDate, "MM/dd/yyyy"))

	}, [dueDateDays])

	const updatePO = () => {
		if (PO != null) {
			updateInvoicePurchaseOrder(PO, invoiceID).then((data, err) => {
				setLoader(true)
				dispatch(requestServiceDetails(invoiceID)).then((data, err) => {
					setLoader(false)
				})
			})
		}
	}

	const renderTerms = termList.map( (term, index) => {
		return(
			<option value= {index}>
				{term.Name}
			</option>
		)
	})

	return(
		<Container>
			{

				loader ?
					<Spinner />
				: 
					<>
						<Row>
							<Col>
								<h5> Invoice Details </h5>
							</Col>
						</Row>
						<Row>
								<Col>
									<Form.Label> Service Date  </Form.Label>
									 <br/>
									{ 
										dueDate ? 
											<> 
												{ format( new Date( dueDate) , "MM/dd/yyyy")  }
											</>
										: 
											<> </>

										}
								</Col>
								<Col>
									<Form.Label>Due Date  </Form.Label> 
										<br/>
										<> {dueDate} </>
										
								</Col>
							
								<Col>

									<FloatingLabel label = "PO #">
										<Form.Control 
										value={PO}
										onChange={(event) => setPO(event.target.value)}
										onBlur={ updatePO }
										/>
									</FloatingLabel>
								</Col>
							
						
						</Row>	
					</>
			}


			
		</Container>
	)
}