import dispatch from "../reducers/dispatch/dispatch";
import * as server from "../util/builderServer";

export const SET_INVOICE_ID_BUILDER  = "SET_INVOICE_ID_BUILDER"; 
export const CLEAR_INVOICE_ID_BUILDER = "CLEAR_INVOICE_ID_BUILDER"
export const SET_BUILDER_SERVICE_ADDRESS = "SET_BUILDER_SERVICE_ADDRESS"; 
export const CLEAR_BUILDER_SERVICE_ADDRESS = "CLEAR_BUILDER_SERVICE_ADDRESS"
export const SET_BUILDER_BILLING_DETAILS = "SET_BUILDER_BILLING_DETAILS"; 
export const CLEAR_BUILDER_BILLING_DETAILS = "CLEAR_BUILDER_BILLING_DETAILS"; 
export const SET_BUILDER_SERVICE_DETAILS = "SET_BUILDER_SERVICE_DETAILS"; 
export const CLEAR_BUILDER_SERVICE_DETAILS = "CLEAR_BUILDER_SERVICE_DETAILS"; 
export const SET_LINE_ITEMS_LIST_BUILDER = "SET_LINE_ITEMS_LIST_BUILDER"
export const CLEAR_LINE_ITEMS_LIST_BUILDER = "CLEAR_LINE_ITEMS_LIST_BUILDER"
export const SET_BUILDER_TOTAL = "SET_BUILDER_TOTAL"
export const CLEAR_BUILDER_TOTAL = "CLEAR_BUILDER_TOTAL"


const receiveBuilderTotal = (total) => ({
	type: SET_BUILDER_TOTAL,
	total
})
const clearTotal = () => ({
	type: CLEAR_BUILDER_TOTAL
})

const receiveID = (id) => ({
	type: SET_INVOICE_ID_BUILDER, 
	id
})

const clearID = () => ({
	type: CLEAR_INVOICE_ID_BUILDER
})

const receiveServiceAddress = (address) => ({
	type: SET_BUILDER_SERVICE_ADDRESS, 
	address
})

const clearServiceAddress = () => ({
	type: CLEAR_BUILDER_SERVICE_ADDRESS
})


const receiveBillingDetails = (details) =>({
	type: SET_BUILDER_BILLING_DETAILS, 
	details
})
const clearBillingDetails = () => ({
	type: CLEAR_BUILDER_BILLING_DETAILS
})

const receiveServiceDetails = (details) => ({
	type: SET_BUILDER_SERVICE_DETAILS, 
	details
})
const clearServiceDetails = () => ({
	type: CLEAR_BUILDER_SERVICE_DETAILS
})


const receiveLineItems = (list) => ({
	type: SET_LINE_ITEMS_LIST_BUILDER, 
	list
})

const clearLineItems = () => ({
	type: CLEAR_LINE_ITEMS_LIST_BUILDER
})


export const determineTotal = (total) => dispatch => {
	return new Promise((resolve, reject) => {
		dispatch(total)
		resolve()
	})
}


export const updateInvoicePurchaseOrder = (newPO, invoiceID) => {
	return new Promise( async (resolve, reject) => {
		const response = await server.updatePurchaseOrder( newPO, invoiceID);
		const data = await response.json();
		if (response.ok) {
				resolve() 
		}
	})
}

export const determineInvoiceID = (id) => dispatch => {
	return new Promise ( (resolve, reject) => {
	
		dispatch(receiveID( id) )
		resolve(); 
	
	})
}


export const clearInvoiceID = () => dispatch => {
	return new Promise ( (resolve, reject) => {
		dispatch(clearID() )
		resolve(); 
	})
}


export const requestServiceAddress = (invoiceID) => dispatch => {
	return new Promise(async  (resolve, reject) =>{
		const response = await server.serviceAddress(invoiceID); 
		const data = await response.json(); 
		if(response.ok){
			dispatch(receiveServiceAddress( JSON.parse(data).obj))
			resolve(); 
		}
	})	
}
export const requestBillingDetails = (invoiceID)=> dispatch => {
	return new Promise( async(resolve, reject) =>{
		const response = await server.billingDetails(invoiceID); 
		const data = await response.json(); 
		if(response.ok){
			dispatch(receiveBillingDetails(JSON.parse(data).obj))
			resolve(); 
		}
	})
}
export const requestServiceDetails = (invoiceID)=> dispatch =>{
	return new Promise( async(resolve, reject) =>{
		const response = await server.serviceDetails(invoiceID); 
		const data = await response.json(); 
		if(response.ok){
			dispatch(receiveServiceDetails(JSON.parse(data).obj))
			resolve(); 
		}
	})
}

export const requestLineItems = (invoiceID)=> dispatch => {
	return new Promise( async(resolve, reject) =>{
		const response = await server.lineItems(invoiceID); 
		const data = await response.json(); 
		if(response.ok){
			dispatch(receiveLineItems(JSON.parse(data).obj))
			resolve(); 
		}
	})
}


export const requestLineItemDetails = (itemID) => {
	return new Promise( async(resolve, reject) =>{
		const response = await server.lineItemDetails(itemID); 
		const data = await response.json(); 
		if(response.ok){
			resolve(JSON.parse(data).obj); 
		}
	})
}

export const removeItem= (itemID) => {
	return new Promise( async(resolve, reject) =>{
		const response = await server.removeItem(itemID); 
		const data = await response.json(); 
		if(response.ok){
			resolve(); 
		}
	})
}

export const addItem= (invoiceID) => {
	return new Promise( async(resolve, reject) =>{
		const response = await server.addItem(invoiceID); 
		const data = await response.json(); 
		if(response.ok){
			resolve(JSON.parse(data).obj); 
		}
	})
}


export const updateServiceAddress = (addressID, obj, invoiceID) => dispatch => {
	return new Promise( async(resolve, reject) =>{
		const response = await server.updateServiceAddress(addressID, obj, invoiceID); 
		const data = await response.json(); 
		if(response.ok){
			resolve(dispatch( receiveServiceAddress( JSON.parse(data).obj)))
		}
	})
}

export const updateBillignDetails =  (billingID, obj, invoiceID) => dispatch => {
	return new Promise( async(resolve, reject) =>{
		const response = await server.updateBillingDetails(billingID, obj, invoiceID); 
		const data = await response.json(); 
		if(response.ok){
			resolve(dispatch( receiveBillingDetails( JSON.parse(data).obj)))
		}
	})


}



export const updateDueDate =  (newDate, invoiceID) => dispatch => {
	return new Promise( async(resolve, reject) =>{
		const response = await server.updateDueDate(newDate, invoiceID); 
		const data = await response.json(); 
		if(response.ok){
			resolve(dispatch( receiveServiceDetails( JSON.parse(data).obj)))
		}
	})
}

export const updateLineItem =  (itemID, obj) => dispatch => {
	return new Promise( async(resolve, reject) =>{
		const response = await server.updateLineItem(itemID, obj); 
		const data = await response.json(); 
		if(response.ok){
			resolve(JSON.parse(data).obj)
		}
	})
}





