import {
  SET_INVOICE_ID
  , CLEAR_INVOICE_ID
} from "../../actions/invoice";

const _nullID =  null
                
export default (state = _nullID, { type, ID }) => {
  Object.freeze(state);
  switch (type) {
    case SET_INVOICE_ID:
      return ID;
    case CLEAR_INVOICE_ID: 
      return _nullID
    case "": 
      return _nullID; 
    default:
      return state;
  }
};