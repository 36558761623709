import {
  RECEIVE_RESULTS
} from "../../actions/SearchEngine";

const _nullResults = { 
  resultsList: [], 
  addresses: []
}
                
export default (state = _nullResults, { type, resultlist }) => {
  switch (type) {
    case RECEIVE_RESULTS:
      return resultlist;
    case "": 
      return _nullResults; 
    default:
      return state;
  }
};