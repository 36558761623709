import {
  SET_INVOICE_ID_BUILDER, 
  CLEAR_INVOICE_ID_BUILDER
} from "../../actions/invoiceBuilder";

const _nullID =  13867
                
export default (state = _nullID, { type, id }) => {
  Object.freeze(state);
  switch (type) {
    case SET_INVOICE_ID_BUILDER:
      return id;
    case CLEAR_INVOICE_ID_BUILDER: 
      return _nullID
    case "": 
      return _nullID; 
    default:
      return state;
  }
};