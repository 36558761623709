import Table from 'react-bootstrap/Table';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import {useSelector, useDispatch} from "react-redux";
import { useState, useEffect, useRef, useCallback} from "react";

import EditTestReport from "./EditTestReport"




export default function AMGApp(){
	const mylist = [1,2,3,4,5]

	return(
		<Container className="main-container-scrollable">
			<Row>	
				<Col>
					<h3> Reports with odd results </h3>

				</Col>
			</Row>
			<Row>
				<Col>
					<Form.Control placeholder = "Look at by test report ID"
					size="sm" />
				</Col>
				<Col>
				</Col>
			</Row>
			<Row>	
				<Col>
					<hr/>
					<EditTestReport />
				</Col>
			</Row>

		</Container>
	)
}