import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Spinner from 'react-bootstrap/Spinner';
import Form from 'react-bootstrap/Form';
import FloatingLabel from 'react-bootstrap/FloatingLabel';
import { useState, useEffect, useRef, useCallback} from "react";
import {useSelector, useDispatch} from "react-redux";
import {requestTester} from "../../../actions/report.js"
import {format} from "date-fns"

export default function TesterGauge(){
	const obj = useSelector( (state) => state.report_tester)
	const reportID = useSelector( (state) =>state.reportID)
	const dispatch = useDispatch(); 
	const [loader, setLoader] = useState(false)

	useEffect(()=>{
		//request assembly
		if(reportID != null){
			setLoader(false)
			dispatch( requestTester(reportID) ).then((data, err)=>{
				console.log("Requested assembly!")
				setLoader(true)
			})	
		}
			
	}, [reportID])
	return(
		<Container>
			<Row>
				
					{
						loader ?
							<>	
								<Col>
									 <h5>Tester and Gauge</h5>
									<p>	<strong>Tester Name: </strong> <u>{obj.tester_name}</u><br/>				
										<strong> WA Certification: </strong> <u>{obj.WA_cert}</u> <br/>
	 									<strong> OR Cert: </strong> <u>{obj.OR_cert} </u><br/>
										<strong> Gauge Serial: </strong> <u>{obj.gaugesSerial} </u><br/>
										<strong> Calibration Date: </strong> <u>{format( new Date( obj.calibration_date), "MM/dd/yyyy") } </u> <br/>
										<strong> Model: </strong> <u> {obj.gaugeModel} </u>
										
									</p>
								</Col>
							</>
						: 
							<>
								<Spinner />	
							</>

					}
			
			</Row>
		</Container>
	)
}