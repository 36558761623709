import {
  SET_DATE
} from "../../actions/waterDelivery";

import {format} from "date-fns"

const _nullDate = new Date()
                
export default (state = _nullDate, { type, date}) => {
  Object.freeze(state);
  switch (type) {
    case SET_DATE:
      return date;
    case "": 
      return _nullDate; 
    default:
      return state;
  }
};