import {
  SET_ROUNDS_DATE, CLEAR_ROUNDS_DATE
} from "../../actions/rounds.js";
import {format } from "date-fns"

const _nullDate=  format( new Date(), "yyyy-MM-dd"); 
                
export default (state = _nullDate, { type, date }) => {
  Object.freeze(state);
  switch (type) {
    case SET_ROUNDS_DATE:
      return date;
    case CLEAR_ROUNDS_DATE: 
      return _nullDate; 
    case "": 
      return _nullDate; 
    default:
      return state;
  }
};