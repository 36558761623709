
import 'bootstrap/dist/css/bootstrap.min.css';
import Dispatch from "./Pages/Dispatch"; 
import Login from "./Pages/Login"
import Connect from "./Pages/Connect"; 
import UserAgreement from "./Pages/UserAgreement"; 
import PrivacyPolicy from "./Pages/PrivacyPolicy"; 
import { useState, useEffect, useRef, useCallback} from "react";
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import Container from 'react-bootstrap/Container';
import 'animate.css';

import ProtectedRoutes from "./util/ProtectedRoutes"; 
import AuthRoutes from "./util/AuthRoutes"; 
import "./App.css"; 
import "./amazon.css"

function App() {
  return (
  
    <BrowserRouter>
      <Routes>
      	<Route element={<ProtectedRoutes />}>
      		<Route path="/dispatch" element={ <Dispatch />} />
      		<Route path="/" element={ <Dispatch />} />
          <Route path= "/connect" element = { <Connect />} />
      	</Route>
      	<Route element={<AuthRoutes />}>
      		   <Route path="/login" element={ <Login />} />

      	</Route>
    
       <Route path="/userAgreement" element={ <UserAgreement />} />
       <Route path="/privacyPolicy" element={ <PrivacyPolicy />} />
        
      </Routes>
    </BrowserRouter>
  
  );
}

export default App;
