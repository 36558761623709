import * as server from "../util/servicesReportServer"
export const SET_SERVICES_REPORT_LIST = "SET_SERVICES_REPORT_LIST"; 
export const SET_SERVICES_REPORT_DATE = "SET_SERVICES_REPORT_DATE"
export const SET_SERVICES_REPORT_ADDRESS = "SET_SERVICES_REPORT_ADDRESS"
export const SET_SERVICES_REPORT_ASSEMBLIES = "SET_SERVICES_REPORT_ASSEMBLIES"
export const SET_SERVICES_REPORT_CONTACT = "SET_SERVICES_REPORT_CONTACT"
export const SET_SERVICES_REPORT_CURRENT_STOP = "SET_SERVICES_REPORT_CURRENT_STOP"
export const SET_SERVICES_REPORT_CURRENT_STOP_ID = "SET_SERVICES_REPORT_CURRENT_STOP_ID"
export const SET_WATER_LIST = "SET_WATER_LIST"

const setDate = (date) =>({
	type: SET_SERVICES_REPORT_DATE, 
	date
})


const receiveAddress = (address) => ({
	type: SET_SERVICES_REPORT_ADDRESS,
	address
})

const receiveServicesList = (list) => ({
	type: SET_SERVICES_REPORT_LIST,
	list
})


const receiveServicesAssemblies = (list) =>({
	type: SET_SERVICES_REPORT_ASSEMBLIES, 
	list
})


const receiveServicesContact = (contact) =>({
	type: SET_SERVICES_REPORT_CONTACT, 
	contact
})


const receiveStop = (stop) =>({
	type: SET_SERVICES_REPORT_CURRENT_STOP, 
	stop
})
const receiveWaterDistricts = (list) =>({
	type: SET_WATER_LIST, 
	list
})

const setCurrentStopID = (stopID) =>({
	type: SET_SERVICES_REPORT_CURRENT_STOP_ID, 
	stopID
})

export const updateStopDetails =  (stopID, obj) => {
	return new Promise( async(resolve, reject) =>{	
		const response = await server.updateStopDetails(stopID, obj); 
		const data = await response.json(); 
		if(response.ok){
				resolve()
		}
	})

}

export const requestTestReport  = (testReportID) => {
	return new Promise( async(resolve, reject) =>{	
		const response = await server.getTestReport(testReportID); 
		const data = await response.json(); 
		if(response.ok){
				resolve(JSON.parse(data).report )
		}
	})
}

export const cancelStop = (stopID, reason) => {
	return new Promise( async(resolve, reject) =>{	
		const response = await server.cancelStop(stopID, reason); 
		const data = await response.json(); 
		if(response.ok){
				resolve()
		}
	})
}

export const updateAssembly = (assemblyID, obj, serviceID)  =>{
	return new Promise( async(resolve, reject)=>{
		const response = await server.updateAssembly(assemblyID, obj); 
		const data = await response.json(); 
		if(response.ok){
			
				resolve()
		}

	})
}

export const createAssembly = (addressID, stopID) =>{
	return new Promise( async(resolve, reject) =>{
		const response = await server.createAssembly(addressID, stopID); 
		const data = await response.json(); 
		if(response.ok){
			
				resolve()
		}
	})
}
export const setServicesReportDate = (date) => dispatch =>{
	return new Promise((resolve, reject) =>{
		try{
			dispatch( setDate(date) )
			resolve(true)
		}catch(e){	
			reject(e)
		}

	})
}


export const requestInvoiceStatus = (stopID) =>{
	return new Promise( async (resolve, reject) =>{
		try{
			const response = await server.requestInvoiceStatus(stopID); 
			const data = await response.json(); 
			if(response.ok){
				resolve(JSON.parse(data).list)
			}
		}catch(e){	
			reject(e)
		}
	})

}
export const requestStopAssemblies = (stopID) =>{
	return new Promise( async (resolve, reject) =>{
		try{
			const response = await server.requestStopAssemblies(stopID); 
			const data = await response.json(); 
			if(response.ok){
				resolve(JSON.parse(data).list)
			}
		}catch(e){	
			reject(e)
		}
	})

}

export const updateServiceType  = (serviceID, serviceType) => {
	return new Promise( async (resolve, reject) =>{
		try{
			const response = await server.updateServiceType(serviceID, serviceType); 
			const data = await response.json(); 
			if(response.ok){
				resolve(true)
			}
		}catch(e){	
			reject(e)
		}
	})
}
export const clearStop = () => dispatch =>{
	return new Promise( (resolve, reject) =>{
		try{
			dispatch( receiveStop(null) )
			resolve()
		}catch(e){	
			console.log("ERROR: ", e)
		}
	})
}
export const requestStopDetails = (stopID)  => {

	
}

export const requestTesters = () => dispatch  => {
	
}

export const reduceStopID = (stopID) => dispatch => {
	return new Promise( (resolve, reject) =>{
		try{
			dispatch( setCurrentStopID(stopID))
		}catch(e){	
			console.log("ERROR: ", e)
		}
	})
}

export const requestWaterDistricts = () => dispatch =>{
	return new Promise( async (resolve, reject) =>{
		try{
			const response = await server.requestWaterDistricts(); 
			const data = await response.json(); 
			if(response.ok){
				dispatch( receiveWaterDistricts( JSON.parse(data).list)  )
				resolve(true)
			}		
		}catch(e){	
			reject(e)
		}
	})
}
export const updateAddress = (addressID, obj,stopID) => dispatch => {
	return new Promise( async (resolve, reject) =>{
		try{
			const response = await server.updateAddress(addressID, obj, stopID); 
			const data = await response.json(); 
			if(response.ok){
				dispatch( receiveAddress( JSON.parse(data).address)  )
				resolve(true)
			}		
		}catch(e){	
			reject(e)
		}
	})
}

export const updateContact = (contactID, obj, stopID) => dispatch => {
	return new Promise( async (resolve, reject) =>{
		try{
			const response = await server.updateContact(contactID, obj, stopID); 
			const data = await response.json(); 
			if(response.ok){
				dispatch( receiveServicesContact( JSON.parse(data).contact)  )
				resolve(true)
			}		
		}catch(e){	
			reject(e)
		}
	})
}
export const requestAddress = (stopID) => dispatch => {
	return new Promise( async (resolve, reject) =>{
		try{
			const response = await server.requestAddress(stopID); 
			const data = await response.json(); 
			if(response.ok){
				dispatch( receiveAddress( JSON.parse(data).address)  )
				resolve(true)
			}		
		}catch(e){	
			reject(e)
		}
	})
}

export const requestStop = (stopID) =>  {
	return new Promise( async (resolve, reject) =>{
		try{
			const response = await server.requestStop(stopID); 
			const data = await response.json(); 
			if(response.ok){ 
				resolve( JSON.parse(data).stop )
			}		
		}catch(e){	
			reject(e)
		}
	})
}

export const updateTimeFrame = (stopID , startTime, endTime ) => dispatch => {
	return new Promise( async (resolve, reject) =>{
		try{
			const response = await server.updateTimeFrame(stopID, startTime, endTime); 
			const data = await response.json(); 
			if(response.ok){
				resolve(true)
			}
		
		}catch(e){	
			reject(e)
		}
	})
}

export const updateDate = (stopID, newdate) => dispatch => {
	return new Promise( async (resolve, reject) =>{
		try{
			const response = await server.updateDate(stopID, newdate); 
			const data = await response.json(); 

			if(response.ok){
				resolve(true)
			}
		
		}catch(e){	
			reject(e)
		}
	})
}
export const updateTester = (stopID, testerID) => dispatch => {
	return new Promise( async (resolve, reject) =>{
		try{
			const response = await server.updateTester(stopID, testerID); 
			const data = await response.json(); 

			if(response.ok){
				resolve(true)
			}
		
		}catch(e){	
			reject(e)
		}
	})
}

export const requestContact = (stopID) => dispatch => {
	
	return new Promise( async (resolve, reject) =>{
		try{
			const response = await server.requestContact(stopID); 
			const data = await response.json(); 

			if(response.ok){
				dispatch( receiveServicesContact( JSON.parse(data).contact) ); 
				resolve(true)
			}
		
		}catch(e){	
			reject(e)
		}

	})
}


export const requestServicesReportList = (date) => dispatch =>{
	return new Promise( async (resolve, reject) =>{
		try{
			const response = await server.requestServicesList(date); 
			const data = await response.json(); 

			if(response.ok){
				dispatch( receiveServicesList( JSON.parse(data).list) ); 
				resolve(true)
			}
		
		}catch(e){	
			reject(e)
		}

	})

}

export const requestServicedAssemblies = (stopID) => dispatch => {
	return new Promise( async (resolve, reject) =>{
		try{
			const response = await server.requestAssemblies(stopID); 
			const data = await response.json(); 
			if(response.ok){
				dispatch( receiveServicesAssemblies( JSON.parse(data).list) ); 
				resolve(true)
			}
		}catch(e){	
			reject(e)
		}
	})
} 