import { useSelector, useDispatch } from "react-redux";
import { useState, useEffect, useRef, useCallback } from "react";
import Table from 'react-bootstrap/Table';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import Spinner from 'react-bootstrap/Spinner';

import { requestAssemblyDetails, updateAssembly }  from "../../../../actions/addressApp.js"

export default function AssemblyRow(props) {
	const [assembly, setAssembly] = useState(null); 
	const [location, setLocation] = useState(null);
	const [size, setSize] = useState(null);
	const [make, setMake] = useState(null);
	const [model, setModel] = useState(null);
	const [serial, setSerial] = useState(null);
	const [hazard, setHazard] = useState(null); 
	const [type, setType] = useState(null); 
	const [status, setStatus] = useState(null)
	const [showAss, setShowAss] = useState(false)

	useEffect(() => {
		if (assembly == null) {
			setShowAss(false)
		} else {
			console.log("This is the assembly: ", assembly)
			setLocation(assembly.location); 
			setSerial(assembly.serial_number);
			setSize(assembly.size);
			setModel(assembly.model);
			setMake(assembly.make);
			setHazard(assembly.hazard_type)
			setType(assembly.assembly_type)
			setStatus(assembly.state)
			setShowAss(true)
		}
	}, [assembly])

	useEffect(() =>	{
		//request assembly info
		requestAssemblyDetails(props.assembly.id).then((data, err) => {
			setAssembly(data)
		})	
	}, [])


	const update = () => {
		let obj = {};
		obj.location = location;
		obj.serial = serial;
		obj.size = size;
		obj.make = make;
		obj.model = model;
		obj.hazard = hazard;
		obj.type = type;
		obj.state = status
		updateAssembly(props.assembly.id, obj).then((data, err) => {
			requestAssemblyDetails(props.assembly.id).then((data, err) => {
				setAssembly(data)
			})
		})
	}
	
    return (
		<>
			{
				showAss ?
					<>
					<tr>
					<td>
						S/N
					</td>
					<td>
						Size	
					</td>
					<td>
						Make
					</td>
					<td>
						Model
					</td>
					<td>
						Type
					</td>
					
					
					<td>
						Hazard
					</td>
				</tr>
						<tr>

						
						<td>
							<Form.Control
								value={serial}
								onChange={(event) => setSerial(event.target.value.toUpperCase() )}
								onBlur={update}
								size= "sm"
							/>
						</td>
						<td>
							<Form.Select
								size="sm"
								id="size"
								value={size}
								onChange={(event) => setSize(event.target.value)}
								onBlur={update}
							>
								<option value={null}> </option>
								<option value="3/8">3/8"</option>
								<option value="1/2">1/2"</option>
								<option value="3/4">3/4"</option>
								<option value="1">1"</option>
								<option value="1 1/4"> 1 1/4"</option>
								<option value="1.5">1 1/2"</option>
								<option value="2">2"</option>
								<option value="2.5">2 1/2"</option>
								<option value="3">3"</option>
								<option value="4">4"</option>
								<option value="6">6"</option>
								<option value="8">8"</option>
								<option value="10">10"</option>
								<option value="12">12"</option>
							</Form.Select>
						</td>
						<td>
							<Form.Control
								value={make}
								size="sm"
								onChange={(event) => setMake(event.target.value)}
								onBlur={update}
							/>
						</td>
						<td>
							<Form.Control
								value={model}
								size="sm"
								onChange={(event) => setModel(event.target.value)}
								onBlur={update}
							/>
						</td>

						
						<td>
								<Form.Select
									size= "sm"
									type="text"
									id="type"
									value={type}
									onChange={
										(event) => {
											setType(event.target.value)
										}
									}
									onBlur={update}
								>
									<option value={null}> Please choose one </option>
									<option value="DC"> DC</option>
									<option value="DCDA"> DCDA </option>
									<option value="DCDAII"> DCDA-II </option>
									<option value="RP"> RP </option>
									<option value="RPDA"> RPDA </option>
									<option value="RPDAII"> RPDA-II</option>
									<option value="PVB"> PVB </option>
									<option value="SVB"> SVB </option>
									<option value="AVB"> AVB </option>
									<option value="AG"> AG </option>
								</Form.Select>

						</td>
					
						
							
							<td>

								<Form.Control
									value={hazard}
									size="sm"
									onChange={(event) => setHazard(event.target.value)}
									onBlur={update}
								/>
							</td>
						</tr>

						<tr>
							<td colSpan = {4}>
								Location
							</td>
							<td colSpan = {2}>
								Status
							</td>
						</tr>
						<tr>
							
							<td colSpan = {4}>
								<Form.Control
									value={location}
									onChange={(event) => setLocation(event.target.value)}
									onBlur={update}
									size="sm"
								/>
							</td>
							<td colSpan = {2}> 
							
								<Form.Select size= "sm" 
									value = {status} 
									onChange = {(event) => setStatus(event.target.value)}
									onBlur={update}
								>
									<option value = {null}> Select One</option>
									<option value = "EXISTING"> Existing</option>
									<option value = "REMOVED" > Removed </option>
									<option value = "NEW" > New </option>
									<option value = "REPLACED" >Replaced</option>
								</Form.Select>
							</td>
						</tr>
						<hr/>
						
					</>
					:
					<>
						<Spinner />
					</>
			}
		</>
    )
}