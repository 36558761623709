import {
  SET_BILLING_ID, 
  CLEAR_BILLING_ID
} from "../../actions/scheduling";

const _nullBillingId =  null; 
                
export default (state = _nullBillingId, { type, id }) => {
  Object.freeze(state);
  switch (type) {
    case SET_BILLING_ID:
      return id;
    case CLEAR_BILLING_ID: 
      return _nullBillingId
    case "": 
      return _nullBillingId; 
    default:
      return state;
  }
};