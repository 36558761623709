import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import {useSelector, useDispatch} from "react-redux";
import { useState, useEffect, useRef, useCallback} from "react";
import DumpTable from "./DumpTable"
import DumpFilters from "./DumpFilters"
export default function TestReportDump(){
	

	return(
		<Container>
			<Row>
				<Col>
				</Col>
			</Row>
			<Row>
				<Col>
					<DumpFilters />
				</Col>
			</Row>
			<Row>
				<Col>
					<DumpTable />
				</Col>
			</Row>
		</Container>
	)
}