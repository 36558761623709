import {
  GAUGE_DETAILS, CLEAR_DETAILS
} from "../../actions/gauges";

const _nullObj = null
                
export default (state = _nullObj, { type, obj }) => {
  Object.freeze(state);
  switch (type) {
    case GAUGE_DETAILS:
      return obj;
    case CLEAR_DETAILS: 
      return _nullObj; 
    case "":
      return _nullObj; 
    default:
      return state;
  }
};