import {
  RECEIVE_ASSEMBLY
} from "../../actions/report";

const _nullAssembly = null
                
export default (state = _nullAssembly, { type, assembly }) => {
  Object.freeze(state);
  switch (type) {
    case RECEIVE_ASSEMBLY:
      return assembly;
    case "": 
      return _nullAssembly; 
    default:
      return state;
  }
};