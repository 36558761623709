

import {
  GET_LOG_LIST
} from "../../actions/customerApp";

const _nullList = []
                
export default (state = _nullList, { type, list }) => {
  Object.freeze(state);
  switch (type) {
    case GET_LOG_LIST:
      return list;
    case "": 
      return _nullList; 
    default:
      return state;
  }
};