import Form from 'react-bootstrap/Form';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Alert from 'react-bootstrap/Alert';
import Spinner from 'react-bootstrap/Spinner';
import Badge from 'react-bootstrap/Badge';
import Modal from 'react-bootstrap/Modal';
import Dropdown from 'react-bootstrap/Dropdown';
import ListGroup from 'react-bootstrap/ListGroup';
import { useState, useEffect, useRef} from 'react';
import {useSelector, useDispatch} from "react-redux";
import { searchQuery } from "../../../actions/SearchEngine.js" 
import { newCustomerID, createCustomer, associateAddressToCustomer} from "../../../actions/customerApp.js" 
import { determineID } from "../../../actions/addressApp.js"
import { Overlay, FormControl } from 'react-bootstrap';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import { checkSession } from "../../../actions/session.js"


export default function SeachBar(){
	const [customerID, setCustomerID] = useState(0); 
	const [showResultsBool, setShowResultsBool] = useState(false)
	const [clientModal, setClientModal] = useState(false); 
	const [customerSnapshot, setCustomerSnapshot] = useState(false); 
	const [search, setSearch] = useState(""); 
	const results = useSelector( (state)=> state.results.resultsList)
	const addressesResults = useSelector((state)=> state.results.addresses)
	const dispatch = useDispatch(); 
	const [isCustomer, setIsCustomer] = useState(false)
	const target = useRef(null);

	const check = () => {
		return new Promise((resolve, reject) => {
			dispatch(checkSession()).then((data, err) => {
				resolve()
			})
		})
	}

	useEffect(() => {
		console.log(".. ... ... .. ... ")
		if (search != "") {
			check().then((data, err) => {
				dispatch(searchQuery(search)).then((data, err) => {
					setShowResultsBool(true);
				})
			})
			
		}else{
			setShowResultsBool(false); 
		}
	}, [search])

	const showCustomer = (key, addID)=>{
		console.log("Showing this customer: ", key, addID); 
		if(key == -1){
			//create a new customer for this address & return the customerID 
			let emptyCustomer = {}; 
			emptyCustomer.fname = null; 
			emptyCustomer.lname = null; 
			emptyCustomer.phone = null; 
			emptyCustomer.email = null; 
			emptyCustomer.displayName = null; 
			dispatch( createCustomer(emptyCustomer) ).then( (newID, err) =>{
				dispatch( associateAddressToCustomer(addID, newID) ).then((data, err) =>{
						console.log("Got the updated list! ")
						dispatch( newCustomerID(newID) ).then((data, err)=>{
							console.log("New Customer!"); 
							setShowResultsBool(false)
							//add addressID to customer 
							
						})
				})
					
			}) 

		}else{
			dispatch( newCustomerID(key) ).then((data, err)=>{
				dispatch( determineID(addID) ).then((data, err) =>{
				
				})
			})

		}
		
	}


	const renderListItems = results.map( (result) =>{
		return(
			<ListGroup.Item eventKey  = {result.id} onClick ={ ()=> showCustomer(result.id) } >
				<small><strong>{result.display_name}</strong></small>
			</ListGroup.Item>
		)
	})
	const renderListAddresses = addressesResults.map( (addr)=>{
		return(
			<ListGroup.Item eventKey  = {addr.accountID} onClick ={ ()=>{

					showCustomer( addr.accountID, addr.id) 

				}
			}>
				<small><strong> {addr.street}, {addr.city}, {addr.state}, {addr.zipcode}</strong></small>
			</ListGroup.Item>
		)
	})
	return(
		<Container >
			<Row>
				<Form >
					<OverlayTrigger  
						trigger = 'focus'
						placement="bottom"
						show={showResultsBool}
						onToggle = {()=>{
							if(showResultsBool){
								setShowResultsBool(false)
							}else{
								setShowResultsBool(true)
							}
							
						}}
     					delay={{ show: 100, hide: 100 }}

      					overlay={
      						<Container id="makeittothetop">
				 			
				 			<Row className="listDisplay"> 
							<ListGroup >
								{	
									showResultsBool ?
										<>
											{ 
												results.length ? 
													<> 
														{ renderListItems} 
														
													</> 
												: 
													<>
														
										     
										       		</>	
											}

												{
												addressesResults.length ?
													<>
														{renderListAddresses}
													</>
												: 
													<></>
												}
										</>
										


									:
									<>
									</>
								}
								</ListGroup>
								</Row>
							</Container>}
						>
						<Form.Control 
							placeholder = "Search"
							value={search} 
				
							onChange={ (event) => setSearch( event.target.value)  }  
							onFocus = { () => setShowResultsBool(true)}
							ref={target}
							id="searchMe-amazon"
						/>
					</OverlayTrigger>
				</Form>
			</Row>
		</Container>
	)
}