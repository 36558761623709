

export const createAccount = (obj) =>(
	fetch("backflow/account/create", {
		method: "POST", 
		body: JSON.stringify(obj), 
		headers: {
            "Content-Type" : "application/json"
        }
	}) 
)
/*getters*/
export const requestBillingDetails = (accountID) =>(
	fetch("backflow/account/billingDetails?" + new URLSearchParams({
		accountID: accountID
	}), {
		method: "GET", 
		headers: {
            "Content-Type" : "application/json"
        }
	}) 
)

/*Account updates*/
export const updateBillingName= (newname, billingID) =>(
	fetch("backflow/account/billingName", {
		method: "PUT", 
		body: JSON.stringify({ newname: newname, billingID: billingID}), 
		headers: {
            "Content-Type" : "application/json"
        }
	}) 
)

export const updateBillingContact= (obj, billingID) =>(
	fetch("backflow/account/billingContact", {
		method: "PUT", 
		body: JSON.stringify({ obj: obj, billingID: billingID}), 
		headers: {
            "Content-Type" : "application/json"
        }
	}) 
)
export const updateBillingAddress= (obj, billingID) =>(
	fetch("backflow/account/billingAddress", {
		method: "PUT", 
		body: JSON.stringify({ obj: obj, billingID: billingID}), 
		headers: {
            "Content-Type" : "application/json"
        }
	}) 
)
export const updateBillingDetails= (obj,billingID) =>(
	fetch("backflow/account/billingDetails", {
		method: "PUT", 
		body: JSON.stringify({ obj: obj, billingID: billingID}), 
		headers: {
            "Content-Type" : "application/json"
        }
	}) 
)

export const updateAccountName= (newname, accountID) =>(
	fetch("backflow/account/accountName", {
		method: "PUT", 
		body: JSON.stringify({ newname: newname, accountID: accountID}), 
		headers: {
            "Content-Type" : "application/json"
        }
	}) 
)

export const updateContactName= (obj, accountID) =>(
	fetch("backflow/account/contactName", {
		method: "PUT", 
		body: JSON.stringify({obj: obj, accountID: accountID }), 
		headers: {
            "Content-Type" : "application/json"
        }
	}) 
)

export const updateContactPhoneEmail= (obj, accountID) =>(
	fetch("backflow/account/phoneEmail", {
		method: "PUT", 
		body: JSON.stringify({obj: obj, accountID: accountID }), 
		headers: {
            "Content-Type" : "application/json"
        }
	}) 
)
/*sends status and name*/
export const updateStatus= (obj, accountID) =>(
	fetch("backflow/account/status", {
		method: "PUT", 
		body: JSON.stringify({obj: obj, accountID: accountID }), 
		headers: {
            "Content-Type" : "application/json"
        }
	}) 
)







