import Table from 'react-bootstrap/Table';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import {format} from "date-fns"
import {useSelector, useDispatch} from "react-redux";
import { useState, useEffect, useRef, useCallback} from "react";
import {requestFailedTestReports, setID, requestTestReport} from "../../../../actions/failed" 

export default function TestReport(){
	const currentID = useSelector((state) => state.failed_id)
	const [showReport, setShowReport] = useState(false)
	const dispatch = useDispatch(); 
	const myReport = useSelector((state) => state.failed_report)

	useEffect(()=>{
		if(currentID != null){
			setShowReport(false)
			dispatch( requestTestReport(currentID) ).then( (data, err) => {
				console.log("Received test report object")
				setShowReport(true)
			})
		}
	},[currentID])

	return(


		<Container>
			{
				showReport ?
					<>
						<Row>
							<Col>
								<h4> Test Report: {currentID} </h4>
							</Col>
						</Row>
						
						<Row>
							<Col >
								<p>
									<strong> Date </strong> <br/>
									{format( new Date( myReport.initialTest_date) , "MM/dd/yyyy")}
									<Button variant = "link" href={myReport.reportLink} target="_blank"> Open PDF</Button>
								</p>
							</Col>
						</Row>	
				</>
			: 
				<> Loading... </>

			}
				
		</Container>
	)
}