


export const checkSession = () => (
	fetch("backflow/session/checkSession", {
		method: "GET",
		headers: {
			"Content-Type": "application/json"
		}
	})
)
export const requestFlashReport = (reportID) => (
	fetch("backflow/customer/flashReport?" + new URLSearchParams({
		reportID: reportID

	}), {
		method: "GET", 
		headers: {
            "Content-Type" : "application/json"
        }
	}) 
)

export const getStops = (obj) =>(
	fetch("backflow/stops", {
		method: "POST", 
		body: JSON.stringify(obj), 
		headers: {
            "Content-Type" : "application/json"
        }
	}) 
)

export const setTestsAsSent = (list) =>(
	fetch("backflow/testReport/setAsSentList", {
		method: "POST", 
		body: JSON.stringify({ list: list}), 
		headers: {
            "Content-Type" : "application/json"
        }
	}) 

)

export const requestTestReport = (obj) =>(
	fetch("backflow/testReport", {
		method: "POST", 
		body: JSON.stringify(obj), 
		headers: {
            "Content-Type" : "application/json"
        }
	}) 
)

export const getNextReport = (obj) => (
	fetch("backflow/testReport/getNext", {
		method: "POST", 
		body: JSON.stringify(obj), 
		headers: {
            "Content-Type" : "application/json"
        }
	}) 


)
export const setReportAsSent = (obj) =>(
	fetch("backflow/testReport/setAsSent", {
		method: "POST", 
		body: JSON.stringify(obj), 
		headers: {
            "Content-Type" : "application/json"
        }
	}) 

)

export const requestPendingReports = (obj) => (
	fetch("backflow/testReport/testReportList", {
		method: "POST", 
		body: JSON.stringify(obj), 
		headers: {
            "Content-Type" : "application/json"
        }
	}) 
)


export const popInvoice = (offset) => (
	fetch("backflow/invoice/pop?" + new URLSearchParams({
		offset: offset
	}), {
		method: "GET", 
		headers: {
            "Content-Type" : "application/json"
        }
	}) 

)
export const getSalesTax = (addressID) => (
	fetch("backflow/invoice/taxrate?" + new URLSearchParams({
		addressID: addressID
	}), {
		method: "GET", 
		headers: {
            "Content-Type" : "application/json"
        }
	}) 

)

export const queryTerms = () => (
	fetch("backflow/invoice/queryTerms", {
		method: "GET", 
		headers: {
            "Content-Type" : "application/json"
        }
	}) 

)


export const requestInvoice = (obj) =>(
	fetch("backflow/invoice", {
		method: "POST", 
		body: JSON.stringify(obj), 
		headers: {
            "Content-Type" : "application/json"
        }
	}) 

)

export const addItem = (invoiceID) => (
	fetch("backflow/invoice/addItem", {
		method: "POST", 
		body: JSON.stringify({invoiceID: invoiceID}), 
		headers: {
            "Content-Type" : "application/json"
        }
	})
)

export const requestInvoiceItems = (invoiceID) => (
	fetch("backflow/invoice/itemList?" + new URLSearchParams({
		invoiceID: invoiceID
	}), {
		method: "GET", 
		headers: {
            "Content-Type" : "application/json"
        }
	})
)
export const requestServiceAddress= (invoiceID) =>(
	fetch("backflow/invoice/serviceAddress?" + new URLSearchParams({
		invoiceID: invoiceID
	}), {
		method: "GET", 
		headers: {
            "Content-Type" : "application/json"
        }
	})
   
)

export const requestBillingDetail = (invoiceID) =>(
	fetch("backflow/invoice/billingDetail?" + new URLSearchParams({
		invoiceID: invoiceID
	}), {
		method: "GET", 
		headers: {
            "Content-Type" : "application/json"
        }
	})
   
)

export const setAsBroken = (invoiceID) =>(
	fetch("backflow/invoice/broken", {
		method: "PUT", 
		body: JSON.stringify({ invoiceID:  invoiceID}), 
		headers: {
            "Content-Type" : "application/json"
        }
	})
   
)

export const updateBillingDetails = (obj) => (
	fetch("backflow/invoice/billingDetail", {
		method: "PUT", 
		body: JSON.stringify(obj), 
		headers: {
            "Content-Type" : "application/json"
        }
	})
)

export const updateServiceAddress = (obj, serviceAddress, invoiceID) => (
	fetch("backflow/invoice/serviceAddress", {
		method: "PUT", 
		body: JSON.stringify({obj: obj, addressID: serviceAddress, invoiceID: invoiceID} ), 
		headers: {
            "Content-Type" : "application/json"
        }
	})
)

export const updateSalesPO = (obj) => (
	fetch("backflow/invoice/salesPO", {
		method: "PUT", 
		body: JSON.stringify(obj), 
		headers: {
            "Content-Type" : "application/json"
        }
	})
)

export const removeItem = (itemID) => (
	fetch("backflow/invoice/removeItem", {
		method: "POST", 
		body: JSON.stringify({itemID: itemID}), 
		headers: {
			"Content-Type" : "application/json"
		}
	})
)

export const updateItem = (obj, itemID, invoiceID) => (
	fetch("backflow/invoice/updateItem", {
		method: "PUT", 
		body: JSON.stringify({obj: obj, itemID: itemID, invoiceID: invoiceID}), 
		headers: {
			"Content-Type" : "application/json"
		}
	})
)

export const setWDSend = (obj) => (
	fetch("backflow/testReport/setState", {
		method: "POST", 
		body: JSON.stringify(obj), 
		headers: {
            "Content-Type" : "application/json"
        }
	}) 
)

export const updateQuickbooksDetails = ( obj ) => (
	fetch("backflow/invoice/updateQuickboooks", {
		method: "POST", 
		body: JSON.stringify(obj), 
		headers: {
            "Content-Type" : "application/json"
        }
	}) 
)


export const login = (obj) => (
	fetch("backflow/session", {
		method: "POST", 
		body: JSON.stringify(obj), 
		headers: {
            "Content-Type" : "application/json"
        }
	}) 
)
export const logout = () => (
	fetch("backflow/session", {
		method: "DELETE"
	}) 
)


export const requestDates = (obj) => (
	fetch("backflow/dates", {
		method: "POST", 
		body: JSON.stringify(obj), 
		headers: {
            "Content-Type" : "application/json"
        }
	}) 

)

export const getInvoices = ()=> (
	fetch("backflow/invoice/pending", {
		method: "POST", 
		headers: {
            "Content-Type" : "application/json"
        }
	}) 
)

export const markAsEntered = (obj) => (
	fetch("backflow/invoice/quickbooksEntered", {
		method: "POST", 
		body: JSON.stringify(obj),  
		headers: {
            "Content-Type" : "application/json"
        }
	}) 
)


export const getPendingTestReport = () =>(
	fetch("backflow/testReport/pending", {
		method: "POST",  
		headers: {
            "Content-Type" : "application/json"
        }
	})
)

export const getTestReportPendingList = () => (
	fetch("backflow/testReport/forWater", {
		method: "GET",  
		headers: {
            "Content-Type" : "application/json"
        }
	})
)

export const requestServicesList = (obj) =>(
	fetch("backflow/invoice/services", {
		credentials: 'include', 
		method: "POST",  
		body: JSON.stringify( obj ), 
		headers: {
            "Content-Type" : "application/json", 
      		"Cache-Control": "no-store, no-cache"
        }
	})
)

export const createQBInvoice = (obj, token) => (
	fetch("https://americanplumbingservices.com/backflow/invoice/createInvoice", {
		credentials: 'include', 
		method: "POST",  
		body: JSON.stringify( obj ), 
		headers: {
            "Content-Type" : "application/json", 
      		"Cache-Control": "no-store, no-cache",
      		"x-csrf-token" : token
        }
	})
)

export const getCSRF = () => (
  fetch("https://americanplumbingservices.com/csrf", {
      method: "GET", 
      credentials: "include", 
      headers: {
        "Content-Type": "application/json", 
       "Cache-Control": "no-store, no-cache"
      }
  })
)


export const printWaterReport = (printjob) =>(
	fetch( "/backflow/waterDistrict/print", {
		method: "POST",  
		body: JSON.stringify(printjob), 
		headers: {
            "Content-Type" : "application/json"
        }
	})
)
export const requestOnlineWaterDistrictList = () =>(
	fetch( "/backflow/waterDistrict/waterDistrictList/online", {
		method: "POST",   
		headers: {
            "Content-Type" : "application/json"
        }
	})
)

export const requestMailWaterDistrictList = ()=> (
	fetch( "/backflow/waterDistrict/waterDistrictList/mail", {
		method: "POST",   
		headers: {
            "Content-Type" : "application/json"
        }
	})

)

export const requestEmailWaterDistrictList = () => (
	fetch( "/backflow/waterDistrict/waterDistrictList/email", {
		method: "POST",   
		headers: {
            "Content-Type" : "application/json"
        }
	})
)
export const requestWaterDistrictDetails = (obj) => (
	fetch( "/backflow/waterDistrict/getDetails", {
		method: "POST",  
		body: JSON.stringify(obj), 
		headers: {
            "Content-Type" : "application/json"
        }
	})
)

export const checkOffReports = (obj) =>(
	fetch( "/backflow/testReport/checkOff", {
		method: "POST",  
		body: JSON.stringify(obj), 
		headers: {
            "Content-Type" : "application/json"
        }
	})
)

export const requestPrinterList = () =>(
	fetch( "/backflow/waterDistrict/printer", {
		method: "POST",  
		headers: {
            "Content-Type" : "application/json"
        }
	})
)


export const requestTesters = () => (
	fetch( "/backflow/testers/active", {
		method: "POST",  
		headers: {
            "Content-Type" : "application/json"
        }
	})
)

export const requestDispatch = (obj) =>(
	fetch("/backflow/dispatch", {
				method: "POST",
				body: JSON.stringify(obj),
				headers: {
		            "Content-Type" : "application/json"
		        }
	})
)


export const scheduleNewClient = (obj) =>(
	fetch("/backflow/test/newClient", {
				method: "POST",
				body: JSON.stringify(obj),
				headers: {
		            "Content-Type" : "application/json"
		        }
	})
)

export const searchResult = (obj) =>(
	fetch("/backflow/search", {
				method: "POST",
				body: JSON.stringify(obj),
				headers: {
		            "Content-Type" : "application/json"
		        }
	})
)

export const requestMessages = () => (
	fetch("/backflow/chat", {
				method: "POST",
				headers: {
		            "Content-Type" : "application/json"
		        }
	})

)

export const getCustomer = (obj) => (
	fetch("/backflow/customer/customer", {
				method: "POST",
				body: JSON.stringify(obj),
				headers: {
		            "Content-Type" : "application/json"
		        }
	})

)
export const getContact = (obj) => (
	fetch("/backflow/customer/contact", {
				method: "POST",
				body: JSON.stringify(obj),
				headers: {
		            "Content-Type" : "application/json"
		        }
	})

)
export const getAddresses = (obj) => (
	fetch("/backflow/customer/addresses", {
				method: "POST",
				body: JSON.stringify(obj),
				headers: {
		            "Content-Type" : "application/json"
		        }
	})

)


export const getCompanyItems = () => (
	fetch("backflow/invoice/companyItems", {
		method: "GET", 
		headers: {
			"Content-Type": 'application/json'
		}
	})
)

export const requestWaterPDF = (districtID) => (
	fetch( "/backflow/waterDistrict/getPrintable?" + new URLSearchParams({
		districtID:  districtID
	}), {
		method: "GET", 
		headers: {
            "Content-Type" : "application/json"
        }
	})
)

