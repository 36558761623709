export const requestGauge = (id) =>(
	fetch("backflow/gauge?" + new URLSearchParams({
		id: id
	}), {
		method: "GET",
		headers: {
            "Content-Type" : "application/json"
        }
	}) 
)
export const updateGauge= (id, obj) =>(
	fetch("backflow/gauge", {
		method: "PUT",
		body: JSON.stringify({
			id: id, 
			obj: obj
		}), 
		headers: {
            "Content-Type" : "application/json"
        }
	}) 
)

export const createGauge= (obj) =>(
	fetch("backflow/gauge", {
		method: "POST",
		body: JSON.stringify({
			obj: obj
		}), 
		headers: {
            "Content-Type" : "application/json"
        }
	}) 
)