import {
  SET_ON_SITE_PHONE, 
  CLEAR_ON_SITE_PHONE
} from "../../actions/scheduling";

const _nullOnSitePhone = null; 
                
export default (state = _nullOnSitePhone, { type, phone }) => {
  Object.freeze(state);
  switch (type) {
    case SET_ON_SITE_PHONE:
      return phone;
    case CLEAR_ON_SITE_PHONE: 
      return _nullOnSitePhone
    case "": 
      return _nullOnSitePhone; 
    default:
      return state;
  }
};