import {
SET_CURRENT_DISTRICT_ID, 
CLEAR_CURRENT_DISTRICT_ID
} from "../../actions/districtManagement";
const _nullID = null;  
                
export default (state = _nullID, { type, id }) => {
  Object.freeze(state);
  switch (type) {
    case SET_CURRENT_DISTRICT_ID:
      return id;
    case CLEAR_CURRENT_DISTRICT_ID: 
      return _nullID; 
    case "": 
      return _nullID; 
    default:
      return state;
  }
};