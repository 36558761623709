import {
  GET_PRINTER_LIST
} from "../../actions/testReports";

const _nullPrinters = { 
  list: []
}
                
export default (state = _nullPrinters, { type, printerlist }) => {
  switch (type) {
    case GET_PRINTER_LIST:
      return printerlist;
    case "": 
      return _nullPrinters; 
    default:
      return state;
  }
};