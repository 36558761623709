
import {
  GET_OVERDUE
} from "../../actions/invoice";

const _nullOverdue = { 
  overduelist: []
}
                
export default (state = _nullOverdue, { type, overduelist }) => {
  Object.freeze(state);
  switch (type) {
    case GET_OVERDUE:
      return overduelist;
    case "": 
      return _nullOverdue; 
    default:
      return state;
  }
};