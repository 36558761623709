import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import Card from 'react-bootstrap/Card';
import Modal from 'react-bootstrap/Modal';
import Alert from 'react-bootstrap/Alert';
import Container from 'react-bootstrap/Container'; 
import Offcanvas from 'react-bootstrap/Offcanvas';

import TestReportApp from '../modules/apps/TestReportApp'
import ScheduleApp from "../modules/apps/ScheduleApp"; 
import WaterDeliveryApp from "../modules/apps/WaterDeliveryApp"


import OfficeHeader from '../modules/OfficeHeader'; 
import InvoiceList from "../modules/InvoiceList"; 

import TestReportList from "../modules/TestReportList"
import DispatchApp from "../modules/apps/DispatchApp.js"
import NewDispatch from "../modules/apps/NewDispatch"
import SearchBar from "../modules/SearchBar"


import MessageApp from "../modules/apps/MessageApp"
import SalesApp from "../modules/apps/SalesApp"
import HomeApp from "../modules/apps/HomeApp"
import InvoiceApp from "../modules/apps/InvoiceApp.js"
import Navigation from "../alternativeModules/Navigation"

import Footer from "../modules/Footer"; 
import Tab from 'react-bootstrap/Tab';
import Form from 'react-bootstrap/Form';

import Tabs from 'react-bootstrap/Tabs';
import Nav from 'react-bootstrap/Nav';
import Button from 'react-bootstrap/Button';
import ButtonGroup from 'react-bootstrap/ButtonGroup'
import { useState, useEffect } from 'react';



export default function Dispatch(){
	const [key, setKey] = useState('home');
	const [showApp, setShowApp] = useState(false); 
	const [show, setShow] = useState(false)
	const [appToShow, setAppToShow] = useState(<><HomeApp /></>)
	const [appName, setAppName ] = useState("")

	function handleClose(){
		setShow(false)
	}


	return(
		
		<Container fluid>
			<Navigation />
		</Container>
		
	); 
}