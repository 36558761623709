import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';
import Alert from 'react-bootstrap/Alert'
import Spinner from 'react-bootstrap/Spinner';
import TesterSubmittedInvoices from "./TesterSubmittedInvoices"; 
import {useSelector, useDispatch} from "react-redux";
import { useState, useEffect, useRef, useCallback} from "react";
import { requestInvoices, 
	setInvoiceAsBroken, 
	requestInvoiceTotal
} from "../../../actions/invoice.js"
import InvoiceList  from "./InvoiceList"
import { ErrorBoundary } from "react-error-boundary";

function ErrorMessage(props){
	const invoice = useSelector( (state) => state.invoice.id)
	useEffect(()=>{
		console.log("There was an error!")
		setInvoiceAsBroken( invoice ).then((data, err) =>{
			props.resetCount(); 
		})
	}, [])
	return(
			<Alert> 
				This invoice has an error: {invoice} & has been marked for review				
			</Alert>
		)

}

export default function TesterSubmitted(){
	const dispatch = useDispatch(); 
	return(
		<Container>
			<ErrorBoundary fallback = 
				{ 
					<ErrorMessage  resetCount = 
						{ 
							() => {

								dispatch( requestInvoiceTotal() ).then( 
									(data, err) =>{
										console.log("refreshing my list...")

									}
								)

								dispatch(requestInvoices()).then(
									(data, err)=> {
										
									}
								)
							}
						}
					/>
				}	
			>
				<InvoiceList />
			</ErrorBoundary>
		</Container>
	)
}