import { useState, useEffect, useRef, useCallback} from "react";
import {useSelector, useDispatch} from "react-redux";
import Table from 'react-bootstrap/Table';
import Badge from 'react-bootstrap/Badge';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Form from 'react-bootstrap/Form';
import Col from 'react-bootstrap/Col';
export default function InitialResults(){
	const report = useSelector((state) => state.testReport)
	const assembly = useSelector((state) => state.report_assembly)
	const [value, setValue] = useState(null)
	function yesno(boolval){
		if(boolval){
			return "Yes"
		}else{
			return "No"
		}
	}
	function tightLeaked(tight, leaked){
		if(tight){
			return "TIGHT"
		}else if(leaked){
			return "LEAKED"
		}else {
			return "NA"
		}
	}
	function passFail(pass, fail){
		if(pass){
			return "PASSED"
		}else if(fail){
			return "FAILED"
		}else {
			return "NA"
		}
	}

	function onoff(boolval){
		if(boolval){
			return "On"
		}else{
			return "Off"
		}
	}

	function openedDidNot(opened, didnot){
		if(opened){
			return "Opened Fully"
		}else if(didnot){
			return "Did not open"
		}else {
			return "NA"
		}
	}
	return(
		<Container>
			<Row>
				<Col>
					<Row>
					<Col sm dm lg ={3}>
						
						<h5>Results</h5>
					</Col>
					<Col>
						<h5>
								{
									report.initialTest_pass?
										<Badge bg= "success">PASS </Badge>
									:
										<> </>
								}

								{
									report.initialTest_fail ?
										<Badge bg= "danger">Failed </Badge>
									: 
										<></>
								}
						</h5>
					</Col>
					</Row>
				</Col>	
			</Row>
			
			<Row>
				<Col>
					<Row>

				<Col>
					<strong>DC </strong><br/>
				</Col>
				<Col>
					<Form.Check type="checkbox" label="Type II" value={report.RPA_typeII} readOnly/>
				</Col>
			</Row>
					<p>
						<strong> <small>#1: </small> </strong><u>{report.RPA1_psid}</u> psid - {tightLeaked(report.RPA1_tight, report.RPA1_leaked)} <br/>
						<strong><small>#2: </small> </strong>
						<u>{report.RPA2_psid}</u> psid-  {tightLeaked(report.RPA2_tight, report.RPA2_leaked)}
					</p>
					<p>
						<small>REPAIR</small><br/>
						<strong><small>#1</small></strong>
						<u>{report.repair_rpa_double_c1_psid}</u> psid, 
						{tightLeaked(report.repair_rpa_double_c1_tight, report.repair_rpa_double_c1_leaked)} <br/>
						<p>
						<strong><small>#2</small></strong>
						<u>{report.repair_rpa_double_c2_psid} </u>psid, 
						{tightLeaked(report.repair_rpa_double_c2_tight, report.repair_rpa_double_c2_leaked)}
					</p>
					</p>
				</Col>

				<Col>
					<strong> RP</strong> <br/>
					<p>
						<small><strong > Pressdrop :</strong></small><u>{report.RPA_pressdrop}</u><br/>
						<small><strong>Opened at: </strong></small><u>{report.RPA_opened_at}</u> <br/>
						<small><strong>Relief Valve: </strong></small><u>{passFail( report.RPA_RV_pass, report.RPA_RV_fail)} </u> <br/>
						
					</p>

					<p>
						<small> REPAIR </small> <br/>
						<small><strong> Pressdrop: </strong></small><u>{report.repair_rpa_pressdrop}</u><br/>
						<small><strong>Opened at: </strong></small> <u>{report.repair_rpa_openedat}</u> <br/> 
						<small><strong> Relief Valve: </strong></small> <u>{passFail( report.repair_rpa_relief_pass,report.repair_rpa_relief_fail)} </u>
					</p>
				</Col>
				<Col>
					<p>
						<strong>PVBA/SVBA</strong><br/>
						 <strong><small>Air Inlet: </small> </strong> <u>{report.VB_air_opened_at}</u>  <br/>{openedDidNot( report.VB_air_opened_fully, report.VB_air_didNotOpen) }<br/>
						<strong><small>Check Valve: </small> </strong><u>{report.VB_check_pressdrop}</u> psid <br/>
						<Form.Check label ="Failed"  checked ={report.VB_check_failed} />
					</p>
					<p>
						<small>REPAIR</small><br/>
						 <small><strong>Air Inlet</strong></small> <u> </u> <br/>
						<small> <strong> Opened at:</strong> </small><u>{report.repair_vb_air_openedat}  </u> <br/> {openedDidNot( report.repair_vb_air_opened_fully, report.repair_vb_air_did_not_open) }<br/>
						<small><strong>Check Valve</strong></small><u>{report.repair_vb_check_pressdrop}</u> psid <br/>
						<Form.Check label ="Failed"  checked ={report.repair_vb_check_pass} readOnly />
					</p>
				</Col>
			</Row>
		
			
		</Container>
	)
}