import {
  RECEIVE_WATER_DISTRICT_LIST
} from "../../actions/waterDelivery";

const _nullList = []
                
export default (state = _nullList, { type, list }) => {
  Object.freeze(state);
  switch (type) {
    case RECEIVE_WATER_DISTRICT_LIST:
      return list;
    case "": 
      return _nullList; 
    default:
      return state;
  }
};