import {
  INVOICE_BALANCE
} from "../../actions/invoice";

const _nullBalance =  0
                
export default (state = _nullBalance, { type, balance }) => {
  Object.freeze(state);
  switch (type) {
    case INVOICE_BALANCE:
      return balance;
    case "": 
      return _nullBalance; 
    default:
      return state;
  }
};