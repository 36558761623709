import {
    SET_DISTRICT_CONTACT_LIST,
    CLEAR_DISTRICT_CONTACT_LIST
} from "../../actions/districtManagement";
const _nullList = [];

export default (state = _nullList, { type, list }) => {
    Object.freeze(state);
    switch (type) {
        case SET_DISTRICT_CONTACT_LIST:
            return list;
        case CLEAR_DISTRICT_CONTACT_LIST:
            return _nullList;
        case "":
            return _nullList;
        default:
            return state;
    }
};