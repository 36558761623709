import {useSelector, useDispatch} from "react-redux";
import { useState, useEffect, useRef, useCallback} from "react";
import Table from 'react-bootstrap/Table';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import Spinner from 'react-bootstrap/Spinner';
import {
	requestServicedAssemblies, 
	createAssembly

 } from "../../../../actions/servicesReport.js"

 import {requestAssemblyList} from "../../../../actions/addressApp"
import { format } from "date-fns"

import AssemblyRow from "./AssemblyRow"

export default function ServicesTable(props){
	const dispatch = useDispatch(); 
	const [loader, setLoader] = useState(false); 

	const addressID = useSelector((state) => state.addressID)
	const address = useSelector((state) => state.address_details)
	const list = useSelector((state) => state.address_assemblies)

	const requestlist = () => {
		setLoader(true)
		dispatch(requestAssemblyList(addressID)).then((data, err) => {
			setLoader(false)
		})
	}

	useEffect( ()=>{
		if(addressID != null){
			requestlist(); 
		} 
	}, [addressID])

	const renderServices = list.map( (assembly) => {
		return(
			<AssemblyRow assembly={ assembly } />
		)
	})

	function newAssembly(){
		createAssembly(addressID).then((data, err)=>{
			requestlist(); 
		})
	}

	return(
		<Table  striped responsive bordered size= "sm">
			<thead> 
			
			</thead>
			<tbody>
				{
					loader ?
						<Spinner />
					: 
						<> 
							{renderServices}
						</>
				}

				<tr>
					<td>
						<Button 
							size = "sm" 
							variant = "light"
							onClick = { () => newAssembly()  }
						>+</Button>
					</td>

				</tr>
				
			</tbody>
			
		</Table>	

	)
}