import {
  SET_REPORT
} from "../../actions/failed";

const _nullReport = null
                
export default (state = _nullReport, { type, obj}) => {
  switch (type) {
    case SET_REPORT:
      return obj;
    case "": 
      return _nullReport; 
    default:
      return state;
  }
};