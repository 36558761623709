import * as server from "../util/server";

export const GET_SERVICES  = "GET_SERVICES"


const receiveServices = (serviceslist) =>({
	type : GET_SERVICES, 
	serviceslist
})



export const requestReportFlash = (reportID) => {
	return new Promise( async(resolve, reject) => {
		const response = await server.requestFlashReport(reportID); 
		const data = await response.json()
		if(response.ok){
			resolve(  JSON.parse(data).report)
		}
	})
}

export const requestServicesList = ( invoiceID) => dispatch =>{
	return new Promise( async(resolve, reject) => {
		let obj = { invoiceID: invoiceID }
		const response = await server.requestServicesList( obj )
		const data = await response.json()

		if(response.ok){
			let myobj = { serviceslist: JSON.parse(data).list}
			resolve( dispatch( receiveServices ( myobj) ))
		}

	})

}

export const serverScheduleNewClient = ( obj ) => dispatch =>{
	return new Promise( async (resolve, reject) => {
		const response = await server.scheduleNewClient( obj ); 
		const data = await response.json(); 
		if(response.ok){
			resolve(true); 
		}	
	}) 
}