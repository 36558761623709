import {
  SET_TIME_FRAME, 
  CLEAR_TIME_FRAME
} from "../../actions/scheduling";

const _nullTimeFrame =  { start: "08:00", end: "15:00"}; 
                
export default (state = _nullTimeFrame, { type, timeframe }) => {
  Object.freeze(state);
  switch (type) {
    case SET_TIME_FRAME:
      return timeframe;
    case CLEAR_TIME_FRAME: 
      return _nullTimeFrame
    case "": 
      return _nullTimeFrame; 
    default:
      return state;
  }
};