import * as server from "../util/addressServer";
import * as serverAlt from "../util/alternativeServer";

import {SET_INVOICE_ID_BUILDER, CLEAR_INVOICE_ID_BUILDER }  from "./invoiceBuilder"
export const SET_ADDRESS_ID_APP = "SET_ADDRESS_ID_APP"
export const CLEAR_ADDRESS_ID_APP = "CLEAR_ADDRESS_ID_APP"
export const STOP_LIST = "STOP_LIST"
export const ASSEMBLY_LIST = "ASSEMBLY_LIST"
export const REPORT_LIST = "REPORT_LIST"
export const ADDRESS_DETAILS = "ADDRESS_DETAILS"
export const SCHEDULE_SERVICE_LIST = "SCHEDULE_SERVICE_LIST"
export const CLEAR_SERVICE_LIST = "CLEAR_SERVICES_LIST"

const setInvoiceID = (id) => ({
  type: SET_INVOICE_ID_BUILDER, 
  id
})

const clearInvoiceID = () => ({
  type: CLEAR_INVOICE_ID_BUILDER
})



const setAddressID = (id) => ({
  type: SET_ADDRESS_ID_APP, 
  id
})
const clearAddressIDD = () => ({
  type: CLEAR_ADDRESS_ID_APP
})

const receiveStopList = (list) => ({
  type: STOP_LIST, 
  list
})

const receiveAssemblyList = (list) => ({
  type: ASSEMBLY_LIST, 
  list
})

const receiveAddressDetails = (address) => ({
  type: ADDRESS_DETAILS, 
  address
})

const receiveReportList = (list) => ({
  type: REPORT_LIST, 
  list
})

const receiveServicesList = (list) => ({
  type: SCHEDULE_SERVICE_LIST, 
  list
})


export const clearInvoice = () => dispatch => {
    return new Promise((resolve, reject) => {
        dispatch(clearInvoiceID())
        resolve()
    })
}

export const updateScheduleServiceList = (list) => dispatch => {
  return new Promise(async (resolve, reject) => {
        try {
          resolve( dispatch( receiveServicesList(list) ) ); 
        } catch (e) {
            reject(e)
        }
    })
}


export const createStop = (obj) =>  dispatch => {
  return new Promise( async( resolve, reject) => {
    try{
      const response = await server.createStop(obj);
      const data = await response.json();
      if (response.ok) {
          dispatch( setInvoiceID( JSON.parse(data).invoiceID) ); 
          resolve()
      }
    }catch(err){

    }
  })
}

export const updateAddress = (addressID, obj) => dispatch => {
  return new Promise(async (resolve, reject) => {
        try {
            const response = await server.updateAddress(addressID, obj);
            const data = await response.json();
            if (response.ok) {
                resolve()
            }
        } catch (e) {
            reject(e)
        }
    })
}


export const updateAssembly = (assemblyID, obj) => {
    return new Promise(async (resolve, reject) => {
        try {
            const response = await server.updateAssembly(assemblyID, obj);
            const data = await response.json();
            if (response.ok) {
                resolve()
            }
        } catch (e) {
            reject(e)
        }
    })
}


export const requestAssemblyDetails = (assemblyID) => {
    return new Promise(async (resolve, reject) => {
        try {
            const response = await server.requestAssemblyDetails(assemblyID);
            const data = await response.json();
            if (response.ok) {
                resolve( JSON.parse(data).assembly)
            }
		} catch (e) {
            reject(e)
        }
    })
}


export const clearAddressID = () => dispatch => {
return new Promise( async(resolve, reject) =>{
    try{
      resolve(dispatch( clearAddressIDD() ) )
    } catch(e){
    }
  }) 

}


export const clearAddressObj = () => dispatch => {
return new Promise( async(resolve, reject) =>{
    try{
      resolve(dispatch( receiveAddressDetails(null) ) )
    } catch(e){
    }
  }) 

}
export const determineID  = (addressID) => dispatch => {
  return new Promise( async(resolve, reject) =>{
    try{

      console.log("This is the addressID: ", addressID)
      resolve(dispatch( setAddressID(addressID) ) )
    } catch(e){
    }
  }) 
} 


export const clearAssemblyList = () => dispatch => {
return new Promise( async(resolve, reject) =>{
    try{
      resolve(dispatch( receiveAssemblyList([]) ) )
    } catch(e){
    }
  }) 

}

export const requestAssemblyList = (addressID) => dispatch => {
  return new Promise( async ( resolve, reject) =>{
    try{
      console.log("requesting the address: ", addressID)
      const response = await server.requestAssemblyList( addressID );
      const data = await response.json()
      if(response.ok){
        resolve( dispatch( receiveAssemblyList(JSON.parse(data).list ) )); 
      }else{
      
      }
    }catch(e){
    } 
  })
}



export const requestAddressDetails = (addressID) => dispatch => {
  return new Promise( async ( resolve, reject) =>{
    try{
      console.log("requesting the address: ", addressID)
      const response = await serverAlt.requestAddress( addressID );
      const data = await response.json()
      if(response.ok){
        resolve( dispatch( receiveAddressDetails(JSON.parse(data).address ) )); 
      }else{
      
      }
    }catch(e){
    } 
  })
}


export const requestStops  = (addressID) => dispatch => {
  return new Promise( async(resolve, reject) =>{
    try{
      const response = await server.requestStopList(addressID); 
      const data = await response.json(); 
      if(response.ok){  
          resolve(dispatch( receiveStopList(JSON.parse(data).list)) )
      }
    } catch(e){
    }
  }) 
} 
export const requestStopTesters  = (stopID) => {
  return new Promise( async(resolve, reject) =>{
    try{
      const response = await server.requestStopTesters(stopID); 
      const data = await response.json(); 
      if(response.ok){  
          resolve( JSON.parse(data).list) 
      }
    } catch(e){
    }
  }) 
} 

export const clearStopsList = () => dispatch => {
return new Promise( async(resolve, reject) =>{
    try{
      resolve(dispatch( receiveStopList([]) ) )
    } catch(e){
    }
  }) 

}


 export const requestTestReports = (stopID) =>  {
  return new Promise( async(resolve, reject) =>{
    try{
      const response = await server.requestReportList(stopID); 
      const data = await response.json(); 
      if(response.ok){  
          resolve(JSON.parse(data).list )
      }
    } catch(e){
    }
  }) 
} 


export const updateTesters = (stopID, testerList) =>{
  try{
      return new Promise( async(resolve, reject) =>{
        const response = await server.updateTesterList(stopID, testerList); 
        const data = await response.json(); 
        if(response.ok){  
            resolve(JSON.parse(data).success )
        }
      }) 
  }catch(e){

  }
}

export const updateTrack = (addressID, newTrack) => {
  return new Promise( async(resolve, reject) =>{
    try{
      const response = await server.updateTrack(addressID, newTrack); 
        const data = await response.json(); 
        if(response.ok){  
            resolve(JSON.parse(data).success )
        }
    }catch(e){

    }
  })

}

