import {
  GET_CURRENT_PRINT
} from "../../actions/waterDistricts";

const _nullPrintJobs = { 
  current: "", 
  remaining: "", 
  total: ""
}
                
export default (state = _nullPrintJobs, { type, printcurrent }) => {
  switch (type) {
    case GET_CURRENT_PRINT:
      return printcurrent;
    case "": 
      return _nullPrintJobs; 
    default:
      return state;
  }
};