import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form'
import Table from 'react-bootstrap/Table'
import Button from 'react-bootstrap/Button'
import FloatingLabel from 'react-bootstrap/FloatingLabel'
import Container from 'react-bootstrap/Container';
import ButtonGroup from 'react-bootstrap/ButtonGroup';
import Spinner from 'react-bootstrap/Spinner';
import Breadcrumb from 'react-bootstrap/Breadcrumb';
import ListGroup from 'react-bootstrap/ListGroup';
import {useSelector, useDispatch} from "react-redux";
import { useState, useEffect, useRef, useCallback} from "react";
import ContactModule from "./ContactModule"
import {
	getDistrictDetails,
	getDistrictContacts,
	updateDistrictDetails
} from "../../../actions/districtManagement.js"
import NewContact from "./NewContact"

export default function WaterDistrict(){
	const [waterName, setWaterName] = useState(null); 
	const [shortName, setShortName] = useState(null); 
	const [address, setAddress] = useState(null); 
	const [delivery, setDelivery] = useState(null); 
	const [myCurrent, setMyCurrent] = useState(null);
	const dispatch = useDispatch(); 
	const currentID = useSelector((state) => state.district_id)
	const [contactLoader, setContactLoader] = useState(true)
	const mylist = useSelector( (state) => state.district_contact_list)
	const [showDistrict, setShowDistrict] = useState(false)
	const [edit, setEdit] = useState(true)
	const [showModal, setShowModal] = useState(false)
	const [modalBody,  setModalBody] = useState(<></>)

	const closeModal = () => setShowModal(false)

	const update = () => {
		let obj = {}
		obj.id = myCurrent.waterID;
		obj.name = waterName;
		obj.short_name = shortName;
		obj.address = address;
		obj.delivery_method = delivery; 
		console.log("this is the object: ", obj);
		dispatch(updateDistrictDetails(obj)).then((data, err) => {
			setMyCurrent(data)
			setEdit(true)
		})
	}

	useEffect(() => {
		if (myCurrent != null) {
			console.log("Current district: ", myCurrent)
			setWaterName(myCurrent.name);
			setShortName(myCurrent.short_name);
			setAddress(myCurrent.Address)
			setDelivery(myCurrent.delivery_method.toUpperCase())
			setShowDistrict(true)
		}
	}, [myCurrent])

	useEffect(()=>{
		if (currentID != null) {
			setShowDistrict(false)
			dispatch(getDistrictDetails(currentID)).then((data, err) => {
				setMyCurrent(data)
			})
			setContactLoader(true)
			dispatch(getDistrictContacts(currentID)).then((data, err) => {
				setContactLoader(false)
			})
			
		} else {
			setShowDistrict(false)
		}
	}, [currentID])

	const renderContactList = mylist.map((cc, err) =>{
		return(
			<ContactModule  contact = {cc} />
		)
	})
	return(
		<Container>
			<Modal show={ showModal}>
				<Modal.Header closeButton onHide={
					() => setShowModal(false)
				}>
				</Modal.Header>
				{ modalBody}

			</Modal>
			{
				showDistrict ?
					<>
						<>
							{
								edit ?
									<>
										
										<Row>
											<Col sm md lg={10} >
												<h4>{waterName}</h4>
												<h6> Short Name : {shortName}</h6>
												<h6> Delivery Method: {delivery}</h6>
												<h6> Address: {address}</h6>
											</Col>
											<Col>
												<ButtonGroup vertical>
												<Button
													onClick={() => setEdit(false)}
													variant = "secondary"
												> Edit </Button>
												
												</ButtonGroup>
											</Col>
										</Row>
									</>
									:
									<>
										<Row>

											<Col sm md lg={10}>
												<FloatingLabel label="Name">
													<Form.Control
														value={waterName}
														onChange={
															(event) => setWaterName(event.target.value)
														}

													/>
												</FloatingLabel>

												<FloatingLabel label="Short Name">
													<Form.Control
														value={shortName}
														onChange={
															(event) => setShortName(event.target.value)
														}

													/>
												</FloatingLabel>

												<FloatingLabel label="Delivery Method">
													<Form.Select value={delivery}
														onChange={
															(event) => setDelivery(event.target.value)
														}

													>
														<option value="E-MAIL"> E-mail </option>
														<option value="MAIL"> Mail </option>
														<option value="ONLINE"> Online </option>
													</Form.Select>
												</FloatingLabel>

												<FloatingLabel label="Address">
													<Form.Control
														value={address}
														onChange={
															(event) => setAddress(event.target.value)
														}

													/>
												</FloatingLabel>
											</Col>
											<Col>
												<Container>
													<ButtonGroup>
														<Button
															onClick={update}
															variant="success"
														> Save</Button>
													</ButtonGroup>
												</Container>
											</Col>

										</Row>
										
									</>

							}
						</>
					</>
				: 
					<></>
			}

			<hr/>
			<Row>
				<Col>
					{
						contactLoader ?
							<>loading... </>
							:
							<>
								<Table size= "sm" striped borderless>
									<thead>
										<tr>
											<th> Tag </th>
											<th> Name </th>
											<th> Email </th>
											<th> Phone </th>
											<th> </th>
										</tr>
									</thead>
									<tbody>
										{renderContactList}
										<tr>
											<td>
												<Button variant="secondary" size="sm"
													onClick={
														() => {
															setModalBody(<NewContact closeModal={closeModal} />)

															setShowModal(true)
														}
													}

												> + </Button>
											</td>
											<td></td>
											<td></td>
											<td></td>
											<td></td>
										</tr>
									</tbody>
								</Table>
								
							</>


					}


				</Col>
			</Row>
			
		
		</Container>
	)
}