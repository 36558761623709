import Card from 'react-bootstrap/Card';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Modal from 'react-bootstrap/Modal';
import Table from 'react-bootstrap/Table';
import Over from 'react-bootstrap/Table';
import Spinner from 'react-bootstrap/Spinner';
import Accordion from 'react-bootstrap/Accordion';
import Button from 'react-bootstrap/Button';
import {format} from "date-fns"
import { useState, useEffect, useRef, useCallback} from "react";
import {useSelector, useDispatch} from "react-redux";
import { setAsClientSent, getReportLog, setAsWaterSent} from "../../../../actions/customerApp.js"
import {determineReportID, sendReportToSelf} from "../../../../actions/waterDelivery.js"
import { requestReportFlash } from "../../../../actions/services.js"
import DeliveryForm from "./DeliveryForm"
import TestReport from "../../testReports/TestReport"
import Overlay from 'react-bootstrap/Overlay';

export default function AssemblyCard(props){
	const [myReport, setMyReport] = useState(null)
	const [exists, setExists] = useState(false)
	const [hasData, setHasData] = useState(false)
	const dispatch = useDispatch(); 
	const [showReport, setShowReport] = useState(false)
	const [disabled, setDisabled] = useState(false)
	const [sending, setSending] = useState(false)
	const [sent, setSent] = useState(false);
	const tt = useRef(null)

	useEffect(() => {
		if (myReport != null) {
			if (myReport.serviceState == "CANCELLED") {
				setDisabled(true)
			}
		}
	}, [myReport])

	useEffect(()=>{
		requestReportFlash(props.report.reportID).then((data, err) =>{
			console.log(data)
			if(data.length <=0){
				setExists(false)
			}else{
				console.log(data)
				setMyReport(data[0])
				setExists(true)
			}
		})
	}, [])

	useEffect(()=>{
		if(myReport != null){
			if(myReport.testState != "CREATED" && myReport.testState != "NOT_TESTED"){
				setHasData(true)
			}else{
				setHasData(false)
			}

		}
	}, [myReport])




	function passFail(passbool, failbool){

		if(passbool){
			return "PASSED"
		}
		if(failbool){
			return "FAILED"
		}
		return " ---- "
	}

	function ddstatus(state){
		if(state == "TESTER_APPROVED" || state == "PDF_CREATED"){
			return "TESTED"
		}else if(state.toUpperCase() == "CREATED" ){
			return "NOT_TESTED"
		}else{
			return state
		}

	} 
	return(
		<>
			<Modal show = {showReport} size = "xl" centered backdrop = "static" backdropClassName = "modal-backdrop dark-background">
				<Modal.Header closeButton onHide = {() => setShowReport(false)}>
					Results
				</Modal.Header>
				<Modal.Body>
					<TestReport />
				</Modal.Body>
			</Modal>
			{
				exists ? 
					<>
					<tr>
					<td > 
								<Button
									disabled={ disabled}
									onClick={() =>
						{
							dispatch( determineReportID(props.report.reportID) ).then((data, err) =>{
								setShowReport(true) 
							})
						}

					 } variant = "link"> {props.report.reportID} </Button>	
					</td>
					<td>
						{
											myReport ?

												<> { myReport.serial_number } </>
											: 
												<> </>
										} 
					</td>
			
					<td>
							{
							myReport ?
								<>
									{passFail(myReport.initialPass, myReport.initalFail)}

								</>
								:
								<> </>
							}
					</td>
					<td>
							{
							myReport ?
								<>{passFail(myReport.repairPass, myReport.repairFail)} </>
								:
								<></>

							}
					</td>
					
					<td>
						{
											myReport ? 
												<u>	{ddstatus ( myReport.testState) } </u>
											: 
												<>
													<Spinner />
												</>
										}
					</td>
					
				
						<td>
						
					
							{
										myReport ? 
											<> 
												<Form.Check  label = "" 
												disabled={ disabled}
												onClick={(event) => {
													setAsWaterSent(props.report.reportID, event.target.checked).then((resolve, reject) => {
														console.log("Done!")
														requestReportFlash(props.report.reportID).then((data, err) =>{
															console.log(data)
															if(data.length <=0){
																setExists(false)
															}else{
																console.log(data)
																setMyReport(data[0])
																setExists(true)
															}
														})
													

													})
												}}
												checked = {
												myReport.waterSent
											}/>
											</>
										: 
											<>
											</>

									}
									{
											myReport ? 
												<>
													{
														myReport.waterSent ? 
															<>Sent on:  { format( new Date(myReport.waterSentOn), "MM/dd/yyyy")} </>
														: 
															<>
															</>
													}
												</>
											: 
												<> </>
										}
						</td>
						<td>
										{
											myReport ? 
												<> 
											<Form.Check label=""
												disabled={ disabled }
															onClick={(event) => {
																setAsClientSent(props.report.reportID, event.target.checked).then((resolve, reject) => {
																	console.log("Done!")
																	requestReportFlash(props.report.reportID).then((data, err) =>{
																		console.log(data)
																		if(data.length <=0){
																			setExists(false)
																		}else{
																			console.log(data)
																			setMyReport(data[0])
																			setExists(true)
																		}
																	})
											
																})
															}}
															checked={
																	myReport.clientSent
															}
														/>
												</>
											: 
												<>
												</>
										}
									
											
									{
											myReport ? 
												<>
													{
														myReport.clientSent ? 
															<>Sent on:  { format( new Date(myReport.clientSentOn), "MM/dd/yyyy")} </>
														: 
															<>
															</>
													}
												</>
											: 
												<> </>
										}
							</td>
								
							<td>
								{
										myReport ?

											<>
												{
														myReport.reportLink ? 
													<>
														<Button size='sm'
															ref={tt}
															variant="info"
															disabled={sent}
															onClick={
																() => {
																	setSending(true)
																	setSent(true)
																	sendReportToSelf(props.report.reportID).then((data, err) => {
																		setSending(false)
																	})
																}
															}
														>
															{
																sending ?
																	<Spinner />
																	:
																	<> Email Report to Self </>
															}
														</Button>
													</>
												: 
													<>	
														<Button size='sm'
															ref={tt}
															variant="info"
															disabled={ sent}
															onClick = {
																() =>{
																	setSending(true)
																	setSent(true)
																	sendReportToSelf(props.report.reportID).then((data, err) =>{
																		setSending(false)
																	})
																}
															}
														> 
															{
																sending? 
																	<Spinner />
																: 
																	<> Email Report to Self </>
															}
														</Button>
														
															
													</>
												}
											<Overlay show={true} target={tt.current} placement="top">
												{({
													placement: _placement,
													arrowProps: _arrowProps,
													show: _show,
													popper: _popper,
													hasDoneInitialMeasure: _hasDoneInitialMeasure,
													...props
												}) => (
													<div
														{...props}
														style={{
															fontSize: '12px',
															position: 'relative',
															backgroundColor: 'rgba(60, 179, 113, 0.85)',
															padding: '20px 20px',
															color: 'green',
															borderRadius: 10,
															...props.style,
														}}
													>
														Sent!
													</div>
												)}
											</Overlay>
											</>
										: 
											<> </>
							}
						</td>
							
					</tr>
					<tr  >
						<td colSpan = {10} className = "danger">
						{
									myReport ?
										<> {myReport.stopComment}</>
									: 
										<> </>
									}
						</td>
				
					</tr>
					</>
				:
					<>
						does not exist 
					</>

			}
		</>
					
	)
}