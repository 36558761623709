import * as server from "../util/intuitServer";


export const getCSRFToken = () => {
	return new Promise( async(resolve, reject) =>{
		const response = await server.getCSRF(); 
		const data = await response.json(); 
		resolve(JSON.parse(data).token)
	})
}


export const connectQB = (user, csrf) => async dispatch => {
  const response = await server.connect(user, csrf);
  const data = await response.json(); 
  window.location.replace(JSON.parse(data).url);

};

export const disconnectQB = (csrf) => async dispatch => {
	return new Promise( async(resolve, reject)=>{
		const response = await server.disconnect(csrf);
		const data = await response.json(); 
	})
	
}
export const checkConnection = () => {
	return new Promise( async( resolve, reject) =>{
		const response = await server.checkConnection(); 
		const data = await response.json(); 
		if(response.ok){
			resolve(JSON.parse(data).connected.connected)
		}
	})
}