export const getTesterStops = (testerID, date) =>(
	fetch("backflow/schedule/getTesterStops?" +  new URLSearchParams({
		testerID: testerID,
		date: date
	}), {
		method: "GET", 
		headers: {
            "Content-Type" : "application/json"
        }
	}) 

)

export const getTestersStops = (date, testerID, offset) =>(
	fetch("backflow/schedule/getTestersStops?" +  new URLSearchParams({
		date: date, 
		testerID: testerID,
		offset: offset
	}), {
		method: "GET", 
		headers: {
            "Content-Type" : "application/json"
        }
	}) 

)

export const requestDates = (addressID) =>(
	fetch("backflow/schedule/getDates?" +  new URLSearchParams({
		addressID: addressID
	}), {
		method: "GET", 
		headers: {
            "Content-Type" : "application/json"
        }
	}) 
)
export const requestOnSiteDetails = (addressID) =>(
	fetch("backflow/schedule/onSiteDetails?" +  new URLSearchParams({
		addressID: addressID
	}), {
		method: "GET", 
		headers: {
            "Content-Type" : "application/json"
        }
	}) 
)

export const createServices = (obj) =>(
	fetch("backflow/schedule", {
		method: "POST",
		body: JSON.stringify( obj ) ,
		headers: {
            "Content-Type" : "application/json"
        }
	}) 
)

export const cancelService = (serviceID) =>(
	fetch("backflow/schedule/cancel", {
		method: "POST",
		body: JSON.stringify({serviceID: serviceID} ) ,
		headers: {
            "Content-Type" : "application/json"
        }
	}) 
)

export const updateOnSiteName = (newname, customerID) =>(
	fetch("backflow/schedule/updateOnSiteName", {
		method: "PUT",
		body: JSON.stringify({ newname: newname, adddressID: customerID}  ) ,
		headers: {
            "Content-Type" : "application/json"
        }
	}) 
)

export const updateOnSitePhoneNumber= (newphone, customerID) =>(
	fetch("backflow/schedule/updateOnSitePhone", {
		method: "PUT",
		body: JSON.stringify({ newphone: newphone, addressID: customerID}  ) ,
		headers: {
            "Content-Type" : "application/json"
        }
	}) 
)

export const updateNote= (newnote, customerID) =>(
	fetch("backflow/schedule/updateNote", {
		method: "PUT",
		body: JSON.stringify({ newnote: newnote, addressID: customerID}  ) ,
		headers: {
            "Content-Type" : "application/json"
        }
	}) 
)
export const updateGateNumber= (newgate, customerID) =>(
	fetch("backflow/schedule/updateGate", {
		method: "PUT",
		body: JSON.stringify({ newgate: newgate, addressID: customerID}  ) ,
		headers: {
            "Content-Type" : "application/json"
        }
	}) 
)
export const updateRequestor= (newrequestor, customerID) =>(
	fetch("backflow/schedule/updateRequestor", {
		method: "PUT",
		body: JSON.stringify({ newrequestor: newrequestor, addressID: customerID}  ) ,
		headers: {
            "Content-Type" : "application/json"
        }
	}) 
)

