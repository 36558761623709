import Card from 'react-bootstrap/Card'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'

export default function AnnouncementCard(){
	return(
		<Card>
				<Card.Header>
					American Backflow Announcements
				</Card.Header>
				<Card.Body>
					Get ready to mark your calendars! Here's our holiday schedule for [Year]. Our offices will be closed on these dates to celebrate with our loved ones. Plan ahead and enjoy the festivities! 🎄🎆 #HolidaySchedule #PlanningAhead #TimeToCelebrate 
				</Card.Body>
				<Card.Footer>
					<Row>
						<Col>
							<small>Fri Dec 29, 2023</small>
						</Col>
						<Col >
							<small>Author's name</small>
						</Col>
					</Row>
				</Card.Footer>
		</Card>
	)

}