import Table from 'react-bootstrap/Table';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Spinner from 'react-bootstrap/Spinner';
import InputGroup from 'react-bootstrap/InputGroup';
import { useState, useEffect } from 'react'; 
import {useSelector, useDispatch} from "react-redux";
import {requestLineItemDetails, removeItem, addItem, requestLineItems, updateLineItem } from "../../../actions/invoiceBuilder.js"


export default function ItemLine(props) {
	const invoiceID = useSelector((state) => state.builder_id)
	const [itemID, setItemID] = useState(null)
	const [description, setDescription] = useState(null); 
	const [quantity, setQuantity] = useState(null); 
	const [unitPrice, setUnitPrice] = useState(null); 
	const [amount, setAmount] = useState(null); 
	const dispatch = useDispatch()
	const [taxable, setTaxable] = useState(null)
	const [loader, setLoader] = useState(false)
	const [myItem, setMyItem] = useState(null);



	useEffect(() => {
		if ( quantity != null && unitPrice != null && taxable != null ) {
			update()
		}
	}, [quantity, taxable])

	const update = () =>{
		let obj = {}; 
		obj.description = description; 
		obj.quantity = Number(quantity); 
		obj.amount = Number(quantity * unitPrice) ; 
		obj.taxable = taxable; 
		obj.unitPrice = Number(unitPrice); 
		dispatch( updateLineItem( itemID, obj) ).then((data, err) =>{
			setMyItem(data)
		})
	}

	useEffect(()=>{
		if (myItem != null) {
			console.log("This is my item: ", myItem)
			//update all the values
			setDescription(myItem.description); 
			setQuantity(myItem.quantity); 
			setUnitPrice(myItem.unitPriceDefined); 
			setTaxable(myItem.taxable); 
			setAmount(myItem.amount);
			setItemID(myItem.id)
		}
	}, [myItem])

	useEffect(()=>{
		//request line item details
		requestLineItemDetails(props.id).then((data, err) =>{
			setMyItem(data)
		})
	}, [])

	return(
		<>
			<tr>
				{
					loader ?
						<>
							<Spinner />
						</>
					: 
						<>
							<td>
								
							</td>
							<td>
								<Form.Control
									size= "sm"
									value= {description}
									onChange = {(event) =>{
										setDescription(event.target.value)
									}}
								/>
							</td>
							<td>
								<Form.Control 
									type="number"
									step="1"
									min="1"
									value={quantity}
									size="sm"
									onChange={(event) => {
										setQuantity(event.target.value)
									}}
								/>
							</td>
							<td>
								<InputGroup>
									<InputGroup.Text> $ </InputGroup.Text>
									<Form.Control
										size="sm"
										min="0.0"
										value={unitPrice}
										onChange={(event) => setUnitPrice(event.target.value)}
										onBlur={ update }
										type="number"
										step="0.01"
									
									/>
								</InputGroup>
							</td>
								<td>
								<Form.Check
									type="checkbox"
									value={taxable}
									onChange= { (event) =>{
										setTaxable(event.target.checked)

									}}
								/>
							</td>
							<td>${
								amount ?
									<>
										{amount.toFixed(2)}
									</>
									:
									<>
									</>
							}
							</td>
						
							<td>
								<Button 
									size ="sm" 
									variant = "secondary" 
									onClick = { ()=>{
										removeItem(props.id).then((data, err) =>{
											dispatch( requestLineItems(invoiceID) ).then(( data, err) =>{

											})
										})
												
									
									}}
								> -</Button>
							</td>

						</>
				}
							
			</tr>
	
		</>
	)
}