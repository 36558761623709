import {
  RECEIVE_INVOICE_SERVICE_ADDRESS,  
} from "../../actions/invoice.js";

const _nullInvoiceAddress = null; 
                
export default (state = _nullInvoiceAddress, { type, address }) => {
  Object.freeze(state);
  switch (type) {
    case RECEIVE_INVOICE_SERVICE_ADDRESS:
      return address;
    case "": 
      return _nullInvoiceAddress; 
    default:
      return state;
  }
};