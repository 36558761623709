import {
  SET_SERVICES_REPORT_CURRENT_STOP
} from "../../actions/servicesReport";

const _nullCurrentStop =  null; 
                
export default (state = _nullCurrentStop, { type, stop }) => {
  Object.freeze(state);
  switch (type) {
    case SET_SERVICES_REPORT_CURRENT_STOP:
      return stop;
    case "": 
      return _nullCurrentStop; 
    default:
      return state;
  }
};