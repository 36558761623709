import {
  SET_CURRENT_ID
} from "../../actions/failed";

const _nullID = null
                
export default (state = _nullID, { type, id}) => {
  switch (type) {
    case SET_CURRENT_ID:
      return id;
    case "": 
      return _nullID; 
    default:
      return state;
  }
};