import Form from 'react-bootstrap/Form';
import Modal from 'react-bootstrap/Modal';
import Alert from 'react-bootstrap/Alert';
import Button from 'react-bootstrap/Button';
import Spinner from 'react-bootstrap/Spinner';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { useState, useEffect, useRef, useCallback} from "react";
import { login, cleanErrors} from "../actions/session.js"; 
import {useSelector, useDispatch} from "react-redux";
import format from 'date-fns/format'; 
//import { logout, getTestList } from "../actions/session";
import LoginAlert from "../alternativeModules/apps/sessionize/LoginAlert"


export default function LoginForm(){


	return(
		<>
			<LoginAlert />	
		</>
	)
}