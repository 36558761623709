import {
  CURRENT_GAUGE, CLEAR_GAUGE
} from "../../actions/gauges";

const _nullId = null
                
export default (state = _nullId, { type, id }) => {
  Object.freeze(state);
  switch (type) {
    case CURRENT_GAUGE:
      return id;
    case CLEAR_GAUGE: 
      return _nullId; 
    case "":
      return _nullId; 
    default:
      return state;
  }
};