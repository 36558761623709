import Accordion from 'react-bootstrap/Accordion';
import Row from 'react-bootstrap/Row';	
import Col from 'react-bootstrap/Col';
import Card from 'react-bootstrap/Card';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Modal from 'react-bootstrap/Modal';
import Container from 'react-bootstrap/Container';
import ListGroup from 'react-bootstrap/ListGroup';
import ButtonGroup from 'react-bootstrap/ButtonGroup';
import BillingInformation from "./BillingInformation"
import InvoiceList from "./InvoiceList"
import { useState, useEffect, useRef, useCallback} from "react";
import {useSelector, useDispatch} from "react-redux";


import { requestBillingDetails, createBillingDetail } from "../../../../actions/customerApp.js"

import BillingDetail from "./BillingDetail"
import NewInvoice from "./NewInvoice"
export default function InvoiceTab(){
	const [show, setShow] = useState(false)
	const dispatch = useDispatch(); 
	const billingdetaillist = useSelector( (state) => state.billingDetails ); 
	const customerID = useSelector( (state) => state.customerID)

	return(
		<Container  className ="scrollable-modal">
			<Row>
				<Col sm md lg={10}>
					<p>
						<strong>Invoices & Payments </strong> <br/> 
					</p>
				</Col>

			</Row>
			<Row>
				<Col>
					<InvoiceList />
				</Col>
			</Row>
			<Row>
				<Col>
					
					<NewInvoice />
				</Col>
			</Row>
	
		</Container>
	)
}