import {
  RECEIVE_TEST_REPORT_ID
} from "../../actions/waterDelivery";

const _nullId = null
                
export default (state = _nullId, { type, id }) => {
  Object.freeze(state);
  switch (type) {
    case RECEIVE_TEST_REPORT_ID:
      return id;
    case "":
      return _nullId; 
    default:
      return state;
  }
};