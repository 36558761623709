export const setAsClientSent = (reportID, actionType) => (
	fetch("backflow/testReport/sentToClient", {
		method: "POST",
		body: JSON.stringify({ reportID: reportID, clickType: actionType}), 
		headers: {
			"Content-Type": "application/json"
		}
	})
	
)
export const setAsWaterSent = (reportID, actionType) => (
	fetch("backflow/testReport/sentToWater", {
		method: "POST",
		body: JSON.stringify({ reportID: reportID, clickType: actionType}), 
		headers: {
			"Content-Type": "application/json"
		}
	})
	
)
export const requestReportLog = (reportID) => (
	fetch("backflow/testReport/log?" + new URLSearchParams({
		reportID: reportID
	}), {
		method: "GET",
		headers: {
			"Content-Type": "application/json"
		}
	})
)

export const requestReports = (addressID) => (
	fetch("backflow/customer/reports?" +  new URLSearchParams({
		addressID: addressID
	}), {
		method: "GET", 
		headers: {
            "Content-Type" : "application/json"
        }
    })
)

export const paymentList = (customerID) =>(
	fetch("backflow/customer/payments?" +  new URLSearchParams({
		customerID: customerID
	}), {
		method: "GET", 
		headers: {
            "Content-Type" : "application/json"
        }
    })
)

export const requestServices = (customerID) => (
	fetch("backflow/customer/services?" +  new URLSearchParams({
		customerID: customerID
	}), {
		method: "GET", 
		headers: {
            "Content-Type" : "application/json"
        }
    })
) 


export const updateCustomerDetails = (obj, customerID) =>(
	fetch("backflow/customer" , {
		method: "POST",
		body: JSON.stringify( { obj: obj, customerID: customerID} ) , 
		headers: {
            "Content-Type" : "application/json"
        }
    })
)


export const removeBillingDetail = (id, customerID) => (
	fetch("backflow/customer/billingDetail?"+  new URLSearchParams({
		id:  id, 
		customerID: customerID
	}) , {
		method: "DELETE",
	 
		headers: {
            "Content-Type" : "application/json"
        }
    })

)

export const createBillingDetail= (obj,  customerID) =>(
	fetch("backflow/customer/billingDetail" , {
		method: "PUT",
		body: JSON.stringify( { obj: obj, customerID: customerID} ) , 
		headers: {
            "Content-Type" : "application/json"
        }
    })
)


export const requestCustomerDetails = (customerID) =>(
	fetch("backflow/customer?" +  new URLSearchParams({
		customerID: customerID
	}), {
		method: "GET", 
		headers: {
            "Content-Type" : "application/json"
        }
    })
)

export const serviceAddresses = (customerID) =>(
	fetch("backflow/customer/addresses?" +  new URLSearchParams({
		customerID: customerID
	}), {
		method: "GET", 
		headers: {
            "Content-Type" : "application/json"
        }
    })
)

export const assemblyList = (addressID) => (
	fetch("backflow/address/assemblies?" +  new URLSearchParams({
		addressID: addressID
	}), {
		method: "GET", 
		headers: {
            "Content-Type" : "application/json"
        }
    })
)
export const newAddress = (obj, customerID)=>(
	fetch("backflow/customer/address", {
		method: "PUT",
		body : JSON.stringify({ address: obj, customerID: customerID}),  
		headers: {
            "Content-Type" : "application/json"
        }
    })
)

export const createCustomer = (obj)=>(
	fetch("backflow/customer", {
		method: "PUT",
		body : JSON.stringify({ customer: obj}),  
		headers: {
            "Content-Type" : "application/json"
        }
    })
)
export const associateAddressToCustomer = (addressID, customerID) => (
	fetch("backflow/customer/addExistingAddress", {
		method: "POST",
		body : JSON.stringify({ addressID: addressID, customerID: customerID}),  
		headers: {
            "Content-Type" : "application/json"
        }
    })
)

export const newAssembly = (obj, addressID)=>(
	fetch("backflow/customer/assembly", {
		method: "PUT",
		body : JSON.stringify({ assembly: obj, addressID: addressID}),  
		headers: {
            "Content-Type" : "application/json"
        }
    })
)

export const removeAssembly = (assemblyID, addressID) =>(
	fetch("backflow/customer/removeAssembly", {
		method: "POST",
		body : JSON.stringify({ assemblyID: assemblyID, addressID: addressID}),  
		headers: {
            "Content-Type" : "application/json"
        }
    })
)

export const removeAddress = (addressID, customerID)=>(
	fetch("backflow/address/remove", {
		method: "POST",
		body : JSON.stringify({ addressID: addressID, customerID}),  
		headers: {
            "Content-Type" : "application/json"
        }
    })
)

export const requestBillingDetails = (customerID)=>(
	fetch("backflow/customer/billing?" +  new URLSearchParams({
		customerID: customerID
	}), {
		method: "GET", 
		headers: {
            "Content-Type" : "application/json"
        }
    })
)
export const invoiceList = (customerID)=>(
	fetch("backflow/customer/invoices?" +  new URLSearchParams({
		customerID: customerID
	}), {
		method: "GET", 
		headers: {
            "Content-Type" : "application/json"
        }
    })
)
export const newContactLog = (logObj, customerID)=>(
	fetch("backflow/log", {
		method: "PUT",
		body : JSON.stringify({ log: logObj, customerID: customerID}),  
		headers: {
            "Content-Type" : "application/json"
        }
    })
)

export const contactLogList = (customerID)=>(
	fetch("backflow/customer/contactLogList?" +  new URLSearchParams({
		customerID: customerID
	}), {
		method: "GET", 
		headers: {
            "Content-Type" : "application/json"
        }
    })
)







