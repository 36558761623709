import {
  SET_TERM_LIST,  
} from "../../actions/invoice.js";

const _nullTermList = []; 
                
export default (state = _nullTermList, { type, list }) => {
  Object.freeze(state);
  switch (type) {
    case SET_TERM_LIST:
      return list;
    case "": 
      return _nullTermList; 
    default:
      return state;
  }
};