import {
  RECEIVE_TEST_REPORT, 
  CLEAR_TEST_REPORT
} from "../../actions/report";

const _nullTestReport = null
                
export default (state = _nullTestReport, { type, report }) => {
  Object.freeze(state);
  switch (type) {
    case RECEIVE_TEST_REPORT:
      return report;
    case CLEAR_TEST_REPORT: 
      return _nullTestReport; 
    default:
      return state;
  }
};